import { useMediaQuery, useTheme } from "@mui/material";

const useBreakpoints = () => {
  const theme = useTheme();
  return {
    isXlUp: useMediaQuery(theme.breakpoints.up("xl")), //1536px
    is1150Up: useMediaQuery(theme.breakpoints.up(1150)), //1150px
    is1300Up: useMediaQuery(theme.breakpoints.up(1300)), //1300px
    is1000Up: useMediaQuery(theme.breakpoints.up(1000)), //1000px
    isMdUp: useMediaQuery(theme.breakpoints.up("md")), //900px
    is750Up: useMediaQuery(theme.breakpoints.up(750)), //750px
    isSmUp: useMediaQuery(theme.breakpoints.up("sm")), //600px
    isXlDown: useMediaQuery(theme.breakpoints.down("xl")), //1536px
    is1150Down: useMediaQuery(theme.breakpoints.down(1150)), //1150px
    is1000Down: useMediaQuery(theme.breakpoints.down(1000)), //1000px
    isMdDown: useMediaQuery(theme.breakpoints.down("md")), //900px
    isSmDown: useMediaQuery(theme.breakpoints.down("sm")), //600px
  };
};

export default useBreakpoints;

export interface IBreakpoints {
  isXlUp: boolean,
  is1150Up: boolean,
  is1300Up: boolean,
  is1000Up: boolean,
  isMdUp: boolean,
  is750Up: boolean,
  isSmUp: boolean,
  isXlDown: boolean,
  is1150Down: boolean,
  is1000Down: boolean,
  isMdDown: boolean,
  isSmDown: boolean,
}