import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material'
import colors from '../../../assets/_themes-vars.module.scss'
import TextField from '../../../components/TextField/TextField'


const ElementConstitucion = (props: { label: string, value: string, color?: string }) => {

    const CustomizeTextField = styled(TextField)({
        width: '100%',
        marginTop: '.5rem',
        '& label': {
            color: colors.primaryMain,
            fontSize: '16px',
            fontWeight: 'bold'
        },
    })

    const useStyles = makeStyles({
        underline: {
            "&&&:before": {
                borderBottom: "none"
            },
            "&&:after": {
                borderBottom: "none"
            }
        }
    });

    const classes = useStyles()

    return (
        <CustomizeTextField
            label={props.label}
            variant='standard'
            size='small'
            value={props.value}
            InputProps={{ classes, readOnly: true }}
            sx={{ input: { color: [null, undefined, ''].includes(props.color) ? 'black' : props.color } }}
        />
    )
}

export default ElementConstitucion