import { Button, Typography } from "@mui/material";
import DatePickerComponent from "../../../../components/DatePicker/DatePickerComponent";
import Selector from "../../../../components/Selector/Selector";
import colors from "../../../../assets/_themes-vars.module.scss";
import { Slider } from "../Slider/Slider";
import useFilterResponsiveStyles from "./FilterResponsiveStyle";
import { useContext, useEffect, useState } from "react";
import { FiltersContext } from "../FiltersSection/FiltersSection";
import { MAX_VALUE } from "../Slider/TransformValue";
import { FilterProps } from "./Filters.props";
import { NumberToMoneyConverter } from "../../../../components/Formatters/Formatters";
import FiltrosService, {
  TipoImputacion,
  TipoOrigen,
} from "../../../../services/CuentaService/filtros.service";
import {
  defaultTipoImputacion,
  defaultTipoOrigen,
  getDateLimits,
} from "./FilterUtils";
import { useSystemDate } from "../../../../contexts/SystemDateContext";

const Filters = ({ buttonHandler, ctaPlus }: FilterProps) => {
  const [tipoImputacionesList, setTipoImputacionesList] = useState<
    TipoImputacion[]
  >([defaultTipoImputacion]);

  const [tipoOrigenList, setTipoOrigenList] = useState<TipoOrigen[]>([
    defaultTipoOrigen,
  ]);

  const systemDate = useSystemDate();

  const getFiltros = () => {
    FiltrosService.GetTiposImputacion().then((result) =>
      setTipoImputacionesList(result)
    );
    FiltrosService.GetTiposOrigen().then((result) => setTipoOrigenList(result));
  };

  useEffect(() => {
    getFiltros();
  }, []);

  const selectorTipoImputacionOptionList = tipoImputacionesList.map(
    (tipoImputacion) => {
      return tipoImputacion.Nombre;
    }
  );

  const selectorTipoOrigenOptionList = tipoOrigenList.map((tipoOrigen) => {
    return tipoOrigen.Nombre;
  });

  const styles = useFilterResponsiveStyles(ctaPlus);
  const [filters, setFilters] = useContext(FiltersContext);
  const dateLimits = getDateLimits(filters.dateFrom, filters.dateTo, systemDate);

  const setDateFrom = (newDate: Date) => {
    setFilters({ ...filters, dateFrom: newDate });
  };

  const setDateTo = (newDate: Date) => {
    setFilters({ ...filters, dateTo: newDate });
  };

  const setTipoImputacion = (newImputacionName: string) => {
    const newTipoImputacion: TipoImputacion = tipoImputacionesList.find(
      (tipoImputacion) => tipoImputacion.Nombre === newImputacionName
    );
    setFilters({ ...filters, tipoImputacion: newTipoImputacion });
  };

  const setTipoOrigen = (newOrigenName: string) => {
    const newTipoOrigen: TipoOrigen = tipoOrigenList.find(
      (tipoOrigen) => tipoOrigen.Nombre === newOrigenName
    );
    setFilters({ ...filters, tipoOrigen: newTipoOrigen });
  };

  const setMin = (newMin: number) => {
    setFilters({ ...filters, min: newMin });
  };

  const setMax = (newMax: number) => {
    setFilters({ ...filters, max: newMax });
  };

  const getMoney = (number: number) => {
    let money = NumberToMoneyConverter(number, false);
    return number === MAX_VALUE ? `+${money}` : money;
  };

  const createInputFilters = () => {
    return (
      <>
        <DatePickerComponent
          text={"Desde"}
          date={filters.dateFrom}
          setDate={setDateFrom}
          sx={styles.dateField}
          minDate={dateLimits.minDateFrom}
          maxDate={dateLimits.maxDateFrom}
        />
        <DatePickerComponent
          text={"Hasta"}
          date={filters.dateTo}
          setDate={setDateTo}
          sx={styles.dateField}
          minDate={dateLimits.minDateTo}
          maxDate={dateLimits.maxDateTo}
        />
        <Selector
          list={selectorTipoImputacionOptionList}
          defaultValue={"Todos"}
          value={filters.tipoImputacion.Nombre}
          setValue={setTipoImputacion}
          sx={styles.selectorField}
          title="Tipo Imputacion"
        />
        {
          !ctaPlus &&
          <Selector
            list={selectorTipoOrigenOptionList}
            defaultValue={"Todos"}
            value={filters.tipoOrigen.Nombre}
            setValue={setTipoOrigen}
            sx={styles.selectorField}
            title="Origen"
          />
        }
      </>
    )
  };

  return (
    <div className="cuenta-especifica-default-div">
      <div style={styles.dateAndTipeContainer}>
        {createInputFilters()}
      </div>
      <div style={styles.sliderContainer}>
        <Typography sx={{ color: colors.primaryMain, ml: "4px" }}>
          Rango [{`${getMoney(filters.min)} - ${getMoney(filters.max)}`}]
        </Typography>
        <div className="cuenta-especifica-default-div">
          <Slider
            min={filters.min}
            max={filters.max}
            setMin={setMin}
            setMax={setMax}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBottom: "0.5rem",
        }}
      >
        <Button variant="contained" onClick={buttonHandler} sx={styles.button}>
          Aplicar filtros
        </Button>
      </div>
    </div>
  );
};

export default Filters;
