import React, { useState, createContext } from "react";
import "./LoginPage.scss";
import LoginHeader from "./LoginHeader";
import ClaveRecoveryPage from "./RecuperarClave/PasswordRecoveryPage";
import {
  Divider,
} from "@mui/material";
import { LoginAction } from "./enums/login-actions.enum";
import LoginForm from "./LoginFormulario/LoginForm";
import UsuarioINSRecoveryPage from "./RecuperarUsuario/UserRecoveryPage";

export const LoginContext = createContext<
  [LoginAction, React.Dispatch<React.SetStateAction<LoginAction>>]
>([LoginAction.Login, undefined]);

const LoginPage = () => {
  const [action, setAction] = useState(LoginAction.Login);

  const handleAction = (action) => {
    switch (action) {
      case LoginAction.Login:
        return <LoginForm></LoginForm>;
      case LoginAction.RecuperarContraseña:
        return <ClaveRecoveryPage></ClaveRecoveryPage>;
      case LoginAction.RecuperarUsuario:
        return <UsuarioINSRecoveryPage></UsuarioINSRecoveryPage>;
    }
  };

  return (
    <div className="login">
      <div className="login__container">
        <LoginContext.Provider value={[action, setAction]}>
          <LoginHeader></LoginHeader>
          <Divider></Divider>
          {handleAction(action)}
        </LoginContext.Provider>
      </div>
    </div>
  );
};

export default LoginPage;
