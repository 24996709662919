import { MAX_VALUE, MIN_VALUE } from "../Slider/TransformValue";
import { ICuentaFilters } from "../../../../services/CuentaService/cuenta.service.interfaces";
import { TipoImputacion, TipoOrigen } from "../../../../services/CuentaService/filtros.service";

export const defaultTipoImputacion: TipoImputacion = { Id: null, Nombre: "TODOS" };
export const defaultTipoOrigen: TipoOrigen = { Id: null, Nombre: "TODOS" };

export const MAX_SALDO = 100000000;

export const MAX_MONTHS_TO_PAST = 3;

export const DefaultFiltersWithNulls: ICuentaFilters = {
  dateFrom: null,
  dateTo: null,
  tipoImputacion: defaultTipoImputacion,
  tipoOrigen: defaultTipoOrigen,
  min: MIN_VALUE,
  max: MAX_VALUE,
  page: 0,
  pageSize: 5,
  pageSizeOptions: [5,10,20]
};

export const DefaultFiltersWithoutNulls: ICuentaFilters = {
  ...DefaultFiltersWithNulls,
  dateFrom: null,
  dateTo: null,
  max: MAX_SALDO,
};

export const getDateLimits = (fromDate: Date, toDate: Date, systemDate?: Date) => {
  let minDateFrom: Date = new Date(Date.now());
  minDateFrom.setMonth(minDateFrom.getMonth() - MAX_MONTHS_TO_PAST);

  let maxDateFrom: Date = new Date(Date.now());
  if (toDate !== null) {
    maxDateFrom = toDate;
  }

  let minDateTo: Date = new Date(Date.now());

  if (fromDate === null) {
    minDateTo.setMonth(minDateTo.getMonth() - MAX_MONTHS_TO_PAST);
  } else {
    minDateTo = fromDate;
  }

  let maxDateTo: Date = new Date(Date.now());
  maxDateTo.setDate(maxDateTo.getDate() + 7);
  
  if (systemDate){
    maxDateTo = new Date(systemDate);
  }

  return {
    minDateFrom: minDateFrom,
    minDateTo: minDateTo,
    maxDateFrom: maxDateFrom,
    maxDateTo: maxDateTo,
  };
};