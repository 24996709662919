import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import Icon from "../Icon/Icon";
import { IconEnum } from "../Icon/Icon.props";

export interface PasswordFieldProps {
  label: string;
  onChange: (newPass: string) => void;
}

const PasswordField = (props: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const currentPassword = event.target.value;
    setPassword(currentPassword);
    props.onChange(currentPassword);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const preventDefaultHandler = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="outlined-adornment-password">
        {props.label}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        className=""
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handleChange}
        onCopy={preventDefaultHandler}
        onCut={preventDefaultHandler}
        label={props.label}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={preventDefaultHandler}
              edge="end"
            >
              {showPassword ? (
                <Icon icon={IconEnum.VISIBILITY_OFF} />
              ) : (
                <Icon icon={IconEnum.VISIBILITY_ON} />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default PasswordField;
