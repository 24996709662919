import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import { HiddenBalanceText } from "../../../HiddenBalanceButton/HiddenBalanceText";
import { CuentaCardProps } from "./CuentaCard.props";
import "../HomeCard.scss";
import colors from "../../../../assets/_themes-vars.module.scss";
import { useNavigate } from "react-router-dom";
import Formatter from "../../../../hooks/formatter.hook";
import ViewMoreButton from "../../../ViewMoreButton/ViewMoreButton";

const CuentaCard = (cuenta: CuentaCardProps) => {
  const navigate = useNavigate();

  return (
    <Card className="home-card">
      <CardContent className="home-card-principal-data">
        <Box className="home-card-principal-data-box">
          <Typography variant={"h2"} className="home-card-title">
            {cuenta.titulo}
          </Typography>
          <Typography variant="h1" className="home-card-balance">
            {cuenta.hiddenBalance
              ? `$ ${HiddenBalanceText}`
              : `${Formatter.formatCurrency(cuenta.total)}`}
          </Typography>
        </Box>
        <Box className="home-card-other-data">
          <Typography
            variant="body2"
            sx={{ color: colors.lightTextTertiary, width: "100%" }}
          >
            {`Nro Socio: ${cuenta.numeroSocio}`}
          </Typography>
        </Box>
      </CardContent>
      <CardActions className="home-card-actions">
        <ViewMoreButton onClick={() => navigate(`/cuenta/movimientos`)} />
      </CardActions>
    </Card>
  );
};

export default CuentaCard;
