import { useState, useEffect } from 'react'
import InversionService, { Inversion, DetalleInversion } from '../../../services/InversionService/inversion.service'
import SwipeableDrawerComponent from '../SwipeableDrawer/SwipeableDrawer'
import InversionDrawerContainer from './InversionDrawerContainer'
import { useSnackbar } from 'notistack';


const InversionDrawer = (props: { inversion: Inversion, setSelectedInversion: Function }) => {

    const [detalle, setDetalle] = useState<DetalleInversion>(null)
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)
    const { inversion, setSelectedInversion } = props
    const { enqueueSnackbar } = useSnackbar()

    const getDetalle = (inversion: Inversion) => {
        InversionService.GetDetalle(inversion.NroCertificado)
            .then(data => {
                setDetalle(data)
                setOpenDrawer(true)
            })
            .catch(error => enqueueSnackbar(`Hubo un error al obtener los detalles de la inversión`, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'bottom' } }))
            .finally(() => { })
    }

    useEffect(() => {
        if (inversion) {
            getDetalle(inversion)
        }
    }, [inversion])

    useEffect(() => {
        if (!openDrawer) setSelectedInversion(null)
    }, [openDrawer])


    return (
        inversion &&
        <SwipeableDrawerComponent
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            anchor='right'
            contentDrawer={<InversionDrawerContainer inversion={inversion} detalle={detalle} />}
            title='Detalle de inversión'
        />

    )
}

export default InversionDrawer