import { DateToStringConverter, NumberToMoneyConverter } from "../../../../components/Formatters/Formatters";
import {
  DefaultBodyCell,
  DefaultSmBodyCell,
} from "../../../../components/Tables/TableWrapper/DefaultBodyCell";
import { Cell } from "../../../../components/Tables/TableWrapper/Table.props";
import { IBreakpoints } from "../../../../hooks/breakpoints.hook";
import { MovementAllData } from "../../../../services/CuentaService/cuenta.service.interfaces";
import colors from "../../../../assets/_themes-vars.module.scss";
import DownloadIconButton from "../../../../components/DownloadButton/DownloadIconButton";

const getMontoCell = (monto: number, isSmUp: boolean): Cell => {
  const montoStr = NumberToMoneyConverter(monto);
  const montoCell = isSmUp
    ? DefaultBodyCell(montoStr)
    : DefaultSmBodyCell(montoStr);
  const montoCellSx = {
    ...montoCell.sx,
    color: monto < 0 ? colors.negativeMoney : colors.positiveMoney,
  };
  return { ...montoCell, sx: montoCellSx };
};

export const getBodyCells = (
  movements: MovementAllData[],
  bp: IBreakpoints,
  ctaRemunerada?: boolean
): Cell[][] => {
  const bodyCells: Cell[][] = [];
  movements.forEach((movement) => {
    let fechaCell: Cell = bp.isSmUp
      ? DefaultBodyCell(DateToStringConverter(movement?.Fecha))
      : DefaultSmBodyCell(DateToStringConverter(movement?.Fecha));
    let conceptoCell: Cell = bp.isSmUp
      ? {
        ...DefaultBodyCell(movement.Concepto),
        align: "left",
        sx: {
          ...DefaultBodyCell(movement.Concepto).sx,
          whiteSpace: "nowrap",
        },
      }
      : {
        ...DefaultSmBodyCell(movement.Concepto),
        align: "left",
      };
    let referenciaCell: Cell = {
      ...DefaultBodyCell(movement.Referencia),
      align: "left",
    };
    let montoCell: Cell = getMontoCell(movement?.Monto, bp.isSmUp);
    let comprobanteCell: Cell = DefaultBodyCell(movement?.Comprobante);
    let downloadCell: Cell = movement.TienePDF
      && !movement.HasCuentaRemunerada ? {
      ...DefaultBodyCell(movement?.Comprobante),
      pl: "0px",
      pr: "0px",
      textVariant: "element",
      element: (
        <DownloadIconButton
          style={{ padding: 0, marginLeft: "-35px" }}
          downloadProps={{
            NComprobante: parseInt(movement?.Comprobante),
            TipoMovimiento: movement?.TipoMovimiento,
          }}
        />
      ),
    }
      : DefaultBodyCell("");

    let cellList: Cell[] = [];
    if (bp.isXlUp) {
      cellList = [
        fechaCell,
        conceptoCell,
        !ctaRemunerada && referenciaCell,
        montoCell,
        comprobanteCell,
        downloadCell,
      ];
    } else if (bp.is1150Up) {
      cellList = [
        fechaCell,
        conceptoCell,
        montoCell,
        comprobanteCell,
        downloadCell,
      ];
    } else {
      cellList = [fechaCell, conceptoCell, montoCell];
    }

    bodyCells.push(cellList);
  });

  return bodyCells;
};
