import { Divider, Typography, Stack } from '@mui/material'


const TarjetasPage = () => (
    <div className="prestamos-header">

        <Typography
          variant="h1"
          sx={{ color: "text.primary", m: "0.5rem", my: "0" }}
        >
          <Stack direction="row" spacing={1} style={{ display: 'flex', alignItems: 'center' }}  >
            <div> Tarjetas</div>
          </Stack>
        </Typography>
        <Divider
          sx={{
            width: "100%",
            mb: "1rem",
            mt: "0.3rem",
            borderColor: "text.primary",
          }}
        />
        <div>
            Bienvenido a la pantalla de Tarjetas
        </div>
      </div >
)

export default TarjetasPage;