import {
  GetNotificationsFilters,
  Notification,
} from "../../components/Drawer/NotificationsDrawer/Notifications.interfaces";
import HomeApi from "../../config/axios";
import { NotificationAdapter } from "./Notifcation.service.adapter";

interface GetNotificationsResult {
  Notifications: Notification[];
  TotalUnread: number;
  TotalNotifications: number;
}

const base = "notificaciones";

export const NotificationService = {
  GetNotifications: (
    filters: GetNotificationsFilters
  ): Promise<GetNotificationsResult> => {
    return new Promise<GetNotificationsResult>((resolve, reject) => {
      HomeApi.get(base, {
        params: {
          Page: filters.Page,
          PageSize: filters.PageSize,
        },
      })
        .then((response) => {
          resolve({
            ...response.data,
            Notifications: response.data.Notifications.map((n) =>
              NotificationAdapter(n)
            ),
          });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  MarkAsReadNotifications: (notificationIdsList: number[]) => {
    return new Promise<void>((resolve, reject) => {
      HomeApi.patch(`${base}/marcar-como-leidas`, {
        NotificationIds: notificationIdsList,
      })
        .then(() => resolve())
        .catch((error) => {
          reject(error.message);
        });
    });
  },
};
