import { Grid, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import colors from '../../../../src/assets/_themes-vars.module.scss'

const SwipeableDrawerHeader = (props: { titulo: string, setOpenDrawer: Function }) => {

    const { titulo, setOpenDrawer } = props

    const Header = styled(Grid)({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: colors.primaryMain,
        height: 68,
    })

    return (
        <Header item>
            <IconButton onClick={() => setOpenDrawer(false)}>
                <DoubleArrowIcon color='info' />
            </IconButton>
            <Typography variant='h2' sx={{ color: colors.darkTextTitle, marginRight: '1rem' }}>
                {titulo}
            </Typography>
        </Header>
    )
}

export default SwipeableDrawerHeader