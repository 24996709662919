import { IconButton } from "@mui/material";
import Icon from "../Icon/Icon";
import { IconEnum } from "../Icon/Icon.props";
import { HiddenBalanceButtonProps } from "./HiddenBalanceButtonProps.props";

const HiddenBalanceButton = (props: HiddenBalanceButtonProps) => {
  return (
      <IconButton
        onClick={props.onClick}
        disableRipple={true}
        sx={{ color: "text.primary"}}
      >
        {props.hiddenBalances ? Icon({ icon: IconEnum.VISIBILITY_ON }) : Icon({ icon: IconEnum.VISIBILITY_OFF })}
      </IconButton>
  );
};

export default HiddenBalanceButton;
