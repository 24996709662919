import { Grid } from '@mui/material'
import '../InversionConstitucionPage.scss'
import CuentaSeleccionadaContent from './CuentaSeleccionadaContent'
import CertificadoConstitucionContent from './CertificadoConstitucionContent'
import PlazoVencimientoContent from './PlazoVencimientoContent'
import { AccionVencimientoConstitucion, TitularAdicional } from '../../../services/InversionService/inversion.service'
import { OrdenCertificado } from '../../../services/InversionService/inversion.service'
import { TipoVencimiento } from '../TipoVencimiento'
import { Contacto } from '../../../services/ContactoService/contacto.service'
import { UsuarioCuenta } from '../../../contexts/UsuarioContext'

const PrimerPasoConstitucionPage = (
    props: {
        ordenesCertificado: OrdenCertificado[],
        monto: number,
        setMonto: React.Dispatch<React.SetStateAction<number>>,
        saldoCuenta: number,
        selectedOrdenCertificado: OrdenCertificado,
        setSelectedOrdenCertificado: React.Dispatch<React.SetStateAction<OrdenCertificado>>,
        accionesVencimiento: AccionVencimientoConstitucion[],
        selectedAccionVencimiento: AccionVencimientoConstitucion,
        setSelectedAccionVencimiento: React.Dispatch<React.SetStateAction<AccionVencimientoConstitucion>>,
        tiposVencimiento: TipoVencimiento[]
        selectedTipoVencimiento: TipoVencimiento,
        setSelectedTipoVencimiento: React.Dispatch<React.SetStateAction<TipoVencimiento>>,
        plazoDias: number,
        setPlazoDias: React.Dispatch<React.SetStateAction<number>>,
        fechaVencimiento: Date,
        setFechaVencimiento: React.Dispatch<React.SetStateAction<Date>>,
        pasoActual: number,
        pasoValidado: boolean,
        titulares: Contacto[],
        selectedTitulares: Contacto[],
        setSelectedTitulares: React.Dispatch<React.SetStateAction<Contacto[]>>,
        selectedCuentaUsuario: UsuarioCuenta,
        getDiffDays: Function,
        systemDate: Date,
        plazoReal: number,
        setPlazoReal: Function,
    }
) => {

    const { ordenesCertificado, selectedOrdenCertificado, setSelectedOrdenCertificado, accionesVencimiento, selectedAccionVencimiento, setSelectedAccionVencimiento,
        tiposVencimiento, selectedTipoVencimiento, setSelectedTipoVencimiento, plazoDias, setPlazoDias, fechaVencimiento, setFechaVencimiento, pasoActual,
        titulares, selectedTitulares, setSelectedTitulares, monto, setMonto, pasoValidado, saldoCuenta, selectedCuentaUsuario, getDiffDays, systemDate, plazoReal, setPlazoReal
    } = props
    return (
        <>
            <CuentaSeleccionadaContent
                selectedCuentaUsuario={selectedCuentaUsuario}
                saldoCuenta={saldoCuenta}
                monto={monto}
                setMonto={setMonto}
                pasoActual={pasoActual}
                pasoValidado={pasoValidado}
            />

            <CertificadoConstitucionContent
                ordenesCertificado={ordenesCertificado}
                selectedOrdenCertificado={selectedOrdenCertificado}
                setSelectedOrdenCertificado={setSelectedOrdenCertificado}
                pasoActual={pasoActual}
                pasoValidado={pasoValidado}
                titulares={titulares}
                selectedTitulares={selectedTitulares}
                setSelectedTitulares={setSelectedTitulares}
            />
            <PlazoVencimientoContent
                accionesVencimiento={accionesVencimiento}
                selectedAccionVencimiento={selectedAccionVencimiento}
                setSelectedAccionVencimiento={setSelectedAccionVencimiento}
                tiposVencimiento={tiposVencimiento}
                selectedTipoVencimiento={selectedTipoVencimiento}
                setSelectedTipoVencimiento={setSelectedTipoVencimiento}
                plazoDias={plazoDias}
                setPlazoDias={setPlazoDias}
                fechaVencimiento={fechaVencimiento}
                setFechaVencimiento={setFechaVencimiento}
                pasoActual={pasoActual}
                pasoValidado={pasoValidado}
                getDiffDays={getDiffDays}
                systemDate={systemDate}
                plazoReal={plazoReal}
                setPlazoReal={setPlazoReal}
            />
        </>
    )
}

export default PrimerPasoConstitucionPage