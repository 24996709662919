import { Badge, Grid, IconButton } from "@mui/material";
import Icon from "../../Icon/Icon";
import { IconEnum } from "../../Icon/Icon.props";
import {
  GetNotificationsFilters,
  Notification,
  defaultNotificationFilters,
} from "./Notifications.interfaces";
import { useEffect, useState } from "react";
import { UseUsuario } from "../../../contexts/UsuarioContext";
import { NotificationService } from "../../../services/NotificationsService/Notification.service";
import { IsNaNOrUndefined } from "../../Utils/HelperFunctions";
import SwipeableDrawerComponent from "../SwipeableDrawer/SwipeableDrawer";
import NotificationsList from "./NotificationsList";
import { GetFetcherScroll } from "../../Utils/FetcherScrollHandler";
import useScrollVisibility from "../../../hooks/useScrollVisibility.hook";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback";

const Notifications = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [totalNotifications, setTotalNotifications] =
    useState<number>(undefined);
  const [totalUnread, setTotalUnread] = useState<number>(undefined);
  const [filters, setFilters] = useState<GetNotificationsFilters>(
    defaultNotificationFilters
  );

  const [toUpdateNotifications, setToUpdateNotifications] = useState<
    Notification[]
  >([]);
  const updateNotifications = useDebouncedCallback(() => {
    NotificationService.MarkAsReadNotifications(
      toUpdateNotifications.map((n) => n.Id)
    ).then(() => setToUpdateNotifications([]));
  }, 1500);

  const notificationListId = "NotificationList-ID";
  const { isScrollVisible } = useScrollVisibility(notificationListId, [
    notifications.length,
    openDrawer,
  ]);

  const { selectedCuentaUsuario } = UseUsuario();
  const hasMoreNotifications = notifications.length < totalNotifications;

  const getNotifications = () => {
    setIsLoading(true);
    NotificationService.GetNotifications({
      ...filters,
    })
      .then((response) => {
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...response.Notifications,
        ]);
        setTotalNotifications(response.TotalNotifications);
        if (totalUnread === undefined) setTotalUnread(response.TotalUnread);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setFilters({
      ...defaultNotificationFilters,
      NroSocio: Number.parseInt(selectedCuentaUsuario?.NroSocio),
      NroFilial: Number.parseInt(selectedCuentaUsuario?.FilialId),
    });

    setNotifications([]);
    setTotalNotifications(undefined);
    setTotalUnread(undefined);
  }, [selectedCuentaUsuario?.NroSocio, selectedCuentaUsuario?.FilialId]);

  useEffect(() => {
    if (
      !IsNaNOrUndefined(filters.NroSocio) &&
      !IsNaNOrUndefined(filters.NroFilial)
    )
      getNotifications();
  }, [filters]);

  const notificationIcon =
    totalUnread > 0
      ? IconEnum.NOTIFICATIONS_ACTIVE
      : IconEnum.NOTIFICATIONS_NONE;

  const addNotificationsPage = () => {
    if (hasMoreNotifications) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        Page: prevFilters.Page + 1,
      }));
    }
  };

  useEffect(() => {
    if (openDrawer && !isScrollVisible) {
      addNotificationsPage();
    }
  }, [openDrawer, notifications.length]);

  const markAsRead = (notification: Notification) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((n) =>
        n.Id === notification.Id ? { ...n, IsRead: true } : n
      )
    );
    setToUpdateNotifications((prevToUpdate) => [...prevToUpdate, notification]);
    setTotalUnread((prevTotal) => Math.max(prevTotal - 1, 0));
  };

  useEffect(() => {
    if (toUpdateNotifications.length > 0) updateNotifications();
  }, [toUpdateNotifications]);

  return (
    <Grid item>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => setOpenDrawer(true)}
      >
        <Badge badgeContent={totalUnread} color="error">
          <Icon icon={notificationIcon} />
        </Badge>
      </IconButton>
      <SwipeableDrawerComponent
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        anchor="right"
        title="Notificaciones"
        contentDrawer={
          <NotificationsList
            handleScroll={GetFetcherScroll(addNotificationsPage)}
            isLoading={isLoading}
            notifications={notifications}
            markAsRead={markAsRead}
            notificationListId={notificationListId}
            noMoreNotifications={!hasMoreNotifications}
            totalUnread={totalUnread}
          />
        }
      />
    </Grid>
  );
};

export default Notifications;
