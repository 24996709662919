import ElementConstitucion from "./ElementConstitucion"
import colors from '../../../assets/_themes-vars.module.scss'
import { Grid, Divider, Typography } from '@mui/material'
import Formatter from "../../../hooks/formatter.hook"
import { UsuarioCuenta } from "../../../contexts/UsuarioContext"
import { DetalleInversionConstitucion } from '../../../services/InversionService/inversion.service'

const SeccionCuenta = (props: { selectedCuenta: UsuarioCuenta, detalleSimulacion: DetalleInversionConstitucion  }) => {
    const { selectedCuenta, detalleSimulacion } = props
    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: colors.primaryMain, marginBottom: '.5rem' }}>Datos de la cuenta</Typography>
                <Divider></Divider>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
                <ElementConstitucion label='Cuenta' value={`${selectedCuenta.NroSocio}`} />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
                <ElementConstitucion label='Filial' value={`${selectedCuenta.Filial}`} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <ElementConstitucion label='Socio' value={`${selectedCuenta.NombreSocio}`} />
            </Grid>
        </Grid>
    )
}

export default SeccionCuenta