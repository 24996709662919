import { useState } from "react";
import { PrestamoAndCuotaIdentifier } from "../interfaces/Prestamo";
import PrestamoService from "../../../services/PrestamoService/prestamo.service";
import { useSnackbar } from "notistack";
import {
  defaultSuccessSnackbar,
  homeDisabledSnackbar,
} from "../../../utils/snackBarConfigs.util";
import { homeProbablyDisabled } from "../../../utils/genericErrorMessages";

interface UsePagarCuotaPrestamoProps {
  onSuccessPayment: () => void;
  onFailedPayment: () => void;
}

interface UsePagarCuotaPrestamoResult {
  isConfirmationOpen: boolean;
  isPaying: boolean;
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pay: (cuota: PrestamoAndCuotaIdentifier) => void;
}

const usePagarCuotaPrestamo = (
  props: UsePagarCuotaPrestamoProps
): UsePagarCuotaPrestamoResult => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isPaying, setIsPaying] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const PagarCuota = (cuota: PrestamoAndCuotaIdentifier) => {
    setIsPaying(true);
    PrestamoService.PagarCuotaPrestamo(cuota)
      .then(() => {
        props.onSuccessPayment();
        enqueueSnackbar(
          "Pago de cuota realizado satisfactoriamente",
          defaultSuccessSnackbar
        );
      })
      .catch(() => {
        props.onFailedPayment();
        enqueueSnackbar(homeProbablyDisabled, homeDisabledSnackbar);
      })
      .finally(() => setIsPaying(false));
  };
  return {
    isConfirmationOpen,
    isPaying,
    setIsConfirmationOpen,
    pay: PagarCuota,
  };
};

export default usePagarCuotaPrestamo;
