import { AccordionSeccionProps } from "./AccordionSeccion.props"
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import Icon from '../../../components/Icon/Icon'
import { IconEnum } from '../../../components/Icon/Icon.props'
import { useState } from 'react'
import colors from '../../../assets/_themes-vars.module.scss'
import "../InversionConstitucionPage.scss"

const AccordionSeccion = (props: AccordionSeccionProps) => {

    const [openAccordion, setOpenAccordion] = useState<boolean>(true)

    const { legend, content } = props


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Accordion
                    expanded={openAccordion}
                    className="accordion-container"
                    onChange={() => setOpenAccordion(!openAccordion)}
                    color="primary"
                >
                    <AccordionSummary
                        expandIcon={
                            <Icon icon={IconEnum.RIGHT} sx={{ color: colors.primaryMain }} />
                        }
                        sx={{
                            flexDirection: "row-reverse",
                            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                                transform: "rotate(90deg)",
                            },
                        }}
                    >
                        <Typography variant="h3" sx={{ color: colors.primaryMain }}>
                            {legend}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ borderTop: `1px solid ${colors.primaryMain}` }}>
                        {content}
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )
}

export default AccordionSeccion