import { useEffect, useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { ManageAccountsOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { LayoutContext } from "../../contexts/LayoutContext";
import logo from "../../assets/images/old_logo_mutual_borde.png";
import SwitchCuentasDrawer from "../Drawer/SwitchCuentasDrawer/SwitchCuentasDrawer";
import { UseUsuario } from "../../contexts/UsuarioContext";
import "./navBarComponent.scss";
import Notifications from "../Drawer/NotificationsDrawer/Notifications";
import { useSystemDate } from "../../contexts/SystemDateContext";
import { DateToStringConverter } from "../Formatters/Formatters";

const NavBarComponent = () => {
  const theme = useTheme();
  const [layoutContext, setLayoutContext] = useContext(LayoutContext);
  const [isSelectingUserAccount, setIsSelectingUserAccount] =
    useState<boolean>(false);
  const { selectedCuentaUsuario } = UseUsuario();
  const systemDate = useSystemDate();

  const toggleMenu = () => {
    setLayoutContext({
      ...layoutContext,
      menuOpened: !layoutContext.menuOpened,
    });
  };

  const handleClick = () => {
    setIsSelectingUserAccount(!isSelectingUserAccount);
  };

  useEffect(() => {
    window.screen.width <= 768 &&
      setLayoutContext({
        ...layoutContext,
        menuOpened: !layoutContext.menuOpened,
      });
  }, []);

  return (
    <>
      <AppBar
        enableColorOnDark
        position="fixed"
        sx={{
          zIndex: 3000,
          transition: layoutContext.menuOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              [theme.breakpoints.down("md")]: {
                width: "auto",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                [theme.breakpoints.down("md")]: {
                  width: "auto",
                },
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  flexGrow: 1,
                  justifyContent: "center",
                  marginRight: "15px",
                }}
              >
                <img src={logo} width="60px" alt="Logo" />
              </Box>
            </Box>
            <Typography
              variant="h5"
              className="nav-bar-system-date-text"
            >
              {DateToStringConverter(systemDate)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Notifications />
            <Grid item xs={1}>
              <IconButton
                size="small"
                color="inherit"
                onClick={() => handleClick()}
              >
                <ManageAccountsOutlined />
              </IconButton>
            </Grid>
            <Typography
              variant="h5"
              className="nav-bar-client-name-text"
              sx={{ whiteSpace: "nowrap" }}
            >
              {selectedCuentaUsuario?.NombreSocio}
            </Typography>
          </Box>
        </Toolbar>
        <SwitchCuentasDrawer
          openDrawer={isSelectingUserAccount}
          setOpenDrawer={setIsSelectingUserAccount}
        />
      </AppBar>
    </>
  );
};

export default NavBarComponent;
