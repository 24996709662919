import { CircularProgress } from "@mui/material";
import { LoaderProps } from "./Loader.props";

const Loader = (props : LoaderProps) => {
    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress style={{width: '50px', height: '50px'}}/>
            <span style={{marginTop: '10px', color: '#004080', fontWeight: 'bold'}}>{props.label}</span>
        </div>
    );
}

export default Loader;
