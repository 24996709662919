import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import colors from "../../../assets/_themes-vars.module.scss";
import Formatter from "../../../hooks/formatter.hook";
import DownloadButton from "../../DownloadButton/DownloadButton";
import { DateToStringConverter } from "../../Formatters/Formatters";
import { TipoMovimientoEnum } from "../../../services/ComprobantesService/comprobantes.service.interfaces";

const InversionDrawerList = (props: {
  nroFilial: number;
  nroSocio: number;
  nroCertificado: number;
  fechaCertificado: Date;
  tasaMensual: number;
  tasaAnual: number;
  fechaVencimiento: Date;
  capital: number;
  interes: number;
  gastosAdministrativos: number;
  sellado: number;
  plazo: number;
  orden: number;
  accionVto: string;
  datosTitular: string;
  datosCotitular1: string;
  datosCotitular2: string;
  totalACobrar: number;
  tipoMovimiento: TipoMovimientoEnum;
}) => {
  const {
    nroFilial,
    nroSocio,
    nroCertificado,
    fechaCertificado,
    tasaMensual,
    tasaAnual,
    fechaVencimiento,
    capital,
    interes,
    gastosAdministrativos,
    sellado,
    plazo,
    orden,
    accionVto,
    datosTitular,
    datosCotitular1,
    datosCotitular2,
    totalACobrar,
    tipoMovimiento,
  } = props;

  return (
    <>
      <HeaderDetalle
        totalACobrar={totalACobrar}
        fechaVencimiento={fechaVencimiento}
      />
      <List>
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        {/* Nro de certificado */}
        <InversionDetalle
          title="Nro. Comprobante"
          value={`${nroCertificado}`}
        />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        {/* Fecha del certificado */}
        <InversionDetalle
          title="Fecha Constitución"
          value={`${DateToStringConverter(fechaCertificado)}`}
        />
        <Divider sx={{ borderBottomWidth: 4 }} variant="middle" />

        {/* Capital invertido */}
        <InversionDetalle
          title={"Capital Invertido"}
          value={`${Formatter.formatCurrency(capital)}`}
        />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        {/* Intereses generados */}
        <InversionDetalle
          title={"Intereses"}
          value={`${Formatter.formatCurrency(interes)}`}
        />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        {/* Plazo */}
        <InversionDetalle title={"Plazo"} value={`${plazo}`} />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        {/* Tasa nominal anual */}
        <InversionDetalle title="Tasa T.N.A." value={`${tasaAnual} %`} />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        {/* Tasa efectiva mensual */}
        <InversionDetalle title={"Tasa T.E.M."} value={`${tasaMensual} %`} />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        {/* Gasto Sellado */}
        <InversionDetalle
          title={"Gastos Sellado"}
          value={`${Formatter.formatCurrency(sellado)}`}
        />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        {/* Gastos Administrativos */}
        <InversionDetalle
          title={"Gastos Administrativos"}
          value={`${Formatter.formatCurrency(gastosAdministrativos)}`}
        />
        <Divider sx={{ borderBottomWidth: 4 }} variant="middle" />

        {/* Datos titular */}
        <InversionDetalle title={"Titular"} value={`${datosTitular}`} />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        {/* Datos cotitular 1 */}
        {datosCotitular1 && (
          <>
            <InversionDetalle
              title={"Cotitular"}
              value={`${datosCotitular1}`}
            />
            <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
          </>
        )}
        {/* Datos cotitular 2 */}
        {datosCotitular2 && (
          <>
            <InversionDetalle
              title={"Cotitular"}
              value={`${datosCotitular2}`}
            />
            <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
          </>
        )}
        <InversionDetalle
          title={"Orden"}
          value={`${
            orden === 1
              ? "ORDEN INDISTINTA"
              : orden === 2
              ? "ORDEN CONJUNTA"
              : "UNIPERSONAL"
          }`}
        />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        <InversionDetalle title={"Acción al Vto."} value={`${accionVto}`} />
        <Divider sx={{ borderBottomWidth: 2 }} variant="middle" />
        <DownloadButton
          text={"Comprobante"}
          containerStyle={{ marginRight: "15px", marginTop: "15px" }}
          downloadProps={{
            NComprobante: nroCertificado,
            TipoMovimiento: tipoMovimiento,
          }}
        />
      </List>
    </>
  );
};

export default InversionDrawerList;

const HeaderDetalle = (props: {
  totalACobrar: number;
  fechaVencimiento: Date;
}) => {
  return (
    <div className="inversion-drawer-header-container">
      <Typography variant="h4" className="inversion-drawer-header-subtitle">
        Total a cobrar
      </Typography>
      <Typography variant="h1" className="inversion-drawer-header-balance">
        {Formatter.formatCurrency(props.totalACobrar)}
      </Typography>
      <Typography variant="h4" className="inversion-drawer-header-subtitle">
        Fecha Vto: {DateToStringConverter(props.fechaVencimiento)}
      </Typography>
    </div>
  );
};

const KeyDetalle = (props: { title: string }) => {
  const { title } = props;

  return (
    <Typography
      variant="h4"
      sx={{ fontWeight: "normal", color: colors.grey900 }}
    >
      {title}
    </Typography>
  );
};

const ValueDetalle = (props: { value: string }) => {
  const { value } = props;
  return (
    <Typography variant="h4" sx={{ fontWeight: "bold", color: colors.grey900 }}>
      {value}
    </Typography>
  );
};

const InversionDetalle = (props: { title: string; value: string }) => {
  const { title, value } = props;
  return (
    <ListItem sx={{ gap: "15px" }}>
      <ListItemText primary={<KeyDetalle title={title} />} />
      <ListItemText
        sx={{ textAlign: "right" }}
        primary={<ValueDetalle value={value} />}
      />
    </ListItem>
  );
};
