import { GetValueInRange } from "./GetNumberValueInRange";

export const TransformValue = (value: number, sliderStep: number) => {
  let number: number;
  if (value <= 10) {
    number = GetValueInRange({
      sliderRange: { min: 0, max: 10 },
      finalRange: { min: 0, max: 1000 },
      sliderStep: sliderStep,
      value: value,
    });
  }

  if (value > 10 && value <= 28) {
    number = GetValueInRange({
      sliderRange: { min: 10, max: 28 },
      finalRange: { min: 1000, max: 10000 },
      sliderStep: sliderStep,
      value: value,
    });
  }

  if (value > 28 && value <= 46) {
    number = GetValueInRange({
      sliderRange: { min: 28, max: 46 },
      finalRange: { min: 10000, max: 100000 },
      sliderStep: sliderStep,
      value: value,
    });
  }
  if (value > 46 && value <= 64) {
    number = GetValueInRange({
      sliderRange: { min: 46, max: 64 },
      finalRange: { min: 100000, max: 1000000 },
      sliderStep: sliderStep,
      value: value,
    });
  }
  if (value > 64 && value <= 82) {
    number = GetValueInRange({
      sliderRange: { min: 64, max: 82 },
      finalRange: { min: 1000000, max: 10000000 },
      sliderStep: sliderStep,
      value: value,
    });
  }
  if (value > 82) {
    number = GetValueInRange({
      sliderRange: { min: 82, max: 100 },
      finalRange: { min: 10000000, max: 100000000 },
      sliderStep: sliderStep,
      value: value,
    });
  }
  return Math.round(number);
};

export const MIN_VALUE = 0;
export const MAX_VALUE = 100000000;
