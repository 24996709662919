import { Box, SxProps, Theme, Typography } from "@mui/material";
import useBreakpoints from "../../../../hooks/breakpoints.hook";
import { MovementAllData } from "../../../../services/CuentaService/cuenta.service.interfaces";
import { EmptyToString } from "../../../../components/Formatters/Formatters";
import DownloadButton from "../../../../components/DownloadButton/DownloadButton";

const HiddenLineText = (hiddenLineProps: {
  title: string;
  text: string;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Box sx={{ ...hiddenLineProps.sx, display: "flex" }}>
      <Typography variant="body2" sx={{ fontWeight: "bold" }} align="left">
        {`${hiddenLineProps.title}:`}
      </Typography>
      &nbsp;
      <Typography variant="body2" align="left">
        {`${hiddenLineProps.text}`}
      </Typography>
    </Box>
  );
};

const DivHiddenContainer = (divHiddenProps: { movement: MovementAllData }) => {
  const bp = useBreakpoints();
  return (
    <div className="last-movements-cuenta-especifica-hidden-section">
      {bp.isXlDown && (
        <HiddenLineText
          title={"Referencia"}
          text={EmptyToString(divHiddenProps.movement?.Referencia, "--")}
          sx={{ width: "100%" }}
        />
      )}
      {bp.isSmDown && (
        <HiddenLineText
          title={"Concepto"}
          text={EmptyToString(divHiddenProps.movement?.Concepto, "--")}
          sx={{ width: "100%" }}
        />
      )}
      {bp.is1150Down && (
        <>
          <HiddenLineText
            title={"Comprobante"}
            text={divHiddenProps.movement?.Comprobante}
          />
          {divHiddenProps.movement?.TienePDF && (
            <div className="last-movements-cuenta-especifica-hidden-section-download">
              <DownloadButton
                downloadProps={{
                  NComprobante: parseInt(divHiddenProps.movement?.Comprobante),
                  TipoMovimiento: divHiddenProps.movement?.TipoMovimiento,
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const HiddenContent = (movements: MovementAllData[]): JSX.Element[] => {
  return movements.map((movement) => (
    <DivHiddenContainer key={`Movement-${movement.Id}`} movement={movement} />
  ));
};

export default HiddenContent;
