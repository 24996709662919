import { useState } from "react";
import SessionService from "../../services/session.service";
import { useSnackbar } from "notistack";
import AuthenticationService from "../../services/Login/Authentication.service";
import {
  defaultErrorSnackbar,
  defaultSuccessSnackbar,
} from "../../utils/snackBarConfigs.util";

interface ChangePasswordState {
  dni: string;
  usuario: string;
  passwords: PasswordsState;
  errors: ChangePasswordErrorState;
  onChangePasswords: OnChangePasswords;
  isChangingPassword: boolean;
  onConfirmChangePassword: () => void;
}

interface PasswordsState {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface OnChangePasswords {
  onChangeCurrentPassword: (newPass: string) => void;
  onChangeNewPassword: (newPass: string) => void;
  onChangeConfirmNewPassword: (newPass: string) => void;
}

class ChangePasswordErrorState {
  currentPasswordError: string;
  newPasswordError: string;
  confirmNewPasswordError: string;

  constructor() {
    this.currentPasswordError = "";
    this.newPasswordError = "";
    this.confirmNewPasswordError = "";
  }

  hasErrors(): boolean {
    return (
      !!this.currentPasswordError ||
      !!this.newPasswordError ||
      !!this.confirmNewPasswordError
    );
  }
}

const defaultChangePasswordState: PasswordsState = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const useChangePasswordState = (): ChangePasswordState => {
  const { dni, usuarioINS } = SessionService.GetLoginInformation();
  const [passwords, setPasswords] = useState<PasswordsState>(
    defaultChangePasswordState
  );
  const [errors, setErrors] = useState<ChangePasswordErrorState>(
    new ChangePasswordErrorState()
  );
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirmChangePassword = () => {
    const newErrors = CheckPasswords(passwords);
    setErrors(newErrors);
    if (!newErrors.hasErrors()) {
      ChangePassword();
    }
  };

  const ChangePassword = () => {
    setIsChangingPassword(true);
    AuthenticationService.ChangePassword({
      NroDocumento: dni,
      Usuario: usuarioINS,
      Password: passwords.currentPassword,
      NewPassword: passwords.newPassword,
    })
      .then(() => {
        enqueueSnackbar(
          "Contraseña actualizada. Ingrese nuevamente al sistema",
          defaultSuccessSnackbar
        );
        setTimeout(() => {
          AuthenticationService.Logout();
          AuthenticationService.NavigateToDefaultLogin();
        }, 3000);
      })
      .catch(() => {
        enqueueSnackbar(
          "Error al actualizar contraseña. Verifique los campos ingresados.",
          defaultErrorSnackbar
        );
        setIsChangingPassword(false);
      });
  };

  const handleChange = (field: string) => (newPass: string) => {
    setPasswords((prevState) => ({ ...prevState, [field]: newPass }));
  };

  return {
    dni,
    usuario: usuarioINS,
    passwords,
    errors,
    onChangePasswords: {
      onChangeCurrentPassword: handleChange("currentPassword"),
      onChangeNewPassword: handleChange("newPassword"),
      onChangeConfirmNewPassword: handleChange("confirmNewPassword"),
    },
    isChangingPassword,
    onConfirmChangePassword: handleConfirmChangePassword,
  };
};

const CheckPasswords = (
  passwords: PasswordsState
): ChangePasswordErrorState => {
  const checks: ChangePasswordErrorState = new ChangePasswordErrorState();

  if (passwords.currentPassword === "") {
    checks.currentPasswordError = "Este campo no puede estar vacío";
  }

  const passRegexValidation =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;

  if (!passRegexValidation.test(passwords.newPassword)) {
    checks.newPasswordError =
      "La contraseña debe tener al menos 8, al menos una letra minúscula, mayúscula, número y caracter especial";
  }

  if (passwords.confirmNewPassword !== passwords.newPassword) {
    checks.confirmNewPasswordError = "Las contraseñas no coinciden";
  }

  return checks;
};

export default useChangePasswordState;
