import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";
import colors from "../../../../assets/_themes-vars.module.scss";
import useBreakpoints from "../../../../hooks/breakpoints.hook";

export const useFilterResponsiveStyles = (ctaPlus?: boolean) => {
  const bp = useBreakpoints();

  let dateAndTipeContainerStyle: CSSProperties = {
    width: "63%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
  };

  if (bp.isXlDown) {
    dateAndTipeContainerStyle = {
      ...dateAndTipeContainerStyle,
      width: "100%",
      justifyContent: "space-between",
    };
  }

  let sliderContainerStyle: CSSProperties = {
    width: "32%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    marginLeft: "1.15rem",
    marginRight: "1.15rem",
  };

  if (bp.isXlDown) {
    sliderContainerStyle = {
      ...sliderContainerStyle,
      width: "100%",
    };
  }

  if (bp.isSmDown) {
    sliderContainerStyle = {
      ...sliderContainerStyle,
      paddingRight: "1rem",
    };
  }

  let textFieldStyle: SxProps<Theme> = {
    width: ctaPlus ? "22%" : "30%",
    m: "0.5rem",
  };

  if (bp.is1150Down) {
    textFieldStyle = { ...textFieldStyle, width: "45%" };
  }

  if (bp.isSmDown) {
    textFieldStyle = { ...textFieldStyle, width: "100%" };
  }

  let selectorFieldStyle: SxProps<Theme> = {
    width: ctaPlus ? "22%" : "30%",
    m: "0.5rem",
    "&:after": {
      borderBottomColor: colors.primaryMain,
    },
    "& .MuiSvgIcon-root": {
      color: colors.primaryMain,
    },
  };

  if (bp.is1150Down) {
    selectorFieldStyle = { ...selectorFieldStyle, width: "45%" };
  }

  if (bp.isSmDown) {
    selectorFieldStyle = { ...textFieldStyle, width: "100%" };
  }

  let buttonStyle: SxProps<Theme> = {
    width: "13.7%",
    height: "50px",
    mx: "0.5rem",
  };

  if (bp.isXlDown) {
    buttonStyle = { ...buttonStyle, width: "21.75%" };
  }

  if (bp.is1000Down) {
    buttonStyle = { ...buttonStyle, width: "45%" };
  }

  if (bp.isSmDown) {
    buttonStyle = { ...buttonStyle, width: "100%" };
  }

  return {
    dateAndTipeContainer: dateAndTipeContainerStyle,
    sliderContainer: sliderContainerStyle,
    dateField: textFieldStyle,
    selectorField: selectorFieldStyle,
    button: buttonStyle,
  };
};

export default useFilterResponsiveStyles;
