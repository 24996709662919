import { useState } from "react";
import "../Home/HomePage.scss";
import QuickAccessMenuComponent from "./PageComponents/QuickAccessMenu/QuickAccessMenuComponent";
import { SectionHeader } from "../../components/Headers/SectionHeader";
import { useCardDivResponsiveStyle } from "./PageComponents/CardResponsiveStyle.hook";
import Header from "./PageComponents/Header";
import useHome from "./PageComponents/useHome.hook";
import CuentaCardList from "../../components/Cards/HomeCards/CuentaCard/CuentaCardList";
import LineaPrestamoCardList from "../../components/Cards/HomeCards/LineaPrestamoCard/LineaPrestamoCardList";
import GetResumenInversionesCard from "../../components/Cards/HomeCards/ResumenInversionesCard/GetResumenInversionesCard";
import HomeCardSection from "./PageComponents/HomeCardSection.component";
import LastMovementsHomeSection from "./PageComponents/LastMovements/LastMovementsHomeSection";

const HomePage = () => {
  const cardDivResponsiveStyle = useCardDivResponsiveStyle();
  const [hiddenBalances, setHiddenBalances] = useState(false);
  const {
    cuenta,
    lineasPrestamo,
    inversionesResumen,
    lastMovements,
    isLoadingCuenta,
    isLoadingPrestamos,
    isLoadingInversiones,
    isLoadingMovimientos,
  } = useHome();

  return (
    <div className="generic-main-div">
      <Header
        hiddenBalances={hiddenBalances}
        setHiddenBalances={setHiddenBalances}
      />
      <div className="home-main-container">
        <div className="home-vertical-section">
          <HomeCardSection
            title={"Cuenta"}
            loaderText={"Cargando cuenta"}
            isLoading={isLoadingCuenta}
            items={CuentaCardList(cuenta, hiddenBalances)}
          />
          <HomeCardSection
            title={"Préstamos"}
            loaderText={"Cargando préstamos"}
            isLoading={isLoadingPrestamos}
            items={LineaPrestamoCardList(lineasPrestamo, hiddenBalances)}
          />
          <HomeCardSection
            title={"Inversiones"}
            loaderText={"Cargando inversiones"}
            isLoading={isLoadingInversiones}
            items={GetResumenInversionesCard(inversionesResumen, hiddenBalances)}
          />
        </div>
        <div className="home-vertical-section">
          <LastMovementsHomeSection
            nroCuenta={cuenta?.NroCuenta}
            isLoading={isLoadingMovimientos}
            movements={lastMovements}
          />
          <div className="home-quick-access-div" style={cardDivResponsiveStyle}>
            <SectionHeader title={"Accesos rápidos"} />
            <QuickAccessMenuComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
