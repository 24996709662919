import SearchBar from "../../../../components/SearchBar/SearchBarComponent";
import {
  DrawerDataItem,
  DrawerItemsForm,
} from "../../../../components/DrawerForm/DrawerFormAndItems";

const TransferenciaSocioSearcher = (props: {
  searchFunction: (searchedSocio: string) => void;
}) => {
  return (
    <DrawerItemsForm>
      <DrawerDataItem label={"Buscar Destinatario"}>
        <SearchBar
          className="transferencia-individual-text-field"
          placeholder={"Nro Socio..."}
          searchType="number"
          onChangedValue={props.searchFunction}
        />
      </DrawerDataItem>
    </DrawerItemsForm>
  );
};

export default TransferenciaSocioSearcher;
