import { IconButton, InputAdornment } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import Icon from "../Icon/Icon";
import { IconEnum } from "../Icon/Icon.props";
import { DatePickerProps } from "./DatePickerComponent.props";
import colors from "../../assets/_themes-vars.module.scss";
import useDesktopOrMobileDevice from "../../hooks/desktopOrMobileDevice.hook";
import TextField from "../TextField/TextField";

const DatePickerComponent = (props: DatePickerProps) => {
  const { isDesktopDevice } = useDesktopOrMobileDevice();

  const labelTextSize =
    isDesktopDevice || props.date !== null ? "1.2rem" : "auto";

  return (
    <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
      <DatePicker
        label={props.text}
        disabled={
          props.disabled != null && props.disabled != undefined
            ? props.disabled
            : false
        }
        readOnly={
          props.readonly != null && props.readonly != undefined
            ? props.readonly
            : false
        }
        OpenPickerButtonProps={{
          disableRipple: true,
          sx: { ml: "-0.9rem", mr: "0.15rem" },
        }}
        value={props.date}
        minDate={getMinDate(props.minDate)}
        maxDate={getMaxDate(props.maxDate)}
        defaultCalendarMonth={!props.date && props.minDate && props.minDate}
        onChange={(newValue) => {
          props.setDate(new Date(newValue));
        }}
        showToolbar={false}
        renderInput={(params) => (
          <TextField
            {...params}
            error={props.error}
            helperText={props.helperTextField}
            sx={{
              ...props.sx,
              svg: {
                color: props.error ? colors.errorMain : colors.primaryMain,
              },
              "& .MuiInputLabel-root, fieldset": {
                fontSize:
                  props.labelTextSize != null &&
                  props.labelTextSize != undefined
                    ? props.labelTextSize
                    : labelTextSize,
                color: props.error ? colors.errorMain : colors.primaryMain,
              },
            }}
            InputProps={{
              readOnly: true,
              startAdornment: params?.InputProps?.endAdornment,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disableRipple
                    onClick={() => {
                      props.setDate(null);
                    }}
                    sx={{ padding: "0" }}
                  >
                    <Icon icon={IconEnum.CLEAR} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;

const getMinDate = (date: Date) => {
  return new Date(
    date.getFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    0,
    0,
    0
  );
};

const getMaxDate = (date: Date) => {
  return new Date(
    date.getFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    23,
    59,
    59
  );
};
