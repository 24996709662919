import { LineaPrestamoResumen } from "../../../../pages/Prestamos/interfaces/Prestamo";
import { IconEnum } from "../../../Icon/Icon.props";
import NoElementsCard from "../NoElementsCard/NoElementsCard";
import LineaPrestamoCard from "./LineaPrestamoCard";

function LineaPrestamoCardList(
  list: LineaPrestamoResumen[],
  hiddenBalances: boolean
) {
  let cardList: JSX.Element[] = [];
  list.forEach((lp) => {
    cardList.push(
      <LineaPrestamoCard
        NombreLinea={lp.Nombre}
        CantidadPrestamosVigentes={lp.CantidadPrestamosVigentes}
        Importe={lp.ImporteAdeudado}
        ProximoVencimiento={lp.ProximoVencimiento}
        EsMoroso={lp.EsMoroso}
        HiddenBalances={hiddenBalances}
      />
    );
  });
  if (cardList.length === 0) {
    cardList.push(
      <NoElementsCard
        errorText={"No tiene ningún préstamo tomado"}
        buttonText={"Tomar préstamo"}
        icon={IconEnum.PRESTAMOS}
        redirectPath={"/prestamos"}
      />
    );
  }
  return cardList;
}

export default LineaPrestamoCardList;
