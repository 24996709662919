import { Cell } from "./Table.props";

export const DefaultBodyCell = (text: string): Cell => {
    return {
      text: text,
      sx: { color: "text.primary" },
      align: "center",
      textVariant: "body2",
      pl: "0.5rem",
      pr: "0.5rem",
    };
  };

  export const DefaultSmBodyCell = (text: string): Cell => {
    return {
      text: text?.split(" ")[0],
      sx: { color: "text.primary" },
      align: "center",
      textVariant: "body2",
      pl: "0.4rem",
      pr: "0.4rem",
    };
  };