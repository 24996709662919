const disableConsoleOnProduction = () => {
  const IS_DEBUG = process.env.NODE_ENV !== "production";

  if (!IS_DEBUG) {
    if (!window.console) window.console = {} as Console;
    const methods = ["log", "debug", "warn", "info", "error"];
    methods.forEach((element) => {
      console[element] = () => {};
    });
    window.onerror = () => true;
    window.addEventListener("unhandledrejection", function (event) {
      event.preventDefault();
    });
  }
}

export default disableConsoleOnProduction;
