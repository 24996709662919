import React, { useEffect, useState } from "react";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import AccordionSeccion from "../AccordionSeccion/AccordionSeccion";
import colors from "../../../assets/_themes-vars.module.scss";
import { styled } from "@mui/material";
import DatePickerComponent from "../../../components/DatePicker/DatePickerComponent";
import "../InversionConstitucionPage.scss";
import useBreakpoints from "../../../hooks/breakpoints.hook";
import { AccionVencimientoConstitucion } from "../../../services/InversionService/inversion.service";
import Loader from "../../../components/Loader/Loader";
import { TipoVencimiento, TipoVencimientoEnum } from "../TipoVencimiento";
import { NumericFormat } from "react-number-format";
import { PasoConstitucion1 } from "../PasoConstitucion";
import InversionService from "../../../services/InversionService/inversion.service";
import { UseUsuario } from "../../../contexts/UsuarioContext";
import moment from "moment";
import TextField from "../../../components/TextField/TextField";

const PlazoVencimientoContent = (props: {
  accionesVencimiento: AccionVencimientoConstitucion[];
  selectedAccionVencimiento: AccionVencimientoConstitucion;
  setSelectedAccionVencimiento: React.Dispatch<
    React.SetStateAction<AccionVencimientoConstitucion>
  >;
  tiposVencimiento: TipoVencimiento[];
  selectedTipoVencimiento: TipoVencimiento;
  setSelectedTipoVencimiento: React.Dispatch<
    React.SetStateAction<TipoVencimiento>
  >;
  plazoDias: number;
  setPlazoDias: React.Dispatch<React.SetStateAction<number>>;
  fechaVencimiento: Date;
  setFechaVencimiento: React.Dispatch<React.SetStateAction<Date>>;
  pasoActual: number;
  pasoValidado: boolean;
  getDiffDays: Function;
  systemDate: Date;
  plazoReal: number;
  setPlazoReal: Function;
}) => {
  const {
    accionesVencimiento,
    selectedAccionVencimiento,
    setSelectedAccionVencimiento,
    tiposVencimiento,
    selectedTipoVencimiento,
    setSelectedTipoVencimiento,
    plazoDias,
    setPlazoDias,
    fechaVencimiento,
    setFechaVencimiento,
    pasoActual,
    pasoValidado,
    getDiffDays,
    systemDate,
    plazoReal,
    setPlazoReal,
  } = props;

  const [modalPlazoOpen, setModalPlazoOpen] = useState<boolean>(false);
  const [fechaVtoReal, setFechaVtoReal] = useState<string>();
  const [maxDate, setMaxDate] = useState<number>(180);

  const bp = useBreakpoints();
  const { selectedCuentaUsuario } = UseUsuario();

  const CustomizeTextField = styled(TextField)({
    width: "100%",
    "& label": {
      color: colors.primaryMain,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.primaryMain,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colors.primaryMain,
      },
      "&:hover fieldset": {
        borderColor: colors.primaryMain,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.primaryMain,
      },
    },
  });

  const CustomizeNumericTextField = styled(TextField)({
    width: "100%",
    "& label": {
      color: colors.primaryMain,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.primaryMain,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colors.primaryMain,
      },
      "&:hover fieldset": {
        borderColor: colors.primaryMain,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.primaryMain,
      },
    },
  });

  //obtiene el plazo real para una inversion
  useEffect(() => {
    const getPlazoReal = async () => {
      const plazoReal = await InversionService.obtenerPlazoReal(
        plazoDias ?? getDiffDays(systemDate, fechaVencimiento),
        parseInt(selectedCuentaUsuario?.FilialId)
      );
      setFechaVtoReal(plazoReal[0].FechaVtoReal);
      setPlazoReal(plazoReal[0].PlazoReal);
      if (plazoReal[0].PlazoReal !== plazoDias && plazoDias !== null) {
        setModalPlazoOpen(true);
      }
      if (
        plazoReal[0].PlazoReal !== getDiffDays(systemDate, fechaVencimiento) &&
        fechaVencimiento !== null
      ) {
        setModalPlazoOpen(true);
      }
    };

    if (selectedCuentaUsuario?.FilialId && (plazoDias || fechaVencimiento))
      getPlazoReal();
    setModalPlazoOpen(false);
  }, [plazoDias, fechaVencimiento]);

  //obtiene el utimo dia habil permitido para realizar la inversion, en el caso que los 180 dias no caigan en un dia habil
  useEffect(() => {
    let plazoMaximoPermitido = 180;
    const getPlazoMaximo = async (plazo: number) => {
      const plazoMaximo = await InversionService.obtenerPlazoReal(
        plazo,
        parseInt(selectedCuentaUsuario?.FilialId)
      );
      if (plazoMaximo[0].PlazoReal > 180) {
        plazoMaximoPermitido = plazoMaximoPermitido - 1;
        getPlazoMaximo(plazoMaximoPermitido);
      }
      setMaxDate(plazoMaximo[0].PlazoReal);
    };
    if (selectedCuentaUsuario?.FilialId) getPlazoMaximo(plazoMaximoPermitido);
  }, []);

  const MobileAccordionContent = () => {
    const [plazo, setPlazo] = useState<number>(plazoDias);
    useEffect(() => {
      if (plazoReal > 180) {
        setPlazo(plazo - 1);
      }
    }, [plazo]);

    return (
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <RadioGroup
              onChange={(e) =>
                setSelectedTipoVencimiento(tiposVencimiento[e.target.value])
              }
            >
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    checked={
                      selectedTipoVencimiento && selectedTipoVencimiento.id == 1
                    }
                  />
                }
                label={tiposVencimiento[0].label}
                sx={{ color: colors.primaryMain }}
                disabled={pasoActual != PasoConstitucion1.pagina}
              />
            </RadioGroup>
            <RadioGroup
              onChange={(e) =>
                setSelectedTipoVencimiento(tiposVencimiento[e.target.value])
              }
            >
              <FormControlLabel
                value={1}
                control={
                  <Radio
                    checked={
                      selectedTipoVencimiento && selectedTipoVencimiento.id == 2
                    }
                  />
                }
                label={tiposVencimiento[1].label}
                sx={{ color: colors.primaryMain }}
                disabled={pasoActual != PasoConstitucion1.pagina}
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {selectedTipoVencimiento &&
          selectedTipoVencimiento.id !==
            TipoVencimientoEnum.fechaVencimiento ? (
            <NumericFormat
              decimalScale={0}
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue, formattedValue } = values;
                return !formattedValue || floatValue < 1000;
              }}
              customInput={CustomizeNumericTextField}
              label="Plazo en días"
              size="small"
              InputLabelProps={{ variant: "outlined" }}
              value={plazo}
              onValueChange={(e) => {
                if (
                  e.floatValue !== undefined &&
                  e.floatValue >= 29 &&
                  e.floatValue <= 180
                ) {
                  setPlazoDias(e.floatValue);
                }
                !e.floatValue || e.floatValue == undefined
                  ? setPlazo(null)
                  : setPlazo(e.floatValue);
              }}
              onBlur={(e) =>
                !plazo
                  ? setPlazoDias(null)
                  : setPlazoDias(parseInt(e.target.value))
              }
              disabled={
                pasoActual != PasoConstitucion1.pagina ||
                (selectedTipoVencimiento &&
                  selectedTipoVencimiento.id ==
                    TipoVencimientoEnum.fechaVencimiento)
              }
            />
          ) : (
            <DatePickerComponent
              text={"Fecha de vencimiento"}
              date={fechaVencimiento}
              setDate={setFechaVencimiento}
              error={
                !pasoValidado &&
                selectedTipoVencimiento &&
                selectedTipoVencimiento.id ==
                  TipoVencimientoEnum.fechaVencimiento &&
                !fechaVencimiento
              }
              helperTextField={
                !pasoValidado &&
                selectedTipoVencimiento &&
                selectedTipoVencimiento.id ==
                  TipoVencimientoEnum.fechaVencimiento &&
                !fechaVencimiento &&
                "Debe seleccionar una fecha de vencimiento"
              }
              labelTextSize="0.95rem"
              disabled={
                !(selectedTipoVencimiento && selectedTipoVencimiento.id == 2)
              }
              minDate={new Date(new Date().setDate(systemDate.getDate() + 29))}
              maxDate={
                new Date(
                  new Date().setDate(systemDate?.getDate() + (maxDate - 1))
                )
              }
              readonly={pasoActual != PasoConstitucion1.pagina}
              sx={{
                width: "100%",
                "& .MuiInputBase-root, .MuiOutlinedInput-root": {
                  height: "40px",
                },
              }}
            />
          )}
          {modalPlazoOpen && plazoReal > 180 && maxDate < 180 ? (
            <FormHelperText sx={{ color: `${colors.errorMain}` }}>
              El plazo excede el maximo permitido. selecciona un plazo de{" "}
              {maxDate} dias o menor para continuar
            </FormHelperText>
          ) : (
            modalPlazoOpen &&
            plazoReal < 180 && (
              <FormHelperText sx={{ color: `${colors.successDark}` }}>
                El plazo real sera de {plazoReal} dias. Venciendo el{" "}
                {moment(new Date(fechaVtoReal)).format("DD/MYYYY")}
              </FormHelperText>
            )
          )}
        </Grid>
        <Grid item xs={12} sx={{ marginTop: ".5rem" }}>
          <CustomizeTextField
            variant="outlined"
            select
            label="Acción al vencimiento"
            error={!pasoValidado && !selectedAccionVencimiento}
            helperText={
              !pasoValidado &&
              !selectedAccionVencimiento &&
              "Debe seleccionar una acción"
            }
            size="small"
            disabled={!selectedTipoVencimiento}
            value={accionesVencimiento.indexOf(selectedAccionVencimiento)}
            onChange={(e) =>
              setSelectedAccionVencimiento(accionesVencimiento[e.target.value])
            }
            inputProps={{ readOnly: pasoActual != PasoConstitucion1.pagina }}
          >
            {accionesVencimiento.map((accionVencimiento, index) => (
              <MenuItem key={index} value={index}>
                {accionVencimiento.Nombre}
              </MenuItem>
            ))}
          </CustomizeTextField>
        </Grid>
      </Grid>
    );
  };

  const TabletDesktopAccordionContent = () => {
    const [plazo, setPlazo] = useState<number>(plazoDias);

    return (
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={4} sx={{ marginTop: ".5rem" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <RadioGroup
              onChange={(e) =>
                setSelectedTipoVencimiento(tiposVencimiento[e.target.value])
              }
              sx={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }}
            >
              {tiposVencimiento.map((tipoVencimiento, index) => (
                <FormControlLabel
                  key={tipoVencimiento.id}
                  value={index}
                  label={tipoVencimiento.label}
                  control={
                    <Radio
                      checked={
                        selectedTipoVencimiento &&
                        selectedTipoVencimiento.id == tipoVencimiento.id
                      }
                    />
                  }
                  sx={{ marginTop: ".5rem", color: colors.primaryMain }}
                  disabled={pasoActual != PasoConstitucion1.pagina}
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} sx={{ marginTop: ".5rem" }}>
              {selectedTipoVencimiento &&
              selectedTipoVencimiento.id !==
                TipoVencimientoEnum.fechaVencimiento ? (
                <NumericFormat
                  decimalScale={0}
                  allowNegative={false}
                  isAllowed={(values) => {
                    const { floatValue, formattedValue } = values;
                    return !formattedValue || floatValue < 1000;
                  }}
                  customInput={CustomizeNumericTextField}
                  label="Plazo en días"
                  size="small"
                  InputLabelProps={{ variant: "outlined" }}
                  value={plazo}
                  onValueChange={(e) => {
                    if (
                      e.floatValue !== undefined &&
                      e.floatValue >= 29 &&
                      e.floatValue <= 180
                    ) {
                      setPlazoDias(e.floatValue);
                    }
                    !e.floatValue || e.floatValue == undefined
                      ? setPlazo(null)
                      : setPlazo(e.floatValue);
                  }}
                  onBlur={(e) =>
                    !plazo
                      ? setPlazoDias(null)
                      : setPlazoDias(parseInt(e.target.value))
                  }
                  error={
                    !pasoValidado &&
                    selectedTipoVencimiento &&
                    selectedTipoVencimiento.id == TipoVencimientoEnum.plazo &&
                    (!plazo || plazo < 29 || plazo > 180)
                  }
                  helperText={
                    !pasoValidado &&
                    selectedTipoVencimiento &&
                    selectedTipoVencimiento.id == TipoVencimientoEnum.plazo &&
                    (!plazo || plazo < 29 || plazo > 180)
                      ? !plazo
                        ? "Debe definir un plazo"
                        : plazo < 29
                        ? "El plazo no puede ser inferior a los 29 días"
                        : "El plazo no puede ser superior a los 180 días"
                      : ""
                  }
                  disabled={
                    pasoActual != PasoConstitucion1.pagina ||
                    (selectedTipoVencimiento &&
                      selectedTipoVencimiento.id ==
                        TipoVencimientoEnum.fechaVencimiento)
                  }
                />
              ) : (
                <DatePickerComponent
                  text={"Fecha de vencimiento"}
                  date={fechaVencimiento}
                  setDate={setFechaVencimiento}
                  error={
                    !pasoValidado &&
                    selectedTipoVencimiento &&
                    selectedTipoVencimiento.id ==
                      TipoVencimientoEnum.fechaVencimiento &&
                    !fechaVencimiento
                  }
                  helperTextField={
                    !pasoValidado &&
                    selectedTipoVencimiento &&
                    selectedTipoVencimiento.id ==
                      TipoVencimientoEnum.fechaVencimiento &&
                    !fechaVencimiento &&
                    "Debe seleccionar una fecha de vencimiento"
                  }
                  labelTextSize="0.95rem"
                  disabled={
                    !(
                      selectedTipoVencimiento && selectedTipoVencimiento.id == 2
                    )
                  }
                  minDate={
                    new Date(new Date().setDate(systemDate?.getDate() + 28))
                  }
                  maxDate={
                    new Date(
                      new Date().setDate(systemDate?.getDate() + (maxDate - 1))
                    )
                  }
                  readonly={pasoActual != PasoConstitucion1.pagina}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root, .MuiOutlinedInput-root": {
                      height: "40px",
                    },
                  }}
                />
              )}
              {modalPlazoOpen &&
              plazoReal > 180 &&
              maxDate < 180 &&
              plazo <= 180 ? (
                <FormHelperText sx={{ color: `${colors.errorMain}` }}>
                  El plazo excede el maximo permitido. selecciona un plazo de{" "}
                  {maxDate} dias o menor para continuar
                </FormHelperText>
              ) : (
                modalPlazoOpen &&
                plazoReal <= 180 &&
                plazoReal >= 29 && (
                  <FormHelperText sx={{ color: `${colors.successDark}` }}>
                    El plazo real sera de {plazoReal} dias. Venciendo el{" "}
                    {moment(new Date(fechaVtoReal)).format("DD/MM/YYYY")}
                  </FormHelperText>
                )
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ marginTop: ".5rem" }}>
              <CustomizeTextField
                variant="outlined"
                select
                label="Acción al vencimiento"
                error={!pasoValidado && !selectedAccionVencimiento}
                helperText={
                  !pasoValidado &&
                  !selectedAccionVencimiento &&
                  "Debe seleccionar una acción"
                }
                size="small"
                disabled={!selectedTipoVencimiento}
                value={accionesVencimiento.indexOf(selectedAccionVencimiento)}
                onChange={(e) =>
                  setSelectedAccionVencimiento(
                    accionesVencimiento[e.target.value]
                  )
                }
                inputProps={{
                  readOnly: pasoActual != PasoConstitucion1.pagina,
                }}
              >
                {accionesVencimiento.map((accionVencimiento, index) => (
                  <MenuItem key={index} value={index}>
                    {accionVencimiento.Nombre}
                  </MenuItem>
                ))}
              </CustomizeTextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const AccordionContent = () => {
    return !accionesVencimiento ? (
      <Loader label="Cargando acciones"></Loader>
    ) : bp.isSmDown ? (
      <MobileAccordionContent />
    ) : (
      <TabletDesktopAccordionContent />
    );
  };

  return (
    <Grid container marginTop={2}>
      <Grid item xs={12}>
        <AccordionSeccion
          legend="Plazo / Fecha de vencimiento"
          content={<AccordionContent />}
        />
      </Grid>
    </Grid>
  );
};

export default PlazoVencimientoContent;
