import { useCallback, useRef } from "react";
import { Timeout } from "react-number-format/types/types";

function useDebouncedCallback(
  callback: (...args: any[]) => void,
  delay: number
): (...args: any[]) => void {
  const timeoutRef = useRef<Timeout>();

  return useCallback(
    (...args) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
}

export default useDebouncedCallback;
