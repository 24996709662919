import "./CuentaEspecificaPage.scss";
import "../../layout/GenericPageLayout/Page.scss";
import { useEffect, useMemo, useState } from "react";
import MovementsTable from "./PageComponents/MovementsTable/MovementsTable";
import { PageHeader } from "../../components/Headers/PageHeader";
import { SectionHeader } from "../../components/Headers/SectionHeader";
import FiltersSection from "./PageComponents/FiltersSection/FiltersSection";
import { PaginationConfig } from "../../components/Tables/TableWrapper/PaginationConfig";
import Loader from "../../components/Loader/Loader";
import { DefaultFiltersWithNulls } from "./PageComponents/Filters/FilterUtils";
import { UseUsuario, UsuarioCuenta } from "../../contexts/UsuarioContext";
import AllPageLoader from "../../components/Loader/AllPageLoader";
import {
  Cuenta,
  ICuentaFilters,
  MovementAllData,
} from "../../services/CuentaService/cuenta.service.interfaces";
import CuentaService from "../../services/CuentaService/cuenta.service";
import { CuentaEspecificaFiltersToGetMovements } from "../../services/CuentaService/cuenta.service.adapter";
import { defaultErrorSnackbar } from "../../utils/snackBarConfigs.util";
import { useSnackbar } from "notistack";

const CuentaEspecificaPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCuentaUsuario } = UseUsuario();
  const cuenta: Cuenta = useMemo(
    () => getAccountData(selectedCuentaUsuario),
    [selectedCuentaUsuario]
  );
  const [movements, setMovements] = useState<MovementAllData[]>([]);
  const [totalMovements, setTotalMovements] = useState<number>(0);
  const [isLoadingMovements, setIsLoadingMovements] = useState(true);
  const [filters, setFilters] = useState<ICuentaFilters>(
    DefaultFiltersWithNulls
  );

  const paginationConfig = {
    page: filters.page,
    pageSize: filters.pageSize,
    pageSizeOptions: filters.pageSizeOptions,
  };

  const getMovements = () => {
    setIsLoadingMovements(true);
    CuentaService.GetMovements(
      CuentaEspecificaFiltersToGetMovements({
        filters: filters,
      })
    )
      .then((result) => {
        setMovements(result.Results);
        setTotalMovements(result.TotalElements);
      })
      .catch(() =>
        enqueueSnackbar(
          `Hubo un error al obtener los movimientos`,
          defaultErrorSnackbar
        )
      )
      .finally(() => setIsLoadingMovements(false));
  };

  useEffect(() => {
    if (cuenta) {
      getMovements();
    }
  }, [cuenta, filters]);

  const onPaginationChange = (config: PaginationConfig) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...config }));
  };

  const applyFilters = (newFilters: ICuentaFilters) => {
    setFilters((prevFilters) => ({
      ...newFilters,
      page: 0,
      pageSize: prevFilters.pageSize,
    }));
  };

  return (
    <>
      {cuenta === undefined && <AllPageLoader label={"Cargando..."} />}
      {cuenta !== undefined && (
        <div className="generic-main-div">
          <PageHeader
            title={`${cuenta.Etiqueta} (${selectedCuentaUsuario?.NroSocio})`}
          />
          <div className="cuenta-especifica-table-container">
            <div className="cuenta-especifica-default-div">
              <FiltersSection cuenta={cuenta} applyFilters={applyFilters} />
              <div className="cuenta-especifica-movimientos-header">
                <SectionHeader title={`Movimientos`} />
              </div>
            </div>
            {isLoadingMovements && (
              <Loader label={"Buscando los movimientos"} />
            )}
            {!isLoadingMovements && (
              <MovementsTable
                movements={movements}
                totalMovements={totalMovements}
                paginationConfig={paginationConfig}
                onPageChange={onPaginationChange}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CuentaEspecificaPage;

const getAccountData = (currentCuenta: UsuarioCuenta): Cuenta => {
  return currentCuenta
    ? ({
        Moneda: "ARS",
        Etiqueta: currentCuenta.Etiqueta,
        NroCuenta: currentCuenta.NroSocio,
        FilialId: currentCuenta.FilialId,
      } as Cuenta)
    : undefined;
};
