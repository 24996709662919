import { Divider, Grid, Card, CardActionArea } from "@mui/material";
import { Person, Home, AccountBox } from "@mui/icons-material";
import "./UserCardComponent.scss";
import { useState, useEffect } from "react";
import { UseUsuario } from "../../contexts/UsuarioContext";
import useBreakpoints from "../../hooks/breakpoints.hook";
import { DatosSocio } from "../../services/session.service";

const UserCardComponent = (props: {
  setOpenDrawer: Function;
  setIsChangingAccount: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { selectedCuentaUsuario, cuentasUsuario, changeSelectedAccount } =
    UseUsuario();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [_, setOpenMenu] = useState<boolean>(false);
  const bp = useBreakpoints();
  const { setOpenDrawer } = props;

  const handleChangeSelectedCuentaUsuario = (cuentaUsuario: DatosSocio) => {
    props.setIsChangingAccount(true);
    changeSelectedAccount(
      cuentaUsuario.FilialId,
      cuentaUsuario.NroSocio
    ).finally(() => {
      setAnchorMenu(null);
      setOpenDrawer(false);
      props.setIsChangingAccount(false);
    });
  };

  useEffect(() => {
    const openMenuByAnchor = !anchorMenu ? false : true;
    setOpenMenu(openMenuByAnchor);
  }, [anchorMenu]);

  const isUsuarioCuentaSelected = (usuario: DatosSocio): boolean => {
    if (!(cuentasUsuario && cuentasUsuario.length) || !selectedCuentaUsuario)
      return false;

    return (
      selectedCuentaUsuario.Filial == usuario.Filial &&
      selectedCuentaUsuario.FilialId == usuario.FilialId &&
      selectedCuentaUsuario.NombreSocio == usuario.NombreSocio &&
      selectedCuentaUsuario.NroSocio == usuario.NroSocio
    );
  };

  const HeaderCardCuentasDeUsuario = (): JSX.Element => {
    return (
      <>
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            direction={bp.isSmDown ? "row-reverse" : "row"}
          >
            <Grid
              item
              marginLeft={bp.isSmDown ? 3.5 : 2}
              xs={bp.isSmDown ? 7.5 : 9}
              display="flex"
              justifyContent={bp.isSmDown ? "flex-start" : "center"}
            >
              <span className="header-card-usuario-title">Cuentas</span>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          sx={{ color: "$grey200", width: "100%", marginBottom: "0.5rem" }}
        />
      </>
    );
  };

  const BodyCardCuentasDeUsuario = (): JSX.Element => {
    const RowDataBody = (props: { icon: any; text: string }) => (
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            {props.icon}
          </Grid>
          <Grid
            item
            xs={11}
            style={{ marginTop: "0.4rem", color: "$primaryMain" }}
          >
            <span
              className="body-card-usuario-text"
              style={{ fontSize: "10px", marginLeft: "15px" }}
            >
              {props.text}
            </span>
          </Grid>
        </Grid>
      </Grid>
    );

    return (
      <>
        {cuentasUsuario.map((cuentaUsuario, index) => (
          <Card
            onClick={() => handleChangeSelectedCuentaUsuario(cuentaUsuario)}
            key={index}
            className={
              isUsuarioCuentaSelected(cuentaUsuario)
                ? "menu-item-usuario-selected"
                : "menu-item-usuario-non-selected"
            }
          >
            <CardActionArea sx={{ width: 200 }}>
              <RowDataBody
                icon={<AccountBox />}
                text={cuentaUsuario ? `${cuentaUsuario.NroSocio}` : null}
              />
              <RowDataBody
                icon={<Person />}
                text={cuentaUsuario ? `${cuentaUsuario.NombreSocio}` : null}
              />
              <RowDataBody
                icon={<Home />}
                text={
                  cuentaUsuario ? `${cuentaUsuario.Filial.toUpperCase()}` : null
                }
              />
            </CardActionArea>
          </Card>
        ))}
      </>
    );
  };

  const CuentaUsuarioCard = (): JSX.Element => (
    <Grid container rowSpacing={0} className="usuario-content">
      {
        <div style={{ display: "block" }}>
          <HeaderCardCuentasDeUsuario />
          <BodyCardCuentasDeUsuario />
        </div>
      }
    </Grid>
  );

  return <CuentaUsuarioCard />;
};

export default UserCardComponent;
