import {
  DefaultHeaderCell,
  DefaultSmHeaderCell,
} from "../../../../components/Tables/TableWrapper/DefaultHeaderCell";
import { Cell } from "../../../../components/Tables/TableWrapper/Table.props";
import { IBreakpoints } from "../../../../hooks/breakpoints.hook";

export const getHeaderCells = (bp: IBreakpoints, ctaRemunerada: boolean): Cell[] => {
  const headerCells: Cell[] = [];

  {
    bp.isSmUp
      ? headerCells.push({ ...DefaultHeaderCell("Fecha"), width: "5%" })
      : headerCells.push({ ...DefaultSmHeaderCell("Fecha"), width: "10%" });
  }

  {
    bp.isSmUp
      ? headerCells.push({
        ...DefaultHeaderCell("Concepto"),
        align: "left",
        width: "20%",
      })
      : headerCells.push({
        ...DefaultSmHeaderCell("Concepto"),
        align: "left",
        width: "20%",
      });
  }

  if (bp.isXlUp && !ctaRemunerada) {
    headerCells.push({
      ...DefaultHeaderCell("Referencia"),
      align: "left",
      width: "45%",
    });
  } else if (bp.isXlUp && ctaRemunerada) {
    headerCells.push({
      ...DefaultHeaderCell(""),
      align: "left",
      width: "45%",
    });
  }

  {
    bp.isSmUp
      ? headerCells.push({ ...DefaultHeaderCell("Monto"), width: "15%" })
      : headerCells.push({ ...DefaultSmHeaderCell("Monto"), width: "30%" });
  }

  if (bp.is1150Up) {
    headerCells.push({ ...DefaultHeaderCell("Comprobante"), width: "6.5%" });
    headerCells.push({ ...DefaultHeaderCell(""), width: "1%" });
  }

  return headerCells;
};
