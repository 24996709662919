import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import SessionService, { DatosSocio } from "../services/session.service";
import CuentaService from "../services/CuentaService/cuenta.service";
import { CuentaToUsuarioCuenta } from "../services/CuentaService/cuenta.service.adapter";
import { useSnackbar } from "notistack";
import { defaultErrorSnackbar } from "../utils/snackBarConfigs.util";

export interface UsuarioCuenta {
  NroSocio: number;
  Filial: string;
  FilialId: number;
  NombreSocio: string;
  Cuit: string;
  TipoCuentaId: number;
  LeySellosAhorros: string;
  Orden: string;
  DescripcionTipoCuenta: string;
  Moneda: string;
  Saldo: number;
  Etiqueta: string;
  HasCuentaRemunerada: boolean;
  InteresesAcumuladosMC?: number;
  SaldoCuentaRemunerada?: number;
  FechaAceptaTyC: Date;
}

const UsuarioContext = React.createContext(null);

export const UsuarioProvider = (props) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [cuentasUsuario, setCuentasUsuario] = useState<DatosSocio[]>(undefined);
  const [fechaAceptaTyC, setFechaAceptaTyC] = useState<Date>(undefined);
  const [selectedCuentaUsuario, setSelectedCuentaUsuario] =
    useState<UsuarioCuenta>(undefined);

  useEffect(() => {
    verifySessionAndGetData();
  }, [location]);

  const verifySessionAndGetData = () => {
    if (SessionService.VerifySession()) {
      GetData();
      HandleTyCData();
    }
  };

  const HandleTyCData = () => {
    const fechaTyCByLogin =
      SessionService.GetLoginInformation()?.fechaAceptaTyC;
    if (fechaTyCByLogin) setFechaAceptaTyC(new Date(fechaTyCByLogin));
  };

  const GetData = () => {
    if (!cuentasUsuario && !selectedCuentaUsuario) {
      const cuentas = SessionService.GetDatosSocios();
      setCuentasUsuario(cuentas);
      SelectAccountIfHasUpdatedData(cuentas[0].FilialId, cuentas[0].NroSocio);
    } else {
      SelectAccountIfHasUpdatedData(
        selectedCuentaUsuario.FilialId,
        selectedCuentaUsuario.NroSocio
      );
    }
  };

  const SelectAccountIfHasUpdatedData = (
    filialId: number,
    nroSocio: number
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      setSelectedAccountSession(filialId, nroSocio);
      CuentaService.GetAccountData()
        .then((result) => {
          const updatedAccountData = CuentaToUsuarioCuenta(result);
          const hasUpdatedData =
            JSON.stringify(updatedAccountData) !==
            JSON.stringify(selectedCuentaUsuario);
          if (hasUpdatedData) {
            setSelectedCuentaUsuario(updatedAccountData);
          }
          resolve();
        })
        .catch(() => {
          setSelectedAccountSession(
            selectedCuentaUsuario.FilialId,
            selectedCuentaUsuario.NroSocio
          );
          enqueueSnackbar(
            `Hubo un problema al intentar obtener los datos del socio ${nroSocio}`,
            defaultErrorSnackbar
          );
          reject();
        });
    });
  };

  const value = useMemo(() => {
    return {
      fechaAceptaTyC,
      cuentasUsuario,
      selectedCuentaUsuario,
      changeSelectedAccount: SelectAccountIfHasUpdatedData,
      refreshAccountsData: verifySessionAndGetData,
    };
  }, [cuentasUsuario, selectedCuentaUsuario, fechaAceptaTyC]);

  return <UsuarioContext.Provider value={value} {...props} />;
};

export const UseUsuario = () => {
  const context = React.useContext(UsuarioContext);

  if (!context) console.log("UseUsuario debe estar dentro del UsuarioContext");

  return context;
};

const setSelectedAccountSession = (filialId: number, nSocio: number) => {
  SessionService.StoreCurrentUserSession(filialId, nSocio);
};
