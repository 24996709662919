import { RouteObject, Navigate, Outlet } from "react-router-dom";
import Layout from "../layout/Layout";
import HomePage from "../pages/Home/HomePage";
import TarjetasPage from "../pages/Tarjetas/TarjetasPage";
import InversionesPage from "../pages/Inversiones/InversionesPage";
import InversionConstitucionPage from "../pages/InversionConstitucion/InversionConstitucionPage";
import PrestamosPage from "../pages/Prestamos/PrestamosPage";
import TransferenciasPage from "../pages/Transferencias/TransferenciasPage";
import LoginPage from "../pages/Login/LoginPage";
import CuentaEspecificaPage from "../pages/CuentaEspecifica/CuentaEspecificaPage";
import Error404Page from "../pages/Error404/Error404";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import CuentaRemuneradaPage from "../pages/CuentaRemunerada/CuentaRemuneradaPage";
import ResetPasswordPage from "../pages/Login/ReestablecerClave/ResetPasswordPage";
import FirstLoginPage from "../pages/Login/FirstLogin/FirstLoginPage";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Layout></Layout>,
    children: [
      {
        path: "/",
        index: true,
        element: <Navigate to="home"></Navigate>,
      },
      {
        path: "home",
        element: <HomePage></HomePage>,
      },
      {
        path: "cuenta",
        element: <Outlet />,
        children: [
          {
            path: "movimientos",
            element: <CuentaEspecificaPage></CuentaEspecificaPage>,
          },
          {
            path: "ahorro-mutual-plus",
            element: <CuentaRemuneradaPage></CuentaRemuneradaPage>,
          },
        ],
      },
      {
        path: "tarjetas",
        element: <TarjetasPage></TarjetasPage>,
      },
      {
        path: "inversiones",
        element: <InversionesPage></InversionesPage>,
      },
      {
        path: "inversion-constitucion",
        element: <InversionConstitucionPage></InversionConstitucionPage>,
      },
      {
        path: "prestamos",
        element: <PrestamosPage></PrestamosPage>,
      },
      {
        path: "transferencias",
        element: <TransferenciasPage></TransferenciasPage>,
      },
      {
        path: "cambiar-contrasenia",
        element: <ChangePassword></ChangePassword>,
      },
    ],
  },
  {
    path: "/login",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <LoginPage></LoginPage>,
      },
      {
        path: "primer-login",
        element: <FirstLoginPage></FirstLoginPage>,
      },
      {
        path: "reset-password",
        element: <ResetPasswordPage></ResetPasswordPage>,
      },
      {
        path: "cambiar-contrasenia",
        element: <ChangePassword></ChangePassword>,
      },
    ],
  },
  {
    path: "/error404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="error404"></Navigate>,
  },
];

export default routes;
