import { Button } from "@mui/material";
import Icon from "../Icon/Icon";
import { IconEnum } from "../Icon/Icon.props";
import "./DownloadButton.scss";
import { useSnackbar } from "notistack";
import { GetComprobanteProps } from "../../services/ComprobantesService/comprobantes.service.interfaces";
import { DownloadComprobante } from "./Download.function";

interface DownloadButtonProps {
  text?: string;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  buttonClass?: string;
  buttonStyle?: React.CSSProperties;
  downloadProps: GetComprobanteProps;
}

const DownloadButton = ({
  text = "Descargar",
  containerClass,
  containerStyle,
  buttonClass,
  buttonStyle,
  downloadProps,
}: DownloadButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div style={containerStyle} className={`download-button ${containerClass}`}>
      <Button
        className={`${buttonClass}`}
        style={buttonStyle}
        variant="contained"
        onClick={() => DownloadComprobante(downloadProps, enqueueSnackbar)}
        endIcon={<Icon icon={IconEnum.DOWNLOAD} />}
      >
        {text}
      </Button>
    </div>
  );
};

export default DownloadButton;
