import { Cell } from "../../../../../components/Tables/TableWrapper/Table.props";
import { DefaultSmHeaderCell } from "../../../../../components/Tables/TableWrapper/DefaultHeaderCell";
import { DefaultSmBodyCell } from "../../../../../components/Tables/TableWrapper/DefaultBodyCell";
import {
  DateToStringConverter,
  NumberToMoneyConverter,
} from "../../../../../components/Formatters/Formatters";
import {
  DrawerItemsForm,
  DrawerTextDataItem,
} from "../../../../../components/DrawerForm/DrawerFormAndItems";
import DetallePrestamoTable from "../DetallePrestamoTable";
import { DetalleCuota } from "../../../interfaces/Prestamo";
import useCustomPagination from "../../../../../hooks/useCustomPagination.hook";
import colors from "../../../../../assets/_themes-vars.module.scss";

interface TablaDetalleCuotasProps {
  currentCuota: number;
  cuotas: DetalleCuota[];
}

const TablaDetalleCuotas = ({
  cuotas,
  currentCuota,
}: TablaDetalleCuotasProps) => {
  const pagination = useCustomPagination<DetalleCuota>({
    defaultPagination: { page: 0, pageSize: 5, totalElements: cuotas.length },
    criteriaToShowSpecificElement: (cuota: DetalleCuota) =>
      cuota.NroCuota === currentCuota,
    elements: cuotas,
  });

  const cuotasToShow = pagination.paginatedElements;

  const cuotaHiddenDetails = cuotasToShow.map((c) => (
    <CuotaHiddenDetail
      key={`CTA-${c.NroCuota}-${c.Total}`}
      cuota={c}
      currentCuotaToPay={currentCuota}
    />
  ));

  return (
    <DetallePrestamoTable
      header={headerCells}
      body={getBodyCells(cuotasToShow, currentCuota)}
      paginationConfig={{
        page: pagination.page,
        pageSize: pagination.pageSize,
        totalElements: pagination.totalElements,
      }}
      onPagination={pagination.onPagination}
      hiddenContent={cuotaHiddenDetails}
    />
  );
};

export default TablaDetalleCuotas;

const headerCells: Cell[] = [
  {
    ...DefaultSmHeaderCell("Cuota"),
    align: "center",
    textVariant: "h4",
  },
  {
    ...DefaultSmHeaderCell("Total"),
    align: "center",
    textVariant: "h4",
  },
  {
    ...DefaultSmHeaderCell("Vencimiento"),
    textVariant: "h4",
  },
];

const getBodyCells = (
  cuotas: DetalleCuota[],
  currentCuota: number
): Cell[][] => {
  const cuotaStyle = (nroCuota: number, esMoroso: boolean) => {
    let cuotaColor = undefined;
    if (nroCuota === currentCuota && esMoroso) cuotaColor = colors.errorMain;
    if (nroCuota < currentCuota) cuotaColor = colors.successDark;
    return {
      fontWeight: nroCuota <= currentCuota && "bold",
      color: cuotaColor,
    };
  };

  return cuotas.map((c) => [
    {
      ...DefaultSmBodyCell(c.NroCuota.toString()),
      sx: cuotaStyle(c.NroCuota, c.EsMoroso),
    },
    {
      ...DefaultSmBodyCell(NumberToMoneyConverter(c.Total)),
      sx: cuotaStyle(c.NroCuota, c.EsMoroso),
    },
    {
      ...DefaultSmBodyCell(DateToStringConverter(c.FechaVencimiento)),
      sx: cuotaStyle(c.NroCuota, c.EsMoroso),
    },
  ]);
};

interface CuotaHiddenDetailProps {
  cuota: DetalleCuota;
  currentCuotaToPay: number;
}

const CuotaHiddenDetail = ({
  cuota,
  currentCuotaToPay,
}: CuotaHiddenDetailProps) => {
  const isAfterCurrentCuota = cuota.NroCuota > currentCuotaToPay;
  return (
    <div className="prestamo-table-hidden-section">
      <DrawerItemsForm>
        {cuota.FechaPago && (
          <DrawerTextDataItem
            label={"Fecha Pago"}
            value={DateToStringConverter(cuota.FechaPago)}
          />
        )}
        <DrawerTextDataItem
          label={"Capital"}
          value={NumberToMoneyConverter(cuota.Capital)}
        />
        <DrawerTextDataItem
          label={"Intereses"}
          value={NumberToMoneyConverter(cuota.Intereses)}
        />
        {!isAfterCurrentCuota && (
          <>
            <DrawerTextDataItem
              label={"Compensatorios"}
              value={NumberToMoneyConverter(cuota.Compensatorios)}
            />
            <DrawerTextDataItem
              label={"Punitorios"}
              value={NumberToMoneyConverter(cuota.Punitorios)}
            />
          </>
        )}
      </DrawerItemsForm>
    </div>
  );
};
