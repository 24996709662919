import axios from "axios";
import SessionService from "../services/session.service";

interface Result<T> {
  Result: T;
}

class ApiError extends Error {
  message: string;

  constructor(message: string) {
    super(message);
    this.message = message;
  }
}

const HomeApi = axios.create({
  baseURL: process.env.REACT_APP_HOME_API,
});

// request interceptors
HomeApi.interceptors.request.use(
  addAuthHeaderInterceptor,
  simpleRequestErrorHandler
);
HomeApi.interceptors.request.use(
  addSessionHeaderInterceptor,
  simpleRequestErrorHandler
);

function addAuthHeaderInterceptor(config) {
  // Do something before request is sent
  let token = SessionService.GetToken();
  if (token) config.headers.Authorization = "Bearer " + token;
  return config;
}

function simpleRequestErrorHandler(error) {
  // Do something with request error
  console.log("error");
  return Promise.reject(error);
}
function addSessionHeaderInterceptor(config) {
  const { currentFilial, currentSocio } =
    SessionService.RetrieveCurrentUserSession();
  if (currentFilial) config.headers["X-Filial"] = currentFilial;
  if (currentSocio) config.headers["X-Socio"] = currentSocio;
  return config;
}

export const defaultMsgError = "Hubo un problema inesperado";

// response interceptor

HomeApi.interceptors.response.use(
  (response) => {
    return response?.data
      ? {
          headers: response?.headers,
          data: response.data.Result ? response.data.Result : response.data,
        }
      : { data: response, headers: response?.headers };
  },
  (error) => {
    if (
      (error.response.status == 401 || error.response.status == 403) &&
      window.location.pathname != "/login"
    ) {
      localStorage.removeItem("token");
      window.location.pathname = "/login";
    } else {
      throw new ApiError(error?.response?.data?.Message ?? defaultMsgError);
    }
  }
);
export default HomeApi;
