import { styled, useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import LeftMenuComponent from "../components/LeftMenuComponent/LeftMenuComponent";
import NavBarComponent from "../components/NavBarComponent/NavBarComponent";
import LayoutContextProvider, {
  LayoutContext,
} from "../contexts/LayoutContext";
import { drawerWidth } from "./LayoutConstants";

//------------------------- Layout content
const LayoutContent = styled("main")(({ theme }) => {
  const [layoutContext] = useContext(LayoutContext);
  return {
    ...(!layoutContext.menuOpened && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      paddingLeft: drawerWidth,
      paddingTop: "80px",
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: -(drawerWidth - 20),
        paddingRight: "20px",
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        paddingLeft: "0px",
        marginRight: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "5px",
        marginRight: "5px",
      },
    }),
    ...(layoutContext.menuOpened && {
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      paddingTop: "80px",
      [theme.breakpoints.up("md")]: {
        paddingLeft: drawerWidth + 20,
        paddingRight: 20,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        paddingLeft: "0px",
        marginRight: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "5px",
        marginRight: "5px",
      },
    }),
  };
});

//------------------------- Layout component
const Layout = () => {
  const theme = useTheme();
  return (
    <LayoutContextProvider>
      <NavBarComponent></NavBarComponent>
      <LeftMenuComponent></LeftMenuComponent>
      <LayoutContent
        theme={theme}
      >
        <Outlet />
      </LayoutContent>
    </LayoutContextProvider>
  );
};
export default Layout;
