import { VariantType, SnackbarProvider, useSnackbar } from 'notistack';
const success: VariantType = 'success';
const warning: VariantType = 'warning';
const error: VariantType = 'error';
const info: VariantType = 'info';
let enqueueSnackbar;

const NotificationProvider = ({children}) => {
    enqueueSnackbar = useSnackbar();
    return (
        <SnackbarProvider>
            {children}
        </SnackbarProvider>
    );
};

export default NotificationProvider;

export const NotificationService = {

    ShowSuccess: (message: string) => () => {
        enqueueSnackbar(message, {variant: success});
    },
    ShowWarning: (message: string) => () => {
        enqueueSnackbar(message, {variant: warning});
    },
    Showerror: (message: string) => () => {
        enqueueSnackbar(message, {variant: error});
    },
    ShowInformation: (message: string) => () => {
        enqueueSnackbar(message, {variant: info});
    }
}