import HomeApi from "../../config/axios";

export interface TipoImputacion {
  Id: number;
  Nombre: string;
}

export interface TipoOrigen {
  Id: string;
  Nombre: string;
}

const FiltrosService = {
  GetTiposImputacion: (): Promise<TipoImputacion[]> => {
    return new Promise((resolve, reject) => {
      HomeApi.get<any>(`cuenta/movimientos/Imputaciones`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.message));
    });
  },
  GetTiposOrigen: (): Promise<TipoOrigen[]> => {
    return new Promise((resolve, reject) => {
      HomeApi.get<any>(`cuenta/movimientos/Origenes`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.message));
    });
  },
};
export default FiltrosService;
