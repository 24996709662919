import { Typography } from "@mui/material";
import { SectionHeader } from "../../../components/Headers/SectionHeader";
import { PrestamoResumen } from "../interfaces/Prestamo";
import useBreakpoints from "../../../hooks/breakpoints.hook";
import { useMemo } from "react";
import { Carousel } from "../../../components/Carousel/Carousel";
import { useCardResponsiveStyle } from "../../../hooks/CardResponsiveStyle.hook";
import PrestamoPageCard from "../../../components/Cards/PrestamoPageCards/PrestamoPageCard";
import { TipoPrestamoEnum } from "../enums/TipoPrestamo.enum";
import { MetodoPagoData } from "../../../components/Cards/PrestamoPageCards/PrestamoPageCard.props";

interface LineaPrestamoProps {
  lineaName: string;
  prestamos: PrestamoResumen[];
  showEmptyMessage?: boolean;
}

const LineaPrestamoComponent = ({
  lineaName,
  prestamos,
  showEmptyMessage,
}: LineaPrestamoProps) => {
  const hasPrestamos = prestamos.length > 0;
  const errorMustBeDisplayed = showEmptyMessage && !hasPrestamos;

  const { isXlDown, is1150Down } = useBreakpoints();

  const splittedPrestamos = useMemo(
    () => splitPrestamos(isXlDown, is1150Down, prestamos),
    [isXlDown, is1150Down]
  );

  return (
    <>
      {errorMustBeDisplayed && <LineaPrestamoError lineaName={lineaName} />}
      {hasPrestamos && (
        <div className="linea-prestamos-container">
          <SectionHeader title={lineaName} />
          <Carousel
            className="generic-page-section"
            items={splittedPrestamos.map((prestL) => (
              <PrestamoCarouselSection
                key={`LP-${prestL[0].NComprobante}`}
                prestamoList={prestL}
              />
            ))}
          />
        </div>
      )}
    </>
  );
};

export default LineaPrestamoComponent;

const LineaPrestamoError = ({ lineaName }: { lineaName: string }) => {
  return (
    <Typography variant="h4" className="prestamos-error-legend">
      No posee préstamos vigentes de la línea:{" "}
      <Typography component="span" variant="h4" style={{ fontWeight: "bold" }}>
        {lineaName}
      </Typography>
    </Typography>
  );
};

function splitPrestamos(
  isXlDown: boolean,
  is1150Down: boolean,
  prestamos: PrestamoResumen[]
): PrestamoResumen[][] {
  let totalCardsForSplit = 3;
  if (isXlDown) totalCardsForSplit = 2;
  if (is1150Down) totalCardsForSplit = 1;

  const splittedPrestamos = [];
  for (let i = 0; i < prestamos.length; i += totalCardsForSplit) {
    const sublist = prestamos.slice(i, i + totalCardsForSplit);
    splittedPrestamos.push(sublist);
  }

  return splittedPrestamos;
}

const PrestamoCarouselSection = ({
  prestamoList,
}: {
  prestamoList: PrestamoResumen[];
}) => {
  const responsiveStyle = useCardResponsiveStyle();

  return (
    <div
      className="prestamos-cards-container"
      style={{ justifyContent: prestamoList.length > 2 && "center" }}
    >
      {prestamoList.map((p) => {
        const tipoPrestamo = GetTipoPrestamo(p);
        return (
          <div
            key={`PC-${p.NComprobante}`}
            className="generic-card-div"
            style={responsiveStyle}
          >
            <PrestamoPageCard
              nSocio={p.NSocio}
              filialId={p.FilialId}
              nComprobante={p.NComprobante}
              lineaId={p.CodSis}
              tipoPrestamo={tipoPrestamo}
              fechaImposicion={p.FechaImposicion}
              monto={p.ImporteAdeudado}
              metodoPagoData={GetMetodoPagoData(tipoPrestamo, p)}
            />
          </div>
        );
      })}
    </div>
  );
};

function GetTipoPrestamo(prestamo: PrestamoResumen): TipoPrestamoEnum {
  if (prestamo.EsTipoVencido) return TipoPrestamoEnum.VENCIDO;
  if (prestamo.EsTipoAnticipado) return TipoPrestamoEnum.ANTICIPADO;
  return TipoPrestamoEnum.CON_CUOTAS;
}

function GetMetodoPagoData(
  tipoPrestamo: TipoPrestamoEnum,
  prestamo: PrestamoResumen
): MetodoPagoData {
  switch (tipoPrestamo) {
    case TipoPrestamoEnum.CON_CUOTAS:
      return {
        actual: prestamo.DetalleCuota.NroCuota,
        total: prestamo.DetalleCuota.CantidadCuotas,
        vencimiento: prestamo.DetalleCuota.FechaVencimiento,
        importe: prestamo.DetalleCuota.Importe,
        esMoroso: prestamo.DetalleCuota.EsMoroso,
      };
    case TipoPrestamoEnum.ANTICIPADO:
      return {
        actual: prestamo.DetalleCheque.NroCheque,
        total: prestamo.DetalleCheque.CantidadCheques,
        vencimiento: prestamo.DetalleCheque.FechaVencimiento,
        importe: prestamo.DetalleCheque.Importe,
      };
    case TipoPrestamoEnum.VENCIDO:
      return {
        actual: prestamo.DetalleVencido.DiasTranscurridos,
        total: prestamo.DetalleVencido.TotalDias,
        vencimiento: prestamo.DetalleVencido.FechaVencimiento,
        esMoroso: prestamo.DetalleVencido.EsMoroso,
      };
  }
}
