import { FormHelperText, Typography } from "@mui/material";
import PasswordField, {
  PasswordFieldProps,
} from "../../components/PasswordField/PasswordField";
import { LoadingButton } from "@mui/lab";
import "./ChangePassword.scss";
import useChangePasswordState from "./useChangePasswordState";

const ChangePassword = () => {
  const state = useChangePasswordState();

  return (
    <main className="generic-main-div change-password-main">
      <section className="change-password-section" style={{ gap: "30px" }}>
        <Typography variant="h1" color="primary">
          Cambiar contraseña
        </Typography>
        <section className="change-password-section" style={{ gap: "5px" }}>
          <Typography variant="h3">
            DNI:{" "}
            <Typography
              variant="h3"
              component="span"
              style={{ fontWeight: "lighter" }}
            >
              {state.dni}
            </Typography>
          </Typography>
          <Typography variant="h3">
            Usuario:{" "}
            <Typography
              variant="h3"
              component="span"
              style={{ fontWeight: "lighter" }}
            >
              {state.usuario}
            </Typography>
          </Typography>
        </section>
        <section
          className="change-password-section"
          style={{ width: "315px", gap: "15px" }}
        >
          <PasswordSection
            label={"Contraseña Actual"}
            onChange={state.onChangePasswords.onChangeCurrentPassword}
            error={state.errors.currentPasswordError}
          />
          <PasswordSection
            label={"Nueva Contraseña"}
            onChange={state.onChangePasswords.onChangeNewPassword}
            error={state.errors.newPasswordError}
          />
          <PasswordSection
            label={"Confimar Contraseña"}
            onChange={state.onChangePasswords.onChangeConfirmNewPassword}
            error={state.errors.confirmNewPasswordError}
          />
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            onClick={state.onConfirmChangePassword}
            loadingPosition="center"
            loading={state.isChangingPassword}
            sx={{
              height: "50px",
              ".MuiLoadingButton-loadingIndicator": {
                color: "white",
              },
            }}
          >
            Cambiar Contraseña
          </LoadingButton>
        </section>
      </section>
    </main>
  );
};

interface PasswordSectionProps extends PasswordFieldProps {
  error: string;
}

const PasswordSection = (props: PasswordSectionProps) => {
  const hasError = props.error !== "";
  return (
    <section style={{ width: "100%" }}>
      <PasswordField label={props.label} onChange={props.onChange} />
      {hasError && (
        <FormHelperText className="password-error" error>
          {props.error}
        </FormHelperText>
      )}
    </section>
  );
};

export default ChangePassword;
