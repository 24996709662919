import { InputAdornment, SxProps } from "@mui/material";
import React, { useState } from "react";
import Icon from "../Icon/Icon";
import { IconEnum } from "../Icon/Icon.props";
import colors from "../../assets/_themes-vars.module.scss";
import TextField from "../TextField/TextField";

interface SearchBarProps {
  placeholder: string;
  className?: string;
  sx?: SxProps;
  searchType?: React.HTMLInputTypeAttribute;
  onChangedValue: (query: string) => void;
  onPressedEnter?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  className,
  sx,
  onChangedValue,
  searchType,
  onPressedEnter,
}) => {
  const [query, setQuery] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangedValue(event.target.value);
    setQuery(event.target.value);
  };

  return (
    <TextField
      placeholder={placeholder}
      value={query}
      onChange={handleChange}
      variant="standard"
      sx={sx}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onPressedEnter) onPressedEnter();
      }}
      className={className}
      type={searchType}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon sx={{ color: colors.primaryMain }} icon={IconEnum.SEARCH} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
