import HomeApi from "../../config/axios";
import SessionService from "../session.service";
import {
  ChangePasswordPayload,
  LoginPayload,
} from "./IAuthentication.interface";

const AuthenticationService = {
  Login: (loginPayload: LoginPayload): Promise<any> => {
    return new Promise((resolve, reject) => {
      HomeApi.post<{ Token: string }>("Account/Login", loginPayload)
        .then((response) => {
          HandleLogin(response, loginPayload);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  ChangePassword: (
    ChangePasswordPayload: ChangePasswordPayload
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      HomeApi.patch<void>("Account/update-password", ChangePasswordPayload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  RecoverMyUsername: (documento: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      HomeApi.get<void>("Account/recover-my-username", {
        params: { Documento: documento },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  ForgotMyPassword: (documento: string, usuario: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      HomeApi.post<void>("Account/forgot-my-password", {
        Documento: documento,
        Usuario: usuario,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  ResetMyPassword: (
    documento: string,
    usuario: string,
    codigo: string
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      HomeApi.patch<void>("Account/reset-my-password", {
        Documento: documento,
        Usuario: usuario,
        Codigo: codigo,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  Logout: (): Promise<void> => {
    return new Promise((resolve) => {
      SessionService.CleanSession();
      if (!window.location.pathname.includes("/login")) {
        AuthenticationService.NavigateToDefaultLogin();
      }
      resolve();
    });
  },
  NavigateToDefaultLogin: () => {
    window.location.href = "/login";
  }
};

export default AuthenticationService;

function HandleLogin(response, loginPayload: LoginPayload) {
  SessionService.SetLoginInformation({
    dni: loginPayload.NroDocumento,
    usuarioINS: loginPayload.Usuario,
    rememberUser: loginPayload.RememberUser,
    fechaAceptaTyC: response.data.FechaAceptaTyC,
  });
  if (response.data.IsFirstLogin) {
    SessionService.SetIsFirstLogin(true);
  } else if (response.data.IsToChangePassword){
    SessionService.SetIsToChangePassword(true);
  } else {
    SessionService.SetToken(response.data.Token);
  }
}
