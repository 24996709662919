import { PageHeader } from "../../components/Headers/PageHeader";
import PrimerPasoConstitucion from "./PrimerPaso/PrimerPasoConstitucionPage";
import SegundoPasoConstitucion from "./SegundoPaso/SegundoPasoConstitucionPage";
import { Grid, Alert, Snackbar } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InversionService, {
  AccionVencimientoConstitucion,
  OrdenCertificado,
  DetalleInversionConstitucion,
} from "../../services/InversionService/inversion.service";
import PasosConstitucion, {
  PasoConstitucion,
  PasoConstitucion1,
  PasoConstitucion2,
} from "./PasoConstitucion";
import { Contacto } from "../../services/ContactoService/contacto.service";
import TipoVencimientos, {
  TipoVencimiento,
  TipoVencimientoEnum,
} from "./TipoVencimiento";
import { useEffect, useState } from "react";
import "./InversionConstitucionPage.scss";
import { useSnackbar } from "notistack";
import { UseUsuario } from "../../contexts/UsuarioContext";
import useBreakpoints from "../../hooks/breakpoints.hook";
import { useNavigate } from "react-router-dom";
import colors from "../../assets/_themes-vars.module.scss";
import { useSystemDate } from "../../contexts/SystemDateContext";
import { homeProbablyDisabled } from "../../utils/genericErrorMessages";
import { homeDisabledSnackbar } from "../../utils/snackBarConfigs.util";

const InversionConstitucionPage = () => {
  const navigate = useNavigate();

  const [pasoValidado, setPasoValidado] = useState<boolean>(true);

  const [ordenesCertificado, setOrdenesCertificado] =
    useState<OrdenCertificado[]>(null);
  const [selectedOrdenCertificado, setSelectedOrdenCertificado] =
    useState<OrdenCertificado>(null);
  const [monto, setMonto] = useState<number>(null);
  const [saldoCuenta, setSaldoCuenta] = useState<number>(-1);
  const [accionesVencimiento, setAccionesVencimiento] =
    useState<AccionVencimientoConstitucion[]>(null);
  const [selectedAccionVencimiento, setSelectedAccionVencimiento] =
    useState<AccionVencimientoConstitucion>(null);
  const [titulares, setTitulares] = useState<Contacto[]>([]);
  const [selectedTitulares, setSelectedTitulares] = useState<Contacto[]>([]);
  const [pasoActual, setPasoActual] =
    useState<PasoConstitucion>(PasoConstitucion1);
  const [tiposVencimiento, setTiposVencimiento] = useState<TipoVencimiento[]>(
    []
  );
  const [selectedTipoVencimiento, setSelectedTipoVencimiento] =
    useState<TipoVencimiento>({
      id: 1,
      label: "Plazo",
    });
  const [plazoDias, setPlazoDias] = useState<number>(null);
  const [fechaVencimiento, setFechaVencimiento] = useState<Date>(null);
  const [aceptaTerminos, setAceptaTerminos] = useState<boolean>(false);
  const [detalleSimulacion, setDetalleSimulacion] =
    useState<DetalleInversionConstitucion>(null);
  const [loadingSimulacion, setLoadingSimulacion] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const systemDate = useSystemDate();
  const [showInactivityError, setShowInactivityError] =
    useState<boolean>(false);
  const [returnToFirstStep, setReturnToFirstStep] = useState<boolean>(false);
  const [plazoReal, setPlazoReal] = useState<number>();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCuentaUsuario } = UseUsuario();
  const bp = useBreakpoints();

  useEffect(() => {
    if (selectedCuentaUsuario && pasoActual.pagina == PasoConstitucion1.pagina)
      getRecursos();
  }, [selectedCuentaUsuario, pasoActual]);

  useEffect(() => {
    if (selectedCuentaUsuario && pasoActual.pagina == PasoConstitucion2.pagina)
      setPasoActual(PasoConstitucion1);
  }, [selectedCuentaUsuario]);

  useEffect(() => {
    if (
      selectedCuentaUsuario?.Saldo !== undefined &&
      selectedCuentaUsuario?.Saldo !== null
    ) {
      setSaldoCuenta(selectedCuentaUsuario.Saldo);
    }
  }, [selectedCuentaUsuario?.Saldo]);

  useEffect(() => {
    if (selectedCuentaUsuario && pasoActual.pagina == PasoConstitucion2.pagina)
      simularInversion();
  }, [pasoActual]);

  useEffect(() => {
    setPlazoDias(null);
    setFechaVencimiento(null);
  }, [selectedTipoVencimiento]);

  const getRecursos = () => {
    if (pasoActual.pagina == PasoConstitucion1.pagina && !ordenesCertificado) {
      getOrdenesCertificado();
    }

    if (pasoActual.pagina == PasoConstitucion1.pagina && !accionesVencimiento) {
      getAccionesVencimiento();
    }
  };

  const getOrdenesCertificado = () => {
    InversionService.GetAllOrdenesCertificado()
      .then((response) => setOrdenesCertificado(response))
      .catch((error) => {
        setOrdenesCertificado([]);
        enqueueSnackbar(`Hubo un error al obtener las ordenes de certificado`, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      });
  };

  const getAccionesVencimiento = () => {
    InversionService.GetAllAccionesVencimientoConstitucion()
      .then((response) => {
        const tiposVencimiento: TipoVencimiento[] = TipoVencimientos.GetAll();
        setTiposVencimiento(tiposVencimiento);
        setAccionesVencimiento(response);
      })
      .catch((error) => {
        setAccionesVencimiento([]);
        enqueueSnackbar(
          `Hubo un error al obtener las acciones al vencimiento`,
          {
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          }
        );
      });
  };

  const simularInversion = async () => {
    setDetalleSimulacion(null);
    setLoadingSimulacion(true);

    const plazo = plazoDias
      ? plazoDias
      : getDiffDays(systemDate, fechaVencimiento);
    const cotitularesIds = selectedTitulares.map((x) => x.NSocio).toString();

    InversionService.SimularInversion(
      `${selectedCuentaUsuario.LeySellosAhorros}`,
      monto,
      plazo,
      cotitularesIds,
      1
    )
      .then((response) => {
        setDetalleSimulacion(response);
      })
      .catch(() => {
        enqueueSnackbar(
          `Hubo un error al obtener los detalles de la constitución`,
          {
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          }
        );
      })
      .finally(() => setLoadingSimulacion(false));
  };

  const getDiffDays = (fechaInicio: Date, fechaFin: Date) => {
    if (fechaInicio !== null && fechaFin !== null) {
      fechaInicio.setHours(0, 0, 0);
      fechaFin.setHours(0, 0, 0);
      const diff = fechaFin.getTime() - fechaInicio.getTime();
      return Math.round(diff / (1000 * 60 * 60 * 24) + 1);
    }
  };

  const validarPaso = (): boolean => {
    let pasoValidado = true;

    //Validaciones del paso 1

    if (pasoActual.pagina == PasoConstitucion1.pagina && !monto) {
      pasoValidado = false;
    }

    if (pasoActual.pagina == PasoConstitucion1.pagina && monto) {
      pasoValidado = monto <= saldoCuenta;
    }

    if (
      pasoActual.pagina == PasoConstitucion1.pagina &&
      !selectedOrdenCertificado
    ) {
      pasoValidado = false;
    }

    if (
      pasoActual.pagina == PasoConstitucion1.pagina &&
      selectedOrdenCertificado &&
      selectedOrdenCertificado.Id !== 3 &&
      selectedTitulares.length === 0
    ) {
      pasoValidado = false;
    }

    if (
      pasoActual.pagina == PasoConstitucion1.pagina &&
      !selectedAccionVencimiento
    ) {
      pasoValidado = false;
    }

    if (
      pasoActual.pagina == PasoConstitucion1.pagina &&
      selectedTipoVencimiento &&
      selectedTipoVencimiento.id == TipoVencimientoEnum.plazo &&
      (!plazoDias || plazoDias < 29 || plazoDias > 180)
    ) {
      pasoValidado = false;
    }

    if (
      pasoActual.pagina == PasoConstitucion1.pagina &&
      selectedTipoVencimiento &&
      selectedTipoVencimiento.id == TipoVencimientoEnum.fechaVencimiento &&
      !fechaVencimiento
    ) {
      pasoValidado = false;
    }

    if (plazoReal > 180) {
      pasoValidado = false;
    }

    setPasoValidado(pasoValidado);
    return pasoValidado;
  };

  const actualizarPaso = async (paso: number) => {
    if (paso === 1) {
      setIsButtonDisabled(false);
      setAlertOpen(false);
    }
    if (validarPaso() || paso < pasoActual.pagina) {
      const pasoActualizado = PasosConstitucion.GetAll().find(
        (x) => x.pagina == paso
      );
      if (pasoActualizado && pasoActualizado.pagina)
        setPasoActual(pasoActualizado);
    }
  };

  const constituirInversion = async () => {
    setIsLoading(true);
    if (!aceptaTerminos) {
      enqueueSnackbar(
        "Debe aceptar los términos y condiciones para concretar la inversión",
        {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        }
      );
    } else {
      const plazo = plazoDias
        ? plazoDias
        : getDiffDays(systemDate, fechaVencimiento);
      const cotitularesIds = selectedTitulares.map((x) => x.NSocio).toString();
      setLoadingSimulacion(true);

      InversionService.SimularInversion(
        `${selectedCuentaUsuario.LeySellosAhorros}`,
        monto,
        plazo,
        cotitularesIds,
        0,
        selectedOrdenCertificado.Id,
        selectedAccionVencimiento.Id
      )
        .then(() => {
          enqueueSnackbar(`Inversion constituida satisfactoriamente.`, {
            variant: "success",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          });
          navigate(`/inversiones`);
        })
        .catch(() => {
          enqueueSnackbar(homeProbablyDisabled, homeDisabledSnackbar);
        })
        .finally(() => setLoadingSimulacion(false));
    }
    setIsLoading(false);
  };

  const ButtonConstitucion = (props: {
    accion: string;
    pasoActual: PasoConstitucion;
  }) => {
    const { accion, pasoActual } = props;

    return (
      <LoadingButton
        variant="contained"
        style={{ marginLeft: "1rem", float: "right" }}
        onClick={() =>
          pasoActual.pagina == PasoConstitucion2.pagina &&
          accion == "constituir"
            ? constituirInversion()
            : actualizarPaso(pasoActual.pagina + (accion == "seguir" ? 1 : -1))
        }
        color={["seguir", "constituir"].includes(accion) ? "primary" : "error"}
        loading={accion === "constituir" ? isLoading : false}
        disabled={
          accion === "constituir"
            ? isButtonDisabled
            : plazoReal > 180
            ? true
            : false
        }
      >
        {accion == "seguir"
          ? "Siguiente"
          : accion == "constituir"
          ? "Constituir"
          : "Volver"}
      </LoadingButton>
    );
  };

  useEffect(() => {
    //Cambia la flag para retornar al primer paso despues de 3 minutos (180000 mili segundos), solo si estamos en el segundo paso
    setReturnToFirstStep(false);
    pasoActual.pagina === PasoConstitucion2.pagina &&
      setTimeout(() => {
        setReturnToFirstStep(true);
      }, 180000);
  }, [pasoActual.pagina]);

  useEffect(() => {
    //si la flag para retornar al primer paso es true y el paso actual es el segundo va a retornar a la primer pantalla
    if (returnToFirstStep && pasoActual.pagina === PasoConstitucion2.pagina) {
      actualizarPaso(pasoActual.pagina + -1);
      setShowInactivityError(true);
    }
  }, [returnToFirstStep]);

  return (
    <>
      <PageHeader title={"Simular Inversión"} />
      <Grid container spacing={2}>
        <Grid item xs={12} className="field-set-container">
          {pasoActual.pagina == PasoConstitucion1.pagina ? (
            <PrimerPasoConstitucion
              pasoActual={pasoActual.pagina}
              pasoValidado={pasoValidado}
              ordenesCertificado={ordenesCertificado}
              selectedOrdenCertificado={selectedOrdenCertificado}
              setSelectedOrdenCertificado={setSelectedOrdenCertificado}
              monto={monto}
              setMonto={setMonto}
              saldoCuenta={saldoCuenta}
              accionesVencimiento={accionesVencimiento}
              selectedAccionVencimiento={selectedAccionVencimiento}
              setSelectedAccionVencimiento={setSelectedAccionVencimiento}
              tiposVencimiento={tiposVencimiento}
              selectedTipoVencimiento={selectedTipoVencimiento}
              setSelectedTipoVencimiento={setSelectedTipoVencimiento}
              plazoDias={plazoDias}
              setPlazoDias={setPlazoDias}
              fechaVencimiento={fechaVencimiento}
              setFechaVencimiento={setFechaVencimiento}
              titulares={titulares}
              selectedTitulares={selectedTitulares}
              setSelectedTitulares={setSelectedTitulares}
              selectedCuentaUsuario={selectedCuentaUsuario}
              getDiffDays={getDiffDays}
              systemDate={systemDate}
              plazoReal={plazoReal}
              setPlazoReal={setPlazoReal}
            />
          ) : (
            <SegundoPasoConstitucion
              aceptaTerminos={aceptaTerminos}
              setAceptaTerminos={setAceptaTerminos}
              detalleSimulacion={detalleSimulacion}
              loadingSimulacion={loadingSimulacion}
              selectedCuenta={selectedCuentaUsuario}
              selectedOrdenCertificado={selectedOrdenCertificado}
              selectedAccionVencimiento={selectedAccionVencimiento}
              plazoDias={
                plazoDias
                  ? plazoDias
                  : getDiffDays(systemDate, fechaVencimiento)
              }
              selectedTitulares={selectedTitulares}
              monto={monto}
              saldoCuenta={saldoCuenta}
              actualizarPaso={actualizarPaso}
              pasoActual={pasoActual.pagina}
            />
          )}
        </Grid>
      </Grid>

      <Grid container justifyContent="end" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={bp.is1300Up ? 4 : 12}
          lg={8}
          marginBottom={2}
          justifyContent="end"
        >
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={() => setAlertOpen(false)}
          >
            <Alert
              onClose={() => setAlertOpen(false)}
              severity="success"
              sx={{
                width: "100%",
                backgroundColor: colors.warningDark,
                color: colors.paper,
              }}
            >
              Sitio en mantenimiento, por favor inténtelo de nuevo más tarde.
            </Alert>
          </Snackbar>
          <Snackbar
            open={showInactivityError}
            autoHideDuration={12000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={() => setShowInactivityError(false)}
          >
            <Alert
              onClose={() => setShowInactivityError(false)}
              severity="success"
              sx={{
                width: "100%",
                backgroundColor: colors.errorMain,
                color: colors.paper,
              }}
            >
              Le informamos que debido a su inactividad el proceso de simulación
              ha sido cancelado preventivamente. Intente nuevamente.
            </Alert>
          </Snackbar>
        </Grid>
        {!loadingSimulacion && (
          <Grid item xs={12} sm={12} md={bp.is1300Up ? 4 : 12} marginBottom={2}>
            <ButtonConstitucion
              accion={
                pasoActual.pagina == PasoConstitucion2.pagina
                  ? "constituir"
                  : "seguir"
              }
              pasoActual={pasoActual}
            />
            {pasoActual && pasoActual.pagina != PasoConstitucion1.pagina && (
              <ButtonConstitucion accion="volver" pasoActual={pasoActual} />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InversionConstitucionPage;
