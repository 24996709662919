import { SxProps, styled } from "@mui/material/styles";
import {
  LinearProgress,
  Tooltip,
  Typography,
  linearProgressClasses,
} from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

interface ProgressBarProps {
  currentValue: number;
  total: number;
  sx?: SxProps;
}

const ProgressBar = (props: ProgressBarProps) => {
  const fixedCurrent =
    props.currentValue > props.total ? props.total : props.currentValue;
  const fixedPercent = props.currentValue > props.total ? ">" : "";
  const progress = (fixedCurrent * 100) / props.total;

  return (
    <Tooltip
      title={`${props.currentValue}/${props.total} (${fixedPercent}${progress.toFixed(0)}%)`}
      arrow
    >
      <Typography variant="h1">
        <BorderLinearProgress
          variant="determinate"
          value={progress}
          sx={props?.sx}
        />
      </Typography>
    </Tooltip>
  );
};

export default ProgressBar;
