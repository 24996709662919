import { Grid, FormControlLabel, FormGroup, Checkbox, Paper } from '@mui/material'
import { DetalleInversionConstitucion, OrdenCertificado, AccionVencimientoConstitucion } from '../../../services/InversionService/inversion.service'
import { Contacto } from '../../../services/ContactoService/contacto.service'
import '../InversionConstitucionPage.scss'
import Loader from '../../../components/Loader/Loader'
import SeccionDetalleSimulacion from './SeccionDetalleSimulacion'
import SeccionCuenta from './SeccionCuenta'
import SeccionFormulario from './SeccionFormulario'
import { UsuarioCuenta } from '../../../contexts/UsuarioContext'

const SegundoPasoConstitucion = (props: {
    aceptaTerminos: boolean,
    setAceptaTerminos: Function,
    detalleSimulacion: DetalleInversionConstitucion,
    loadingSimulacion: boolean,
    selectedCuenta: UsuarioCuenta,
    selectedOrdenCertificado: OrdenCertificado
    selectedAccionVencimiento: AccionVencimientoConstitucion,
    plazoDias: number,
    selectedTitulares: Contacto[],
    monto: number,
    saldoCuenta: number,
    actualizarPaso: Function,
    pasoActual: number,
}) => {

    const { aceptaTerminos, setAceptaTerminos, detalleSimulacion, loadingSimulacion, selectedCuenta,
        selectedOrdenCertificado, selectedAccionVencimiento, selectedTitulares, plazoDias, monto, saldoCuenta, actualizarPaso, pasoActual
    } = props

    const DetalleConstitucionContent = () => {

        return (
            detalleSimulacion &&
            (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SeccionCuenta
                            selectedCuenta={selectedCuenta}
                            detalleSimulacion={detalleSimulacion}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SeccionFormulario
                            selectedAccionVencimiento={selectedAccionVencimiento}
                            selectedOrdenCertificado={selectedOrdenCertificado}
                            selectedTitulares={selectedTitulares}
                            plazoDias={plazoDias}
                            monto={monto}
                            detalleSimulacion={detalleSimulacion}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SeccionDetalleSimulacion
                            detalleSimulacion={detalleSimulacion} 
                            selectedAccionVencimiento={selectedAccionVencimiento}
                            selectedOrdenCertificado={selectedOrdenCertificado}
                        />
                    </Grid>
                    <Grid item xs={12} className='tercer-paso-constitucion'>
                        <Grid direction="row">
                            <FormGroup>
                                <FormControlLabel
                                    onChange={() => setAceptaTerminos(!aceptaTerminos)}
                                    control={<Checkbox checked={aceptaTerminos} />}
                                    label="Acepto términos y condiciones"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
            )
        )
    }

    return (
        <Grid container marginTop={2}>
            <Grid item xs={12}>
                {
                    loadingSimulacion
                        ? (
                            <Loader label={detalleSimulacion ? 'Constituyendo inversión' : "Simulando inversión"} />
                        )
                        : (
                            <Paper elevation={12} sx={{ padding: '1rem', borderRadius: '25px' }}>
                                <DetalleConstitucionContent />
                            </Paper>
                        )
                }
            </Grid>
        </Grid>
    )
}

export default SegundoPasoConstitucion