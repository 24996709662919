import { Inversion } from '../../../services/InversionService/inversion.service'
import InversionPageCard from '../../../components/Cards/InversionCards/InversionPageCard'
import { Grid } from '@mui/material'
import useBreakpoints from '../../../hooks/breakpoints.hook'

const InversionEspecifica = (props: { inversion: Inversion, selectedInversion: Inversion, setSelectedInversion: Function }) => {
    const { selectedInversion, setSelectedInversion, inversion } = props
    const bp = useBreakpoints()
    return (
        <Grid item md={bp.is1300Up ? 4 : 6} sm={bp.is750Up ? 6 : 12} xs={12}>
            <InversionPageCard
                inversion={inversion}
                selectedInversion={selectedInversion}
                setSelectedInversion={setSelectedInversion}
            />
        </Grid>
    )
}

export default InversionEspecifica