import { useNavigate } from "react-router-dom";
import { IconEnum } from "../../../../components/Icon/Icon.props";
import QuickAccessButton from "./QuickAccessButton";
import "./QuickAccessMenuComponent.scss";

const QuickAccessMenuComponent = () => {
  const navigateTo = useNavigate();

  return (
    <div className="main-quick-access-div">
      <QuickAccessButton
        icon={IconEnum.CUENTAS}
        text="Caja de Ahorro"
        onClick={() => navigateTo("/cuenta/movimientos")}
      />
      <QuickAccessButton
        icon={IconEnum.TRANSFERENCIAS}
        text="Transferencias"
        onClick={() => navigateTo("/transferencias")}
      />
      <QuickAccessButton
        icon={IconEnum.PRESTAMOS}
        text="Préstamos"
        onClick={() => navigateTo("/prestamos")}
      />
      <QuickAccessButton
        icon={IconEnum.INVERSIONES}
        text="Inversiones"
        onClick={() => navigateTo("/inversiones")}
      />
    </div>
  );
};

export default QuickAccessMenuComponent;
