import { Cell } from "./Table.props";
import colors from "../../../assets/_themes-vars.module.scss";


export const DefaultHeaderCell = (text: string): Cell => {
    return {
      text: text,
      sx: { color: colors.lightTextSecondary },
      pl: "0.5rem",
      pr: "0.5rem",
      align: "center",
      textVariant: "h3",
    };
  };

  export const DefaultSmHeaderCell = (text: string): Cell => {
    return {
      text: text?.split(" ")[0],
      sx: { color: colors.lightTextSecondary },
      pl: "0.4rem",
      pr: "0.4rem",
      align: "center",
      textVariant: "h3",
    };
  };