import { IconEnum, IconProps } from "./Icon.props";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LockResetIcon from '@mui/icons-material/LockReset';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import GavelIcon from '@mui/icons-material/Gavel';

const Icon = (props: IconProps) => {
  const sharedProps = { sx: props.sx, className: props.className };
  switch (props.icon) {
    case IconEnum.HOME:
      return <HomeIcon {...sharedProps} />;
    case IconEnum.CUENTAS:
      return <AccountBoxIcon {...sharedProps} />;
    case IconEnum.TARJETAS:
      return <CreditCardIcon {...sharedProps} />;
    case IconEnum.INVERSIONES:
      return <TrendingUpIcon {...sharedProps} />;
    case IconEnum.PRESTAMOS:
      return <PriceCheckIcon {...sharedProps} />;
    case IconEnum.TRANSFERENCIAS:
      return <CurrencyExchangeIcon {...sharedProps} />;
    case IconEnum.VIEW_MORE:
      return <ArrowForwardIcon {...sharedProps} />;
    case IconEnum.SHARE:
      return <ShareIcon {...sharedProps} />;
    case IconEnum.MORE_VERTICAL:
      return <MoreVertIcon {...sharedProps} />;
    case IconEnum.VISIBILITY_ON:
      return <VisibilityIcon {...sharedProps} />;
    case IconEnum.VISIBILITY_OFF:
      return <VisibilityOffIcon {...sharedProps} />;
    case IconEnum.PAID:
      return <PaidOutlinedIcon {...sharedProps} />;
    case IconEnum.DOWN:
      return <KeyboardArrowDownIcon {...sharedProps} />;
    case IconEnum.UP:
      return <KeyboardArrowUpIcon {...sharedProps} />;
    case IconEnum.RIGHT:
      return <KeyboardArrowRightIcon {...sharedProps} />;
    case IconEnum.CLEAR:
      return <ClearIcon {...sharedProps} />;
    case IconEnum.SEARCH:
      return <SearchIcon {...sharedProps} />;
    case IconEnum.NOTIFICATIONS_NONE:
      return <NotificationsNoneIcon {...sharedProps} />;
    case IconEnum.NOTIFICATIONS_ACTIVE:
      return <NotificationsActiveIcon {...sharedProps} />;
    case IconEnum.PLAIN_MONEY:
      return <AttachMoneyIcon {...sharedProps} />;
    case IconEnum.LOGOUT:
      return <LogoutIcon {...sharedProps} />;
    case IconEnum.INFO:
      return <InfoIcon {...sharedProps} />;
    case IconEnum.DOWNLOAD:
      return <ArrowDownwardIcon {...sharedProps} />;
    case IconEnum.CHANGE_PASSWORD:
      return <LockResetIcon {...sharedProps} />;
    case IconEnum.PDF:
      return <PictureAsPdfIcon {...sharedProps} />;
    case IconEnum.CSV:
      return <DescriptionIcon {...sharedProps} />;
    case IconEnum.TERMS_AND_CONDITIONS:
      return <GavelIcon {...sharedProps} />;
    default:
      return;
  }
};

export default Icon;
