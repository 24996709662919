import { useEffect, useState } from "react";
import { Contacto } from "../../../../services/ContactoService/contacto.service";
import { UseContactos } from "../ContactosContext";
import { useSnackbar } from "notistack";
import SaveContacto from "../SaveContacto.function";

interface UseAgendarResult {
  agendarEnabled: boolean;
  changeAgendarState: () => void;
  agendar: () => void;
}

const useAgendar = (
  selectedContact: Contacto
): UseAgendarResult => {
  const [agendarEnabled, setAgendarEnabled] = useState<boolean>(false);
  const { refreshContactos } = UseContactos();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setAgendarEnabled(false);
  }, [selectedContact]);

  const changeAgendarState = () =>
    setAgendarEnabled((prevAgendarState) => !prevAgendarState);

  const saveContactoHandler = () => {
    SaveContacto({
      contactNroSocio: selectedContact.NSocio,
      rererencia: "",
      refreshContactos,
      enqueueSnackbar,
    });
  };

  return {
    agendarEnabled,
    changeAgendarState,
    agendar: saveContactoHandler,
  };
};

export default useAgendar;
