import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import "../HomeCard.scss";
import { ResumenInversionesCardProps } from "./ResumenInversionesCard.props";
import ViewMoreButton from "../../../ViewMoreButton/ViewMoreButton";
import { HiddenBalanceText } from "../../../HiddenBalanceButton/HiddenBalanceText";
import "./ResumenInversionesCard.scss";
import { useNavigate } from "react-router-dom";
import {
  DateToStringConverter,
  NumberToMoneyConverter,
} from "../../../Formatters/Formatters";
import Icon from "../../../Icon/Icon";
import { IconEnum } from "../../../Icon/Icon.props";
import { ReactElement, useMemo } from "react";
import useBreakpoints from "../../../../hooks/breakpoints.hook";

const ResumenInversionesCard = (resumen: ResumenInversionesCardProps) => {
  const navigate = useNavigate();

  const hasOwnInverstments = Boolean(resumen.cantidadPropias);

  const balanceText = resumen.hiddenBalance
    ? `$ ${HiddenBalanceText}`
    : `${NumberToMoneyConverter(resumen.total)}`;

  const balanceTooltipText = resumen.hiddenBalance
    ? `Total propias: $ ${HiddenBalanceText}`
    : `Total propias: ${NumberToMoneyConverter(resumen.totalPropias)}`;

  const proxVencimientoTooltipText = resumen.hiddenBalance
    ? `Monto: $ ${HiddenBalanceText}`
    : `Monto: ${NumberToMoneyConverter(resumen.montoProximoVencimiento)}`;

  return (
    <Card className="home-card">
      <CardContent className="home-card-principal-data">
        <Box className="home-card-principal-data-box">
          <Typography className="home-card-title" variant={"h2"}>
            Total acumulado
          </Typography>
          <ResumenInversionesTooltip text={balanceTooltipText}>
            <Typography
              variant="h1"
              className="home-card-balance"
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              {balanceText}
              <Icon icon={IconEnum.INFO} />
            </Typography>
          </ResumenInversionesTooltip>
        </Box>
        <Box
          className="home-card-other-data"
          sx={{
            flexWrap: "nowrap !important",
          }}
        >
          {hasOwnInverstments && (
            <ResumenInversionesTooltip
              text={proxVencimientoTooltipText}
              offset={[32, -10]}
              placement="top"
            >
              <Chip
                className="inversiones-resumen-vencimiento no-clickable-delete-icon"
                label={`Próx. Vto. ${DateToStringConverter(
                  resumen.fechaProximoVencimiento
                )}`}
                color={"success"}
                variant={"filled"}
                deleteIcon={<Icon icon={IconEnum.INFO} />}
                onDelete={() => {}}
              />
            </ResumenInversionesTooltip>
          )}
          <Typography
            variant="body2"
            className="inversiones-resumen-cantidad-vigentes"
            sx={{ margin: !hasOwnInverstments ? "0px !important" : undefined }}
          >
            {`Vigentes: ${resumen.cantidad}`}
          </Typography>
        </Box>
      </CardContent>

      <CardActions className="home-card-actions">
        <ViewMoreButton onClick={() => navigate(`/inversiones`)} />
      </CardActions>
    </Card>
  );
};

export default ResumenInversionesCard;

interface ResumenInversionesTooltipProps {
  children: ReactElement;
  text: string;
  offset?: [number, number];
  placement?: "bottom" | "top";
}

const ResumenInversionesTooltip = ({
  children,
  text,
  offset = [20, -15],
  placement = "bottom",
}: ResumenInversionesTooltipProps) => {
  const { isSmDown } = useBreakpoints();
  const finalOffset = useMemo(
    () => (isSmDown ? [offset[0], offset[1] - 7] : offset),
    [isSmDown]
  );

  return (
    <Tooltip
      title={text}
      placement={placement === "bottom" ? "bottom-start" : "top-start"}
      enterTouchDelay={0}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: finalOffset,
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );
};
