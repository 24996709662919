import { useEffect, useState } from "react";
import { UseUsuario } from "../../../contexts/UsuarioContext";
import PrestamoService from "../../../services/PrestamoService/prestamo.service";
import { LineaPrestamo, PrestamoResumen } from "../interfaces/Prestamo";
import { useSnackbar } from "notistack";
import { defaultErrorSnackbar } from "../../../utils/snackBarConfigs.util";

interface UsePrestamosResult {
  lineasPrestamos: LineaPrestamo[];
  prestamos: PrestamoResumen[];
  isLoadingPrestamos: boolean;
}

const usePrestamos = (): UsePrestamosResult => {
  const { selectedCuentaUsuario } = UseUsuario();
  const [prestamos, setPrestamos] = useState<PrestamoResumen[]>([]);
  const [lineasPrestamos, setLineasPrestamos] = useState<LineaPrestamo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const getAllPrestamosData = () => {
    setIsLoading(true);
    Promise.all([
      PrestamoService.GetAll(),
      PrestamoService.GetLineasPrestamos(),
    ])
      .then(([prestamosResult, lineasPrestamosResult]) => {
        setPrestamos(prestamosResult);
        setLineasPrestamos(lineasPrestamosResult);
      })
      .catch(() => {
        enqueueSnackbar(
          `Hubo un error al obtener los préstamos`,
          defaultErrorSnackbar
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (selectedCuentaUsuario) getAllPrestamosData();
  }, [selectedCuentaUsuario]);

  return { lineasPrestamos, prestamos, isLoadingPrestamos: isLoading };
};

export default usePrestamos;
