import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { defaultErrorSnackbar } from "../../../utils/snackBarConfigs.util";
import {
  PrestamoAnticipadoDetalle,
  PrestamoConCuotasDetalle,
  PrestamoIdentifier,
  PrestamoVencidoDetalle,
} from "../interfaces/Prestamo";

type Prestamo =
  | PrestamoConCuotasDetalle
  | PrestamoAnticipadoDetalle
  | PrestamoVencidoDetalle;

interface UseGenericDetallePrestamoProps extends PrestamoIdentifier {
  serviceToCall: (prestamoData: PrestamoIdentifier) => Promise<Prestamo>;
}

interface UseGenericDetallePrestamoResult {
  detallePrestamo: Prestamo;
  isLoading: boolean;
}

const useGenericDetallePrestamo = (
  props: UseGenericDetallePrestamoProps
): UseGenericDetallePrestamoResult => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [prestamo, setPrestamo] = useState<Prestamo>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const getPrestamoData = () => {
    setIsLoading(true);
    props
      .serviceToCall(props)
      .then((result) => setPrestamo(result))
      .catch(() => {
        enqueueSnackbar(
          `Hubo un error al obtener la información del préstamo`,
          defaultErrorSnackbar
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getPrestamoData();
  }, []);

  return { isLoading, detallePrestamo: prestamo };
};

export default useGenericDetallePrestamo;
