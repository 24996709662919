import Loader from "../../../../components/Loader/Loader";
import { MovementAllData } from "../../../../services/CuentaService/cuenta.service.interfaces";
import { useCardDivResponsiveStyle } from "../CardResponsiveStyle.hook";
import HeaderLastMovements from "./HeaderLastMovements";
import LastMovementsHomeTable from "./MovementsTable/LastMovementsHomeTable";

interface LastMovementsSectionProps {
  nroCuenta: number;
  isLoading: boolean;
  movements: MovementAllData[];
}

const LastMovementsHomeSection = ({
  nroCuenta,
  isLoading,
  movements,
}: LastMovementsSectionProps) => {
  const cardDivResponsiveStyle = useCardDivResponsiveStyle();

  return (
    <div className="home-last-movements-div" style={cardDivResponsiveStyle}>
      <HeaderLastMovements numeroCuenta={nroCuenta} />
      {isLoading ? (
        <Loader label="Cargando movimientos" />
      ) : (
        <LastMovementsHomeTable movements={movements} cuentaId={nroCuenta} />
      )}
    </div>
  );
};

export default LastMovementsHomeSection;
