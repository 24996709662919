export const marks = [
  {
    value: 0,
    label: "$0",
  },
  {
    value: 10,
    label: "$1K",
  },
  {
    value: 28,
    label: "$10K",
  },
  {
    value: 46,
    label: "$100K",
  },
  {
    value: 64,
    label: "$1M",
  },
  {
    value: 82,
    label: "$10M",
  },
  {
    value: 100,
    label: "+$100M",
  },
];
