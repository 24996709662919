import { Checkbox, Typography } from "@mui/material";

interface AgendarContactoProps {
  isEnabled: boolean;
  changeState: () => void;
}

const AgendarContacto = (props: AgendarContactoProps) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={(event) => {
        event.preventDefault();
        props.changeState();
      }}
    >
      <Checkbox checked={props.isEnabled} />
      <Typography
        variant="h4"
        style={{ cursor: "pointer", userSelect: "none" }}
        className="transferencia-data-item-label"
      >
        Agendar Contacto
      </Typography>
    </div>
  );
};

export default AgendarContacto;
