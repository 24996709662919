import HomeApi from "../../config/axios";
import {
  MakeTransferProps,
  MakeTransferPlusProps,
} from "./transferencia.service.interfaces";

const TransferenciaService = {
  MakeTransfer: (props: MakeTransferProps): Promise<number> => {
    return new Promise((resolve, reject) => {
      HomeApi.post<{ Comprobante: number }>(`transferencias`, props)
        .then((response) => resolve(response.data.Comprobante))
        .catch((error) => reject(error.message));
    });
  },
  MakeTransferPlus: (props: MakeTransferPlusProps): Promise<void> => {
    return new Promise((resolve, reject) => {
      HomeApi.post<any>(`transferencias/Remunerada`, props)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.message));
    });
  },
};

export default TransferenciaService;
