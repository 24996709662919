import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import "./NoElementsCardComponent.scss";
import { NoElementCardProps } from "./NoElementsCard.props";
import Icon from "../../../Icon/Icon";
import colors from "../../../../assets/_themes-vars.module.scss";
import { useNavigate } from "react-router-dom";

const NoElementsCard = (props: NoElementCardProps) => {
  const navigate = useNavigate();
  return (
    <Card className="no-elements-card">
      <CardContent
        className="no-elements-principal-data"
        sx={{ width: "100%" }}
      >
        <Typography
          variant="h4"
          sx={{ color: colors.lightTextPrimary, width: "100%", mt: "2.75rem" }}
        >
          {props.errorText}
        </Typography>
      </CardContent>

      <CardActions className="no-elements-card-actions" sx={{ width: "100%" }}>
        {Boolean(props.buttonText) && Boolean(props.redirectPath) && (
          <Button
            sx={{ width: "27%", minWidth: "12rem", height: "60%" }}
            onClick={() => {
              navigate(props.redirectPath);
            }}
            variant="contained"
            color="inherit"
          >
            {Icon({
              icon: props.icon,
            })}
            <Typography
              variant="body2"
              sx={{ color: "inherit", ml: "10px", width: "80%" }}
            >
              {props.buttonText}
            </Typography>
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default NoElementsCard;
