import { useState } from "react";
import UserCardComponent from "../../UserCardComponent/UserCardComponent";
import Loader from "../../Loader/Loader";
import { Grid } from "@mui/material";

const SwitchCuentasContainer = (props: { setOpenDrawer: Function }) => {
  const { setOpenDrawer } = props;
  const [isChangingAccount, setIsChangingAccount] = useState<boolean>(false);

  return (
    <Grid container sx={{ width: "226px" }}>
      {isChangingAccount && <SwitchCuentasLoader />}
      {!isChangingAccount && (
        <UserCardComponent
          setOpenDrawer={setOpenDrawer}
          setIsChangingAccount={setIsChangingAccount}
        />
      )}
    </Grid>
  );
};

export default SwitchCuentasContainer;

const SwitchCuentasLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      <Loader label={"Cambiando de cuenta"} />
    </div>
  );
};
