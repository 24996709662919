import { SxProps, Theme } from "@mui/material";

export enum IconEnum {
  HOME,
  CUENTAS,
  TARJETAS,
  INVERSIONES,
  PRESTAMOS,
  TRANSFERENCIAS,
  VIEW_MORE,
  SHARE,
  MORE_VERTICAL,
  VISIBILITY_ON,
  VISIBILITY_OFF,
  DOWN,
  UP,
  PAID,
  RIGHT,
  CLEAR,
  SEARCH,
  NOTIFICATIONS_NONE,
  NOTIFICATIONS_ACTIVE,
  PLAIN_MONEY,
  LOGOUT,
  INFO,
  DOWNLOAD,
  CHANGE_PASSWORD,
  PDF,
  CSV,
  TERMS_AND_CONDITIONS
}

export interface IconProps {
  sx?: SxProps<Theme>;
  className?: string;
  icon: IconEnum;
}
