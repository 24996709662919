import { useEffect, useMemo, useState } from "react";
import { PageHeader } from "../../components/Headers/PageHeader";
import FiltersSection from "../CuentaEspecifica/PageComponents/FiltersSection/FiltersSection";
import {
  Cuenta,
  ICuentaFilters,
  MovementAllData,
} from "../../services/CuentaService/cuenta.service.interfaces";
import { DefaultFiltersWithNulls } from "../CuentaEspecifica/PageComponents/Filters/FilterUtils";
import { UseUsuario, UsuarioCuenta } from "../../contexts/UsuarioContext";
import { SectionHeader } from "../../components/Headers/SectionHeader";
import AllPageLoader from "../../components/Loader/AllPageLoader";
import Loader from "../../components/Loader/Loader";
import MovementsTable from "../CuentaEspecifica/PageComponents/MovementsTable/MovementsTable";
import { PaginationConfig } from "../../components/Tables/TableWrapper/PaginationConfig";
import CuentaService from "../../services/CuentaService/cuenta.service";
import { CuentaEspecificaFiltersToGetMovements } from "../../services/CuentaService/cuenta.service.adapter";
import { useSnackbar } from "notistack";
import { defaultErrorSnackbar } from "../../utils/snackBarConfigs.util";
import { Box, Button } from "@mui/material";
import useFilterResponsiveStyles from "../CuentaEspecifica/PageComponents/Filters/FilterResponsiveStyle";
import DrawerCtaRemunerada from "./PageComponents/DrawerCtaRemunerada/DrawerCtaRemunerada";
import SwipeableDrawerComponent from "../../components/Drawer/SwipeableDrawer/SwipeableDrawer";

const CuentaRemuneradaPage = () => {
  const [filters, setFilters] = useState<ICuentaFilters>(
    DefaultFiltersWithNulls
  );
  const [isLoadingMovements, setIsLoadingMovements] = useState(true);
  const [movements, setMovements] = useState<MovementAllData[]>([]);
  const [totalMovements, setTotalMovements] = useState<number>(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerAction, setDrawerAction] = useState<string | null>(null);

  const { selectedCuentaUsuario } = UseUsuario();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useFilterResponsiveStyles();

  const btnStyles = {
    ...styles.button,
    marginBottom: "0.5rem",
    margin: 0,
  };

  const getAccountData = (currentCuenta: UsuarioCuenta): Cuenta => {
    return currentCuenta
      ? ({
          Moneda: "ARS",
          Etiqueta: currentCuenta.Etiqueta,
          NroCuenta: currentCuenta.NroSocio,
          FilialId: currentCuenta.FilialId,
        } as Cuenta)
      : undefined;
  };

  const cuenta: Cuenta = useMemo(
    () => getAccountData(selectedCuentaUsuario),
    [selectedCuentaUsuario]
  );

  const paginationConfig = {
    page: filters.page,
    pageSize: filters.pageSize,
    pageSizeOptions: filters.pageSizeOptions,
  };

  const onPaginationChange = (config: PaginationConfig) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...config }));
  };

  const applyFilters = (newFilters: ICuentaFilters) => {
    setFilters((prevFilters) => ({
      ...newFilters,
      page: 0,
      pageSize: prevFilters.pageSize,
    }));
  };

  const getMovements = () => {
    setIsLoadingMovements(true);
    CuentaService.GetMovementsPlus(
      CuentaEspecificaFiltersToGetMovements({
        filters: filters,
      })
    )
      .then((result) => {
        setMovements(result.Results);
        setTotalMovements(result.TotalElements);
      })
      .catch(() =>
        enqueueSnackbar(
          `Hubo un error al obtener los movimientos`,
          defaultErrorSnackbar
        )
      )
      .finally(() => setIsLoadingMovements(false));
  };

  useEffect(() => {
    if (cuenta) {
      getMovements();
    }
  }, [cuenta, filters]);

  return (
    <>
      {cuenta === undefined && <AllPageLoader label={"Cargando..."} />}
      {cuenta !== undefined && (
        <div className="generic-main-div">
          <PageHeader
            title={`Ahorro Mutual Plus (${selectedCuentaUsuario?.NroSocio})`}
          />
          <div className="cuenta-especifica-table-container">
            <div className="cuenta-especifica-default-div">
              <Box
                display={"flex"}
                justifyContent={"center"}
                gap={1}
                p="5px"
                mb={1}
                sx={{ width: "92%" }}
              >
                <Button
                  variant="contained"
                  sx={btnStyles}
                  onClick={() => {
                    setDrawerAction("verIntereses");
                    setIsDrawerOpen(true);
                  }}
                >
                  Ver Intereses
                </Button>
                <Button
                  variant="contained"
                  sx={btnStyles}
                  onClick={() => {
                    setDrawerAction("transferirSaldo");
                    setIsDrawerOpen(true);
                  }}
                >
                  Transferir Saldo
                </Button>
              </Box>
              <FiltersSection
                cuenta={cuenta}
                applyFilters={applyFilters}
                ctaRemunerada={true}
              />
              <div className="cuenta-especifica-movimientos-header">
                <SectionHeader title={`Movimientos`} />
              </div>
            </div>
            {isLoadingMovements && (
              <Loader label={"Buscando los movimientos"} />
            )}
            {!isLoadingMovements && (
              <MovementsTable
                movements={movements}
                totalMovements={totalMovements}
                paginationConfig={paginationConfig}
                onPageChange={onPaginationChange}
                ctaRemunerada={true}
              />
            )}
          </div>
        </div>
      )}
      <SwipeableDrawerComponent
        openDrawer={isDrawerOpen}
        setOpenDrawer={setIsDrawerOpen}
        anchor="right"
        contentDrawer={
          <DrawerCtaRemunerada
            drawerAction={drawerAction}
            filters={filters}
            setOpenDrawer={setIsDrawerOpen}
          />
        }
        title={
          drawerAction === "verIntereses"
            ? "Ver intereses"
            : "Transferir Saldo Plus"
        }
      />
    </>
  );
};

export default CuentaRemuneradaPage;
