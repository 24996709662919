import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useBreakpoints from "../../../hooks/breakpoints.hook";
import Icon from "../../Icon/Icon";
import { IconEnum } from "../../Icon/Icon.props";
import { Cell, TablePaginationProps, TableProps } from "./Table.props";

interface GetTotalEmptyRows {
  pagination: TablePaginationProps;
  fixedRowsQuantity: number;
  totalRows: number;
}

const getTotalEmptyRows = (props: GetTotalEmptyRows): number => {
  if (props.pagination !== undefined) {
    return Math.max(
      0,
      (1 + props.pagination.currentPage) * props.pagination.currentRowsPerPage -
        props.pagination.totalCount
    );
  }

  if (props?.totalRows !== 0 && props.fixedRowsQuantity !== undefined) {
    return props.fixedRowsQuantity - props.totalRows;
  }

  return 0;
};

const TableComponent = (props: TableProps) => {
  const hasHiddenContent = props.hiddenContent !== undefined;
  const hasFooter = props.footer !== undefined;
  const isEmptyTable = props.bodyCells.length === 0;
  const totalEmptyRows = getTotalEmptyRows({
    pagination: props?.tablePagination,
    fixedRowsQuantity: props?.fixedRowsQuantity,
    totalRows: props?.bodyCells?.length,
  });
  const totalColumns = props.headerCells.length + (hasHiddenContent ? 1 : 0);
  const bp = useBreakpoints();

  const Header = () => {
    return (
      <>
        <TableRow className={props.rowClassName}>
          {hasHiddenContent ? (
            <TableCell
              align={props.hiddenArrowStyle.align}
              sx={{
                width: props.hiddenArrowStyle.width,
                pl: props.hiddenArrowStyle.pl,
                pr: props.hiddenArrowStyle.pr,
              }}
            />
          ) : undefined}
          {props.headerCells.map((headerCell) => (
            <TableCell
              key={headerCell.text}
              align={headerCell.align}
              sx={{
                width: headerCell.width,
                pl: headerCell.pl,
                pr: headerCell.pr,
              }}
            >
              {headerCell.textVariant === "element" ? (
                <>headerCell.element</>
              ) : (
                <Typography variant={headerCell.textVariant} sx={headerCell.sx}>
                  {headerCell.text}
                </Typography>
              )}
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  };

  const Row = (rowProps: { row: Cell[]; index: number }) => {
    const { row } = rowProps;
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow className={props.rowClassName}>
          {hasHiddenContent ? (
            <TableCell
              align={props.hiddenArrowStyle.align}
              sx={{
                width: props.hiddenArrowStyle.width,
                pl: props.hiddenArrowStyle.pl,
                pr: props.hiddenArrowStyle.pr,
                border: "0px !important",
              }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <Icon icon={IconEnum.UP} />
                ) : (
                  <Icon icon={IconEnum.DOWN} />
                )}
              </IconButton>
            </TableCell>
          ) : undefined}
          {row.map((cell, i) => (
            <TableCell
              key={cell.text + i}
              align={cell.align}
              sx={{
                width: cell.width,
                pl: cell.pl,
                pr: cell.pr,
                borderBottom: "0px !important",
              }}
            >
              {cell.textVariant === "element" ? (
                <>{cell.element}</>
              ) : (
                <Typography variant={cell.textVariant} sx={cell.sx}>
                  {cell.text}
                </Typography>
              )}
            </TableCell>
          ))}
        </TableRow>
        {hasHiddenContent ? (
          <TableRow sx={{ "& td": { border: "0" } }}>
            <TableCell sx={{ padding: 0 }} colSpan={totalColumns}>
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                className={props.hiddenContentClassName}
              >
                {props.hiddenContent[rowProps.index]}
              </Collapse>
            </TableCell>
          </TableRow>
        ) : undefined}
      </>
    );
  };

  const EmtpyRows = () => {
    let emtpyRowsList: JSX.Element[] = [];
    let index = 0;
    let indexFirstBorder = 0;

    if (isEmptyTable) {
      emtpyRowsList.push(
        <TableRow
          key={`EmptyRow ${index}`}
          className={props.emptyRowClassName}
          sx={{ "& td": { border: "0" }, borderBottom: "0px !important" }}
        >
          <TableCell sx={{ padding: 0 }} colSpan={totalColumns}>
            {props.emtpyTableContent}
          </TableCell>
        </TableRow>
      );
      index++;
      indexFirstBorder = 1;
    }

    for (index; index < totalEmptyRows; index++) {
      emtpyRowsList.push(
        <TableRow
          key={`EmptyRow ${index}`}
          className={props.emptyRowClassName}
          sx={{
            "& td": { border: "0" },
            borderTop:
              index === indexFirstBorder
                ? "thin solid !important"
                : "0px !important",
            borderBottom: "0px !important",
          }}
        >
          <TableCell sx={{ padding: 0 }} colSpan={totalColumns}></TableCell>
        </TableRow>
      );
    }
    return emtpyRowsList;
  };

  const Footer = () => {
    return hasFooter ? props.footer : undefined;
  };

  const Pagination = () => {
    const onPageChange = (_event: unknown, newPage: number) => {
      props.tablePagination?.onChange({
        page: newPage,
        pageSize: props.tablePagination?.currentRowsPerPage,
      });
    };

    const onRowsPerPageChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      props.tablePagination?.onChange({
        page: 0,
        pageSize: parseInt(event.target.value, 10),
      });
    };

    return (
      <TablePagination
        labelRowsPerPage={bp.isSmDown ? "Filas:" : "Filas por página:"}
        labelDisplayedRows={function defaultLabelDisplayedRows({
          from,
          to,
          count,
        }) {
          return `${from}–${to} de ${count !== -1 ? count : `mas de ${to}`}`;
        }}
        rowsPerPageOptions={props.tablePagination?.rowsPerPageList}
        component="div"
        count={props.tablePagination?.totalCount}
        rowsPerPage={props.tablePagination?.currentRowsPerPage}
        page={props.tablePagination?.currentPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        backIconButtonProps={{
          disableRipple: true,
          sx: {
            color: props.tablePagination?.color,
            marginRight: "1.5rem",
            padding: "0px",
            ":disabled": {
              color: props.tablePagination?.color,
              opacity: "0.5",
              padding: "0px",
            },
          },
        }}
        nextIconButtonProps={{
          disableRipple: true,
          sx: {
            color: props.tablePagination?.color,
            padding: "0px",

            ":disabled": {
              color: props.tablePagination?.color,
              opacity: "0.5",
              padding: "0px",
            },
          },
        }}
        sx={{
          display: "flex",
          justifyContent: "right",
          height: "15px",
          alignItems: "center",
          overflowY: "hidden",
          ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
            color: props.tablePagination?.color,
          },
          ".MuiTablePagination-displayedRows": {
            color: props.tablePagination?.color,
          },
        }}
        SelectProps={{
          inputProps: { color: props.tablePagination?.color },
          sx: {
            ".MuiInputBase-input": {
              color: props.tablePagination?.color,
            },
            "&:after": {
              borderBottomColor: props.tablePagination?.color,
            },
            "& .MuiSvgIcon-root": {
              color: props.tablePagination?.color,
            },
          },
        }}
      />
    );
  };

  return (
    <TableContainer className={props.containerClassName} component={Paper}>
      <Table size={props.size}>
        <TableHead className={props.headerClassName}>
          <Header />
        </TableHead>
        <TableBody>
          {props.bodyCells.map((row, i) => (
            <Row key={i} row={row} index={i} />
          ))}
          {EmtpyRows()}
        </TableBody>
        <TableFooter>
          <TableRow className={props.rowFooterClassName}>
            <TableCell colSpan={totalColumns}>
              {props.tablePagination !== undefined ? (
                <Pagination />
              ) : (
                <Footer />
              )}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export { TableComponent as Table };
