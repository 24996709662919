import HomeApi from "../../config/axios";
import {
  PrestamoVencidoDetalle,
  LineaPrestamo,
  PrestamoResumen,
  PrestamoAnticipadoDetalle,
  PrestamoConCuotasDetalle,
  LineaPrestamoResumen,
  PrestamoIdentifier,
  PrestamoAndCuotaIdentifier,
} from "../../pages/Prestamos/interfaces/Prestamo";
import {
  BdPrestamoToPrestamoResumen,
  DetalleAnticipadoAdapter,
  DetalleConCuotasAdapter,
  DetalleVencidoAdapter,
  LineaPrestamoResumenAdapter,
} from "./prestamo.adapter";

const PrestamoService = {
  GetAll: (): Promise<PrestamoResumen[]> => {
    return new Promise<PrestamoResumen[]>((resolve, reject) => {
      HomeApi.get(`prestamos`)
        .then((response) => {
          resolve(
            response.data.map((bdPrestamo) =>
              BdPrestamoToPrestamoResumen(bdPrestamo)
            )
          );
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  GetLineasPrestamos: (): Promise<LineaPrestamo[]> => {
    return new Promise<LineaPrestamo[]>((resolve, reject) => {
      HomeApi.get(`prestamos/lineas`, { params: {} })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  GetResumenLineasPrestamos: (): Promise<LineaPrestamoResumen[]> => {
    return new Promise<LineaPrestamoResumen[]>((resolve, reject) => {
      HomeApi.get(`prestamos/lineas/resumen`)
        .then((response) => {
          resolve(
            response.data.map((bdLinea) => LineaPrestamoResumenAdapter(bdLinea))
          );
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  GetDetallePrestamoConCuotas: (
    params: PrestamoIdentifier
  ): Promise<PrestamoConCuotasDetalle> => {
    return new Promise<PrestamoConCuotasDetalle>((resolve, reject) => {
      HomeApi.get(`prestamos/detalles/con-cuotas`, {
        params: {
          NComprobante: params.NroComprobante,
          CodSis: params.LineaId,
        },
      })
        .then((response) => {
          resolve(DetalleConCuotasAdapter(response.data));
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  GetDetallePrestamoAnticipado: (
    params: PrestamoIdentifier
  ): Promise<PrestamoAnticipadoDetalle> => {
    return new Promise<PrestamoAnticipadoDetalle>((resolve, reject) => {
      HomeApi.get(`prestamos/detalles/anticipado`, {
        params: {
          NComprobante: params.NroComprobante,
          CodSis: params.LineaId,
        },
      })
        .then((response) => {
          resolve(DetalleAnticipadoAdapter(response.data));
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  GetDetallePrestamoVencido: (
    params: PrestamoIdentifier
  ): Promise<PrestamoVencidoDetalle> => {
    return new Promise<PrestamoVencidoDetalle>((resolve, reject) => {
      HomeApi.get(`prestamos/detalles/vencido`, {
        params: {
          NComprobante: params.NroComprobante,
          CodSis: params.LineaId,
        },
      })
        .then((response) => {
          resolve(DetalleVencidoAdapter(response.data));
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  PagarCuotaPrestamo: (params: PrestamoAndCuotaIdentifier): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      HomeApi.patch(`prestamos/con-cuotas/pagar`, {
        NComprobante: params.NroComprobante,
        CodSis: params.LineaId,
        NCuota: params.NroCuota,
      })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.message));
    });
  },
};

export default PrestamoService;
