import { useEffect } from "react";
import SessionService from "../services/session.service";
import AuthenticationService from "../services/Login/Authentication.service";

const handleBeforeUnload = () => {
  SessionService.SetClosedTimestamp();
};

const onLoadPage = () => {
  const lastClosedPageTimestamp = SessionService.GetClosedTimestamp();
  const toleranceTimeToMaintainSessionInMs = 30000;
  const toleranceTimeHasPassed =
    Date.now() - toleranceTimeToMaintainSessionInMs > lastClosedPageTimestamp;
  if (toleranceTimeHasPassed) {
    AuthenticationService.Logout();
  }
};

const useLogoutOnClosePage = () => {
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    onLoadPage();
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
};

export default useLogoutOnClosePage;
