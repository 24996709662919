import { IconButton } from "@mui/material";
import { ViewMoreButtonProps } from "./ViewMoreButton.props";
import Icon from "../Icon/Icon";
import colors from "../../assets/_themes-vars.module.scss";
import { IconEnum } from "../Icon/Icon.props";


const ViewMoreButton = (props: ViewMoreButtonProps) => {
  return (
    <div style={{ borderRadius: "15%", backgroundColor: colors.primaryMain }}>
      <IconButton
        onClick={props.onClick}
        disableRipple={true}
        sx={{ color: colors.lightTextPrimary }}
      >
        {Icon({ icon: IconEnum.VIEW_MORE })}
      </IconButton>
    </div>
  );
};

export default ViewMoreButton;
