import ElementConstitucion from "./ElementConstitucion"
import colors from '../../../assets/_themes-vars.module.scss'
import { Grid, Divider, Typography } from '@mui/material'
import { OrdenCertificado, AccionVencimientoConstitucion } from "../../../services/InversionService/inversion.service"
import { Contacto } from "../../../services/ContactoService/contacto.service"
import { DetalleInversionConstitucion } from '../../../services/InversionService/inversion.service'

const SeccionFormulario = (props: {
    selectedOrdenCertificado: OrdenCertificado,
    selectedAccionVencimiento: AccionVencimientoConstitucion,
    selectedTitulares: Contacto[],
    plazoDias: number,
    monto: number,
    detalleSimulacion: DetalleInversionConstitucion
}
) => {

    const { selectedOrdenCertificado, selectedAccionVencimiento, selectedTitulares, plazoDias, monto, detalleSimulacion } = props

    return (
        <Grid container spacing={0}>
            {
                selectedTitulares && selectedTitulares.length
                    ? (
                        <>
                                <Grid item xs={12} marginTop={1}>
                                    <Typography variant="h4" style={{ color: colors.primaryMain, marginBottom: '.5rem' }}>Cotitulares</Typography>
                                    <Divider></Divider>
                                </Grid>
                            {selectedTitulares.map(selectedTitular => (
                            <Grid item xs={12} style={{ color: 'black', paddingTop: '0.25rem' }}>
                                <span>{selectedTitular.NSocio} - {selectedTitular.Documento} - {selectedTitular.NombreSocio}</span>
                            </Grid>
                    ))}
                            </>
                    )
                    : null
            }
        </Grid>
    )
}

export default SeccionFormulario