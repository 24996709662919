import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { useMemo, useState } from "react";
import { SelectorProps } from "./Selector.props";
import colors from "../../assets/_themes-vars.module.scss";
import Icon from "../Icon/Icon";
import { IconEnum } from "../Icon/Icon.props";
import TextField from "../TextField/TextField";

const Selector = (props: SelectorProps) => {
  const remsPerLetter = 0.3034;
  const titleSpaceWidth = props.title.length * remsPerLetter;
  const [searchText, setSearchText] = useState<string>("");
  const displayedOptions = useGetOptions();

  const Searcher = () => {
    return props.searcheable ? (
      <ListSubheader sx={{ pt: "8px", mt: "-8px" }}>
        <TextField
          size="small"
          placeholder="Buscar..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  icon={IconEnum.SEARCH}
                  sx={{ color: colors.primaryMain }}
                />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
    ) : undefined;
  };

  return (
    <FormControl fullWidth sx={props?.sx} className={props?.className}>
      <InputLabel
        id="demo-simple-select-label"
        sx={{ fontSize: "1.2rem", color: colors.primaryMain }}
      >
        {props.title}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Tipo"
        sx={{
          "& .MuiInputLabel-root, fieldset": {
            fontSize: `${titleSpaceWidth}rem`,
          },
        }}
        MenuProps={{ autoFocus: false, style: { maxHeight: "250px" } }}
        value={props.value}
        onChange={(e) => {
          props.setValue(e.target.value);
        }}
        onClose={() => {
          setSearchText("");
        }}
        renderValue={() => props.value}
      >
        {Searcher()}
        {displayedOptions.map((s) => (
          <MenuItem key={s} value={s}>
            {s}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  function useGetOptions() {
    const containsText = (text, searchText) =>
      text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    const displayedOptionsIfIsSearchable = useMemo(
      () => props.list.filter((option) => containsText(option, searchText)),
      [searchText, props.list]
    );
    return props.searcheable ? displayedOptionsIfIsSearchable : props.list;
  }
};

export default Selector;
