import { useCallback, useEffect, useState } from "react";
import { Contacto } from "../../../services/ContactoService/contacto.service";
import ContactoList from "./ContactoList";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import SearchBar from "../../../components/SearchBar/SearchBarComponent";
import { UseContactos } from "./ContactosContext";

const AgendaContacto = () => {
  const [listContactosFiltrados, setListContactosFiltrados] = useState<
    Contacto[]
  >([]);
  const { contactos, isLoadingContactos } = UseContactos();

  useEffect(() => {
    if (contactos?.length !== undefined) setListContactosFiltrados(contactos);
  }, [contactos]);

  const handleOnChange = useCallback(
    (value: string) => {
      setListContactosFiltrados(
        contactos.filter(
          (c) =>
            c.NombreSocio.toLowerCase().includes(value.toLowerCase()) ||
            c.Referencia.toLowerCase().includes(value.toLowerCase()) ||
            c.NSocio.toString().toLowerCase().includes(value.toLowerCase())
        )
      );
    },
    [contactos]
  );

  return (
    <>
      <Typography variant="h3" className="agenda-contactos-title">
        Mi agenda de contactos
      </Typography>
      {isLoadingContactos ? (
        <Loader label="Actualizando contactos" />
      ) : (
        <Box>
          <SearchBar
            placeholder={"Buscar..."}
            sx={{ width: " 100%" }}
            onChangedValue={handleOnChange}
          />
          <ContactoList cuentas={listContactosFiltrados} />
        </Box>
      )}
    </>
  );
};

export default AgendaContacto;
