import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { UseUsuario } from "../../contexts/UsuarioContext";
import Loader from "../Loader/Loader";
import useTyC from "./hooks/useTyC.hook";
import { DateToStringConverter } from "../Formatters/Formatters";
import "./TyCDialog.styles.scss";

export const tycQueryKey = "tyc";
export const tycQueryValue = "open";

const TyCDialog = () => {
  const [open, setOpen] = useState(false);

  const { selectedCuentaUsuario, fechaAceptaTyC } = UseUsuario();
  const { tyc, isLoading, guardarDisabled, acceptTyC } = useTyC();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (selectedCuentaUsuario && !fechaAceptaTyC) {
      searchParams.set(tycQueryKey, tycQueryValue);
      setSearchParams(searchParams);
    }
  }, [selectedCuentaUsuario]);

  useEffect(() => {
    const tycValue = searchParams.get(tycQueryKey);
    if (tycValue === tycQueryValue) {
      setOpen(true);
    }
  }, [searchParams]);

  const handleSave = () => {
    if (selectedCuentaUsuario && !fechaAceptaTyC) {
      acceptTyC();
    }
  };

  const handleClose = () => {
    if (fechaAceptaTyC) {
      setOpen(false);
      searchParams.delete(tycQueryKey);
      setSearchParams(searchParams);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
      sx={{ zIndex: 3500 }}
    >
      <DialogTitle>
        <Typography variant="h1">Términos y Condiciones</Typography>
      </DialogTitle>
      {isLoading && (
        <div className="tyc-dialog-loader">
          <Loader label={"Cargando..."} />
        </div>
      )}
      {!isLoading && (
        <>
          <DialogContent className="tyc-dialog-content">
            <DialogContentText tabIndex={-1}>
              <p
                dangerouslySetInnerHTML={{
                  __html: tyc,
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!fechaAceptaTyC ? (
              <ToAcceptTyCsActions
                isGuardarDisabled={guardarDisabled}
                onClick={handleSave}
              />
            ) : (
              <AcceptedTyCsActions
                fechaAcepta={fechaAceptaTyC}
                onClick={handleClose}
              />
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default TyCDialog;

const ToAcceptTyCsActions = (props: {
  isGuardarDisabled: boolean;
  onClick: () => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="tyc-dialog-actions">
      <FormControlLabel
        control={
          <Checkbox
            value={isChecked}
            onChange={(_, checked) => setIsChecked(checked)}
          />
        }
        label="He leído y acepto los términos y condiciones"
      />
      <Button
        disabled={!isChecked || props.isGuardarDisabled}
        onClick={props.onClick}
      >
        Guardar
      </Button>
    </div>
  );
};

const AcceptedTyCsActions = (props: {
  fechaAcepta: Date;
  onClick: () => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="tyc-dialog-actions">
      <Typography variant="body1">
        Términos y condiciones leídos y aceptados el día:{" "}
        <strong>{`${DateToStringConverter(props.fechaAcepta)}`}</strong>{" "}
      </Typography>
      <Button onClick={props.onClick}>Cerrar</Button>
    </div>
  );
};
