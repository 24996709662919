import { Typography } from "@mui/material";

export const EmptyMovementsTable = () => {
  return (
    <div style={{ width: "100%", height: "354px" }}>
      <Typography
        variant="body2"
        sx={{ color: "text.primary", marginTop: "1rem" }}
        align="center"
      >
        Sin movimientos
      </Typography>
    </div>
  );
};
