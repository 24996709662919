export interface Notification {
  Id: number;
  Title: string;
  Timestamp: Date;
  ReadTimestamp: Date;
  NroSocio: number;
  NroComprobante: number;
  Capital?: number;
  Mensaje: string;
  IsRead: boolean;
}

export interface GetNotificationsFilters {
  NroSocio: number;
  NroFilial: number;
  Page: number;
  PageSize: number;
}

export const defaultNotificationFilters: GetNotificationsFilters = {
  NroSocio: undefined,
  NroFilial: undefined,
  Page: 0,
  PageSize: 5,
};
