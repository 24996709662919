import React, { useState, useContext, ReactElement, useMemo } from "react";
import { Contacto } from "../services/ContactoService/contacto.service";
import { TransferenciaActionType } from "../pages/Transferencias/enums/transferencia-actions.enum";

export interface TransferenciaAction {
  isOpenDrawer: boolean;
  accion: TransferenciaActionType;
  contacto: Contacto;
}

interface TransferenciaContextState {
  transferenciaAction: TransferenciaAction;
  setTransferenciaAction: React.Dispatch<
    React.SetStateAction<TransferenciaAction>
  >;
}

const TransferenciaActionContext =
  React.createContext<TransferenciaContextState>({
    transferenciaAction: {
      accion: undefined,
      contacto: undefined,
      isOpenDrawer: false,
    },
    setTransferenciaAction: () => {},
  });

export function useTransferenciaActionContext() {
  return useContext(TransferenciaActionContext);
}

export function TransferenciaActionProvider(props: Readonly<{ children: ReactElement }>) {
  const [transferenciaAction, setTransferenciaAction] =
    useState<TransferenciaAction>(undefined);

  const transferenciaMemoized = useMemo(
    () => ({ transferenciaAction, setTransferenciaAction }),
    [transferenciaAction, setTransferenciaAction]
  );

  return (
    <TransferenciaActionContext.Provider value={transferenciaMemoized}>
      {props.children}
    </TransferenciaActionContext.Provider>
  );
}
