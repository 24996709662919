import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { UseUsuario } from "../../../contexts/UsuarioContext";
import HomeService from "../../../services/HomeService/Home.Service";
import { defaultErrorSnackbar } from "../../../utils/snackBarConfigs.util";
import SessionService from "../../../services/session.service";

interface UseTyCResult {
  tyc: string;
  isLoading: boolean;
  guardarDisabled: boolean;
  acceptTyC: () => void;
}

const useTyC = (): UseTyCResult => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [terminosYCondiciones, setTerminosYCondiciones] = useState<string>();
  const [guardarDisabled, setGuardarDisabled] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const { selectedCuentaUsuario, refreshAccountsData } = UseUsuario();

  const handleErrorWithTyCs = () => {
    setTerminosYCondiciones(
      "Hubo un problema al obtener los términos y condiciones, vuelva a intentarlo más tarde."
    );
    enqueueSnackbar(
      "Hubo un problema al cargar los términos y condiciones",
      defaultErrorSnackbar
    );
  };

  useEffect(() => {
    if (selectedCuentaUsuario?.NroSocio) {
      setIsLoading(true);
      HomeService.GetTerminosYCondiciones()
        .then((tyc) => {
          setTerminosYCondiciones(tyc);
          setGuardarDisabled(false);
        })
        .catch(handleErrorWithTyCs)
        .finally(() => setIsLoading(false));
    }
  }, [selectedCuentaUsuario?.NroSocio]);

  const acceptTyC = () => {
    if (selectedCuentaUsuario && !guardarDisabled) {
      setIsLoading(true);
      HomeService.AcceptTerminosYCondiciones()
        .then((fechaAceptaTyC) => {
          SessionService.SetLoginInformation({
            ...SessionService.GetLoginInformation(),
            fechaAceptaTyC: fechaAceptaTyC,
          });
          refreshAccountsData();
        })
        .catch(handleErrorWithTyCs)
        .finally(() => setIsLoading(false));
    }
  };

  return {
    tyc: terminosYCondiciones,
    isLoading: isLoading,
    guardarDisabled,
    acceptTyC,
  };
};

export default useTyC;
