import Divider100P from "../../../components/Dividers/Divider100P";
import {
  DrawerItemsForm,
  DrawerTextDataItem,
} from "../../../components/DrawerForm/DrawerFormAndItems";
import {
  DateToStringConverter,
  NumberToMoneyConverter,
} from "../../../components/Formatters/Formatters";
import AllPageLoader from "../../../components/Loader/AllPageLoader";
import useDetallePrestamoAnticipado from "../hooks/useDetallePrestamoAnticipado.hook";
import { PrestamoIdentifier } from "../interfaces/Prestamo";
import TablaDetalleCheques from "./Tables/TablaDetalleCheques/TablaDetalleCheques";

const PrestamoTipoAnticipado = (props: PrestamoIdentifier) => {
  const { isLoading, detallePrestamo } = useDetallePrestamoAnticipado(props);

  return (
    <>
      {isLoading && <AllPageLoader label={"Cargando detalle"} />}
      {!isLoading && (
        <>
          <DrawerItemsForm>
            <DrawerTextDataItem
              label={"Comprobante"}
              value={detallePrestamo?.NComprobante.toString()}
            />
            <DrawerTextDataItem
              label={"Fecha Imposición"}
              value={DateToStringConverter(detallePrestamo?.FecImp)}
            />
            <DrawerTextDataItem
              label={"Importe"}
              value={NumberToMoneyConverter(detallePrestamo?.Importe)}
            />
            <DrawerTextDataItem
              label={"Importe Adeudado"}
              value={NumberToMoneyConverter(detallePrestamo?.ImporteAdeudado)}
            />
            <DrawerTextDataItem
              label={"Destino"}
              value={detallePrestamo?.Destino}
            />
          </DrawerItemsForm>
          <Divider100P />
          <TablaDetalleCheques cheques={detallePrestamo.Cheques} />
        </>
      )}
    </>
  );
};

export default PrestamoTipoAnticipado;
