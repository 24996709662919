import React from "react";
import SwipeableDrawerComponent from "../SwipeableDrawer/SwipeableDrawer";
import TransferenciaDrawerContainer from "./TransferenciaDrawerContainer";
import { useTransferenciaActionContext } from "../../../contexts/TransferenciaActionContext";

const TransferenciaDrawer = (props: {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { openDrawer, setOpenDrawer } = props;
  const { transferenciaAction: transferenciaActionData } =
    useTransferenciaActionContext();

  return (
    <SwipeableDrawerComponent
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      anchor="right"
      contentDrawer={
        <TransferenciaDrawerContainer setOpenDrawer={setOpenDrawer} />
      }
      title={transferenciaActionData?.accion}
    />
  );
};

export default TransferenciaDrawer;
