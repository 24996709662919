import { useEffect, useState } from "react";

import InversionService, {
  ResumenInversiones,
} from "../../../services/InversionService/inversion.service";
import { LineaPrestamoResumen } from "../../Prestamos/interfaces/Prestamo";
import { useSnackbar } from "notistack";
import { UseUsuario } from "../../../contexts/UsuarioContext";
import { defaultErrorSnackbar } from "../../../utils/snackBarConfigs.util";
import {
  Cuenta,
  MovementAllData,
} from "../../../services/CuentaService/cuenta.service.interfaces";
import CuentaService from "../../../services/CuentaService/cuenta.service";
import { HomeFiltersToGetMovements } from "../../../services/CuentaService/cuenta.service.adapter";
import PrestamoService from "../../../services/PrestamoService/prestamo.service";

const useHome = () => {
  const [cuenta, setCuenta] = useState<Cuenta>(undefined);
  const [lineasPrestamo, setLineasPrestamo] = useState<LineaPrestamoResumen[]>(
    []
  );
  const [inversionesResumen, setInversionesResumen] =
    useState<ResumenInversiones>(undefined);
  const [lastMovements, setLastMovements] = useState<MovementAllData[]>([]);
  const [isLoadingCuenta, setIsLoadingCuenta] = useState(true);
  const [isLoadingPrestamos, setIsLoadingPrestamos] = useState(true);
  const [isLoadingInversiones, setIsLoadingInversiones] = useState(true);
  const [isLoadingMovimientos, setIsLoadingMovimientos] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const { selectedCuentaUsuario } = UseUsuario();

  const getDataCuenta = () => {
    setIsLoadingCuenta(true);
    CuentaService.GetAccountData()
      .then((result) => {
        setCuenta(result);
      })
      .catch(() =>
        enqueueSnackbar(
          `Hubo un error al obtener la información de la cuenta`,
          defaultErrorSnackbar
        )
      )
      .finally(() => setIsLoadingCuenta(false));
  };

  const getPrestamos = () => {
    setIsLoadingPrestamos(true);

    PrestamoService.GetResumenLineasPrestamos()
      .then((result) => setLineasPrestamo(result))
      .catch(() =>
        enqueueSnackbar(
          `Hubo un error al obtener los prestamos vigentes`,
          defaultErrorSnackbar
        )
      )
      .finally(() => {
        setIsLoadingPrestamos(false);
      });
  };

  const getInversiones = () => {
    setIsLoadingInversiones(true);
    InversionService.GetResumenInversiones()
      .then((result) => setInversionesResumen(result))
      .catch(() =>
        enqueueSnackbar(
          `Hubo un error al obtener las inversiones`,
          defaultErrorSnackbar
        )
      )
      .finally(() => {
        setIsLoadingInversiones(false);
      });
  };

  const getUltimosMovimientosCuenta = () => {
    const maxMovements = 7;
    setIsLoadingMovimientos(true);
    CuentaService.GetMovements(HomeFiltersToGetMovements(maxMovements))
      .then((result) => setLastMovements(result.Results))
      .catch(() =>
        enqueueSnackbar(
          `Hubo un error al obtener los últimos movimientos`,
          defaultErrorSnackbar
        )
      )
      .finally(() => {
        setIsLoadingMovimientos(false);
      });
  };

  useEffect(() => {
    if (selectedCuentaUsuario) {
      getDataCuenta();
      getPrestamos();
      getInversiones();
      getUltimosMovimientosCuenta();
    }
  }, [selectedCuentaUsuario]);

  return {
    cuenta,
    lineasPrestamo,
    inversionesResumen,
    lastMovements,
    isLoadingCuenta,
    isLoadingPrestamos,
    isLoadingInversiones,
    isLoadingMovimientos,
  };
};

export default useHome;
