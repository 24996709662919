import Loader from "../../../../components/Loader/Loader";

const TransferLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "90vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader label="Realizando transferencia" />
    </div>
  );
};

export default TransferLoader;
