import { Grid } from "@mui/material";
import TransferenciaIndividual from "../../../pages/Transferencias/PageComponents/TransferenciaIndividual/TransferenciaIndividual";
import NuevoContacto from "../../../pages/Transferencias/PageComponents/NuevoContacto";
import EditarContacto from "../../../pages/Transferencias/PageComponents/EditarContacto";
import { useTransferenciaActionContext } from "../../../contexts/TransferenciaActionContext";
import { TransferenciaActionType } from "../../../pages/Transferencias/enums/transferencia-actions.enum";
import { UseContactos } from "../../../pages/Transferencias/PageComponents/ContactosContext";

const TransferenciaDrawerContainer = (props: {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { setOpenDrawer } = props;

  const { transferenciaAction: transferenciaActionData } =
    useTransferenciaActionContext();

  const { contactos, refreshContactos } = UseContactos();

  const sharedProps = {
    setOpenDrawer: setOpenDrawer,
  };

  return (
    <Grid container sx={{ width: "min(500px, 100vw)" }}>
      {transferenciaActionData?.accion ===
        TransferenciaActionType.TRANSFERENCIA_INDIVIDUAL && (
        <TransferenciaIndividual
          {...sharedProps}
          destinatario={transferenciaActionData?.contacto}
        />
      )}
      {transferenciaActionData?.accion ===
        TransferenciaActionType.NUEVO_CONTACTO && (
        <NuevoContacto
          {...sharedProps}
          contactos={contactos}
          refreshContactos={refreshContactos}
        />
      )}
      {transferenciaActionData?.accion ===
        TransferenciaActionType.EDITAR_CONTACTO && (
        <EditarContacto
          {...sharedProps}
          refreshContactos={refreshContactos}
          destinatario={transferenciaActionData?.contacto}
        />
      )}
    </Grid>
  );
};

export default TransferenciaDrawerContainer;
