import PrestamoService from "../../../services/PrestamoService/prestamo.service";
import useGenericDetallePrestamo from "./useGenericDetallePrestamo.hook";
import { PrestamoAnticipadoDetalle, PrestamoIdentifier } from "../interfaces/Prestamo";

interface UseDetallePrestamoVencidoResult {
  isLoading: boolean;
  detallePrestamo: PrestamoAnticipadoDetalle;
}

const useDetallePrestamoAnticipado = (
  props: PrestamoIdentifier
): UseDetallePrestamoVencidoResult => {
  const { isLoading, detallePrestamo } = useGenericDetallePrestamo({
    ...props,
    serviceToCall: PrestamoService.GetDetallePrestamoAnticipado,
  });

  return {
    isLoading,
    detallePrestamo: detallePrestamo as PrestamoAnticipadoDetalle,
  };
};

export default useDetallePrestamoAnticipado;
