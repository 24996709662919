import { createContext, useState } from "react";
import useBreakpoints from "../../../../hooks/breakpoints.hook";
import DesktopView from "../DesktopView/DesktopView";
import MobileView from "../MobileView/MobileView";
import { MAX_VALUE } from "../Slider/TransformValue";
import { FiltersSectionProps } from "./FiltersSection.props";
import { DefaultFiltersWithNulls, DefaultFiltersWithoutNulls } from "../Filters/FilterUtils";
import { ICuentaFilters } from "../../../../services/CuentaService/cuenta.service.interfaces";

export const FiltersContext = createContext<
  [ICuentaFilters, React.Dispatch<React.SetStateAction<ICuentaFilters>>]
>([undefined, undefined]);

const FiltersSection: React.FC<FiltersSectionProps> = ({ applyFilters, cuenta, ctaRemunerada }) => {

  const [filters, setFilters] = useState<ICuentaFilters>(
    DefaultFiltersWithNulls
  );
  const [openFilters, setOpenFilters] = useState(false);
  const hasFilters =
    filters.dateFrom !== DefaultFiltersWithNulls.dateFrom ||
    filters.dateTo !== DefaultFiltersWithNulls.dateTo ||
    filters.tipoImputacion !== DefaultFiltersWithNulls.tipoImputacion ||
    filters.tipoOrigen !== DefaultFiltersWithNulls.tipoOrigen ||
    filters.min !== DefaultFiltersWithNulls.min ||
    filters.max !== DefaultFiltersWithNulls.max;

  const clearAll = () => {
    setFilters(DefaultFiltersWithNulls);
    applyFilters(DefaultFiltersWithoutNulls);
  };

  const applyFiltersHandler = () => {
    const filtersAdjustedForProcessing = () => {
      return {
        ...filters,
        dateFrom:
          filters.dateFrom === null
            ? DefaultFiltersWithoutNulls.dateFrom
            : filters.dateFrom,
        dateTo:
          filters.dateTo === null
            ? DefaultFiltersWithoutNulls.dateTo
            : filters.dateTo,
        max:
          filters.max === MAX_VALUE
            ? DefaultFiltersWithoutNulls.max
            : filters.max,
      };
    };
    applyFilters(filtersAdjustedForProcessing());
  };

  const sectionParams = {
    openFilters: openFilters,
    setOpenFilters: setOpenFilters,
    cuenta: cuenta,
    filters: filters,
    hasFilters: hasFilters,
    clearAllHandler: clearAll,
    applyFiltersButtonHandler: applyFiltersHandler,
  };

  const bp = useBreakpoints();

  return (
    <div className="cuenta-especifica-filters-section-div ">
      <FiltersContext.Provider value={[filters, setFilters]}>
        {bp.isSmUp ? (
          <DesktopView {...sectionParams} ctaPlus={ctaRemunerada} />
        ) : (
          <MobileView {...sectionParams} ctaPlus={ctaRemunerada} />
        )}
      </FiltersContext.Provider>
    </div>
  );
};

export default FiltersSection;
