import { useMemo, useState } from "react";
import "../Home/HomePage.scss";
import "./PrestamosPage.scss";
import { LineaPrestamo, PrestamoResumen } from "./interfaces/Prestamo";
import { Typography } from "@mui/material";
import PrestamosHeader, {
  DefaultSelectedLineaPrestamos,
} from "./PageComponents/PrestamosHeader";
import usePrestamos from "./hooks/usePrestamos.hook";
import AllPageLoader from "../../components/Loader/AllPageLoader";
import LineaPrestamoComponent from "./PageComponents/LineaPrestamo";
import { DetallePrestamoProvider } from "../../contexts/DetallePrestamoContext";
import DetallePrestamoDrawer from "../../components/Drawer/DetallePrestamoDrawer/DetallePrestamoDrawer";

const PrestamosPage = () => {
  const { lineasPrestamos, prestamos, isLoadingPrestamos } = usePrestamos();
  const [selectedLineaPrestamo, setSelectedLineaPrestamo] =
    useState<LineaPrestamo>(DefaultSelectedLineaPrestamos);

  const hasPrestamos = prestamos.length > 0;
  const hasFilters = selectedLineaPrestamo !== DefaultSelectedLineaPrestamos;
  const showEmptyPrestamosError = !isLoadingPrestamos && !hasPrestamos;
  const showLineas = hasPrestamos && !isLoadingPrestamos;

  const lineasToUse = useMemo(
    () =>
      hasFilters
        ? lineasPrestamos.filter((lp) => lp.Id === selectedLineaPrestamo.Id)
        : lineasPrestamos,
    [lineasPrestamos, selectedLineaPrestamo]
  );

  return (
    <div className="generic-main-div">
      <PrestamosHeader
        lineasPrestamos={lineasPrestamos}
        selectedLineaPrestamo={selectedLineaPrestamo}
        setSelectedLineaPrestamo={setSelectedLineaPrestamo}
      />
      {isLoadingPrestamos && <AllPageLoader label={"Cargando préstamos"} />}
      {showEmptyPrestamosError && <EmptyPrestamosError />}
      <DetallePrestamoProvider>
        <>
          {showLineas &&
            lineasToUse.map((lp) => (
              <LineaPrestamoComponent
                key={`PP-${lp.Id}`}
                lineaName={lp.Nombre}
                prestamos={filterPrestamosByLinea(prestamos, lp.Id)}
                showEmptyMessage={hasFilters}
              />
            ))}
          <DetallePrestamoDrawer />
        </>
      </DetallePrestamoProvider>
    </div>
  );
};

export default PrestamosPage;

function filterPrestamosByLinea(
  allPrestamos: PrestamoResumen[],
  lineaId: number
): PrestamoResumen[] {
  return allPrestamos.filter((p) => p.CodSis === lineaId);
}

const EmptyPrestamosError = () => {
  return (
    <Typography variant="h4" className="prestamos-error-legend">
      No posee préstamos vigentes
    </Typography>
  );
};
