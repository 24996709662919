import { TextField as MuiTexField, TextFieldProps } from "@mui/material";

const TextField = (props: TextFieldProps) => {
  return (
    <MuiTexField
      {...props}
      inputProps={{ ...props?.inputProps, onWheel: (e) => e.currentTarget.blur() }}
    />
  );
};

export default TextField;
