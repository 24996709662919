import { StyledEngineProvider } from "@mui/material";
import { useRoutes } from "react-router-dom";
import "./App.css";
import routes from "./routes/routes";
import { UsuarioProvider } from "./contexts/UsuarioContext";
import useLogoutOnClosePage from "./hooks/useLogoutOnClosePage.hook";
import SystemDateProvider from "./contexts/SystemDateContext";
import TyCDialog from "./components/TyCDialog/TyCDialog";

function App() {
  useLogoutOnClosePage();
  return (
    <UsuarioProvider>
      <SystemDateProvider>
        <StyledEngineProvider injectFirst>
          {useRoutes(routes)}
          <TyCDialog />
        </StyledEngineProvider>
      </SystemDateProvider>
    </UsuarioProvider>
  );
}

export default App;
