import { useState } from "react";
import TransferenciaService from "../../../../services/TransferenciaService/transferencia.service";
import { MakeTransferProps } from "../../../../services/TransferenciaService/transferencia.service.interfaces";
import {
  defaultSuccessSnackbar,
  homeDisabledSnackbar,
} from "../../../../utils/snackBarConfigs.util";
import { useSnackbar } from "notistack";
import { homeProbablyDisabled } from "../../../../utils/genericErrorMessages";

interface UseMakeTransferProps extends MakeTransferProps {
  afterTransfer: (comprobante: number) => void;
}

interface UseMakeTransferResult {
  isMakingTransfer: boolean;
  makeTransfer: () => void;
}

const useMakeTransfer = (
  props: UseMakeTransferProps
): UseMakeTransferResult => {
  const [isMakingTransfer, setIsMakingTransfer] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const makeTransfer = () => {
    setIsMakingTransfer(true);
    if (!props.ctaPlus) {
      TransferenciaService.MakeTransfer({
        NSocioDestino: props.NSocioDestino,
        NFilialDestino: props.NFilialDestino,
        Monto: props.Monto,
        Referencia: props.Referencia,
      })
        .then(props.afterTransfer)
        .catch(() => {
          props.afterTransfer(undefined);
          enqueueSnackbar(homeProbablyDisabled, homeDisabledSnackbar);
        })
        .finally(() => {
          setIsMakingTransfer(false);
        });
    } else {
      TransferenciaService.MakeTransferPlus({
        NSocioDestino: props.NSocioDestino,
        NFilialDestino: props.NFilialDestino,
        Monto: props.Monto,
      })
        .then(() =>
          enqueueSnackbar(
            `Transferencia realizada con éxito`,
            defaultSuccessSnackbar
          )
        )
        .catch(() =>
          enqueueSnackbar(homeProbablyDisabled, homeDisabledSnackbar)
        )
        .finally(() => {
          props.afterTransfer(undefined);
          setIsMakingTransfer(false);
        });
    }
  };

  return { isMakingTransfer, makeTransfer };
};

export default useMakeTransfer;
