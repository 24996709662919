import HomeApi from "../../config/axios";
import downloadFile from "../../utils/downloadFile.util";
import getFilenameFromResponse from "../../utils/getFilenameFromResponse.util";
import { GetExtractoCuenta } from "../CuentaService/cuenta.service.interfaces";
import { GetComprobanteProps } from "./comprobantes.service.interfaces";

const ComprobantesService = {
  DownloadComprobante: (props: GetComprobanteProps) => {
    return new Promise<void>((resolve, reject) => {
      HomeApi.get<void>("ComprobantesPdf", {
        responseType: "blob",
        params: props,
      })
        .then((result: any) => {
          downloadFile(
            getFilenameFromResponse(result),
            new Blob([result.data])
          );
          resolve();
        })
        .catch((error) => reject(error.message));
    });
  },
  DownloadExtracto: (props: GetExtractoCuenta) => {
    return new Promise<void>((resolve, reject) => {
      HomeApi.get<void>("ComprobantesPdf/extracto", {
        responseType: "blob",
        params: props,
      })
        .then((result: any) => {
          downloadFile(
            getFilenameFromResponse(result),
            new Blob([result.data])
          );
          resolve();
        })
        .catch((error) => reject(error.message));
    });
  },
};

export default ComprobantesService;
