import { useState, useEffect } from "react";
import ".././LoginPage.scss";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import AuthenticationService from "../../../services/Login/Authentication.service";
import { defaultMsgError } from "../../../config/axios";
import LoginHeader from "../LoginHeader";
import Loader from "../../../components/Loader/Loader";
import { useSearchParams } from "react-router-dom";

const successfulTitle =
  "Clave reestablecida con éxito. Revise su correo para obtener su clave de un solo uso.";
const successfulSubtitle =
  "Al utilizarla, deberá configurar una nueva contraseña.";

const expiredTitle = "Código expirado.";
const expiredSubtitle =
  "El código ya ha sido utilizado o ha vencido. Solicite un código nuevo.";

const errorTitle = "Hubo un problema al reestablecer su contraseña.";
const errorSubtitle =
  "Contáctese con el equipo de mutual para más información.";

const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(successfulTitle);
  const [subtitle, setSubtitle] = useState(successfulSubtitle);

  const [searchParams] = useSearchParams();

  const document = searchParams.get("documento");
  const user = searchParams.get("usuario");
  const code = searchParams.get("codigo");

  useEffect(() => {
    if (searchParams) {
      validateCode();
    }
  }, [searchParams]);

  const validateCode = async () => {
    AuthenticationService.ResetMyPassword(document, user, code)
      .then()
      .catch((message: string) => {
        if (message !== defaultMsgError) {
          setTitle(expiredTitle);
          setSubtitle(expiredSubtitle);
        } else {
          setTitle(errorTitle);
          setSubtitle(errorSubtitle);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="login">
      <div className="login__container" style={{ width: "auto" }}>
        <LoginHeader></LoginHeader>
        <Divider></Divider>
        <Box
          component="form"
          sx={{
            display: "flex",
          }}
        >
          <div className="login__form">
            {loading && (
              <Loader label={"Validando código de restablecimiento"} />
            )}
            {!loading && (
              <>
                <h2>{title}</h2>
                <Typography
                  variant="h3"
                  sx={{ color: "text.primary", mb: "15px" }}
                >
                  {subtitle}
                </Typography>
                <a href="/login">Volver a página de ingreso</a>
              </>
            )}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
