import "./MovementsTable.scss";
import { MovementsTableProps } from "./MovementsTable.props";
import { TablePaginationProps } from "../../../../components/Tables/TableWrapper/Table.props";
import { Table } from "../../../../components/Tables/TableWrapper/Table";
import DefaultArrowCellStyle from "../../../../components/Tables/TableWrapper/DefaultArrowCellStyle";
import { Typography } from "@mui/material";
import colors from "../../../../assets/_themes-vars.module.scss";
import useBreakpoints from "../../../../hooks/breakpoints.hook";
import HiddenContent from "./HiddenContent";
import { getHeaderCells } from "./MovementsTableHeader";
import { getBodyCells } from "./MovementsTableBodyCells";
import { useMemo } from "react";
import { MAX_MONTHS_TO_PAST } from "../Filters/FilterUtils";

const EmptyTableMessage = () => {

  return (
    <div style={{ width: "100%" }}>
      <Typography
        variant="body2"
        sx={{ color: colors.primaryMain, fontWeight: "light" }}
        align="center"
      >
        No existen movimientos que coincidan con los parámetros actuales.
        <br />
        Rango máximo de búsqueda: {MAX_MONTHS_TO_PAST} meses.
      </Typography>
    </div>
  );
};

const MovementsTable = (props: MovementsTableProps) => {
  const bp = useBreakpoints();

  const headerCells = useMemo(() => getHeaderCells(bp, props.ctaRemunerada), [bp]);
  const bodyCells = useMemo(
    () => getBodyCells(props.movements, bp, props.ctaRemunerada),
    [bp, props?.movements]
  );

  const TablePagination: TablePaginationProps = {
    rowsPerPageList: props.paginationConfig.pageSizeOptions,
    totalCount: props.totalMovements,
    currentRowsPerPage: props.paginationConfig.pageSize,
    currentPage: props.paginationConfig.page,
    onChange: props.onPageChange,
    color: colors.lightTextSecondary,
  };

  return (
    <Table
      containerClassName="last-movements-cuenta-especifica-container"
      headerClassName="last-movements-cuenta-especifica-header"
      rowClassName="last-movements-cuenta-especifica-row"
      rowFooterClassName="last-movements-cuenta-especifica-footer"
      emptyRowClassName="last-movements-cuenta-especifica-empty-row"
      tablePagination={TablePagination}
      hiddenContent={bp.isXlDown ? HiddenContent(props.movements) : undefined}
      hiddenArrowStyle={bp.isXlDown ? DefaultArrowCellStyle() : undefined}
      emtpyTableContent={<EmptyTableMessage />}
      headerCells={headerCells}
      bodyCells={bodyCells}
      size="small"
    />
  );
};

export default MovementsTable;
