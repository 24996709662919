import HomeApi from "../../config/axios";
import { BdMovementToMovementAllData } from "./cuenta.service.adapter";
import {
  Cuenta,
  GetMovementProps,
  MovementAllData,
  PaginatedResult,
  InteresesCtaRemunerada,
  GetInteresesProps,
} from "./cuenta.service.interfaces";

const CuentaService = {
  GetAccountData: (): Promise<Cuenta> => {
    return new Promise<Cuenta>((resolve, reject) => {
      HomeApi.get<Cuenta>(`Cuenta`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error.message));
    });
  },
  GetMovements: (
    props: GetMovementProps
  ): Promise<PaginatedResult<MovementAllData>> => {
    return new Promise<PaginatedResult<MovementAllData>>((resolve, reject) => {
      HomeApi.get<PaginatedResult<MovementAllData>>(
        `Cuenta/Movimientos`,
        {
          params: props,
        }
      )
        .then((response) => {
          resolve({
            ...response.data,
            Results: response.data.Results.map((bdMov) =>
              BdMovementToMovementAllData(bdMov)
            ),
          });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  GetMovementsPlus: (
    props: GetMovementProps
  ): Promise<PaginatedResult<MovementAllData>> => {
    return new Promise<PaginatedResult<MovementAllData>>((resolve, reject) => {
      HomeApi.get<PaginatedResult<MovementAllData>>(
        `Cuenta/Remunerada/Movimientos`,
        {
          params: props,
        }
      )
        .then((response) => {
          resolve({
            ...response.data,
            Results: response.data.Results.map((bdMov) =>
              BdMovementToMovementAllData(bdMov)
            ),
          });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  GetInteresesPlus: (
    props: GetInteresesProps
  ): Promise<InteresesCtaRemunerada[]> => {
    return new Promise<InteresesCtaRemunerada[]>((resolve, reject) => {
      HomeApi.get<InteresesCtaRemunerada[]>(
        `Cuenta/Remunerada/Intereses`,
        {
          params: props,
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
};

export default CuentaService;
