import {
  NumberToMoneyConverter,
  StringToNumber,
} from "../../../../components/Formatters/Formatters";
import { UsuarioCuenta } from "../../../../contexts/UsuarioContext";
import {
  DrawerItemsForm,
  DrawerTextDataItem,
} from "../../../../components/DrawerForm/DrawerFormAndItems";

interface CuentaSeleccionadaInfoSectionProps {
  selectedCuentaUsuario: UsuarioCuenta;
  ctaRemunerada?: boolean;
}

const CuentaSeleccionadaInfoSection = ({
  selectedCuentaUsuario,
  ctaRemunerada
}: CuentaSeleccionadaInfoSectionProps) => {
  const formatedFilial = selectedCuentaUsuario?.Filial.toUpperCase()
    .replace("FILIAL", "")
    .trim();

  const createSection = () => {
    if (!ctaRemunerada) {
      return (
        <DrawerItemsForm>
          <DrawerTextDataItem
            label={"Tipo de Cuenta"}
            value={selectedCuentaUsuario?.DescripcionTipoCuenta}
          />
          <DrawerTextDataItem label={"Filial"} value={formatedFilial} />
          <DrawerTextDataItem
            label={"Moneda"}
            value={selectedCuentaUsuario?.Moneda}
          />
          <DrawerTextDataItem
            label={"Cuenta Origen"}
            value={`${selectedCuentaUsuario?.NroSocio} - ${selectedCuentaUsuario?.NombreSocio}`}
          />
          <DrawerTextDataItem
            label={"Saldo"}
            value={NumberToMoneyConverter(selectedCuentaUsuario?.Saldo)}
          />
        </DrawerItemsForm>
      )
    } else {
      return (
        <DrawerItemsForm>
          <DrawerTextDataItem
            label={"Cuenta"}
            value={selectedCuentaUsuario?.NroSocio.toString()}
          />
          <DrawerTextDataItem label={"Filial"} value={formatedFilial} />
          <DrawerTextDataItem
            label={"Moneda"}
            value={selectedCuentaUsuario?.Moneda}
          />
          <DrawerTextDataItem
            label={"Cuenta Origen"}
            value={`Caja de Ahorro Mutual Plus`}
          />
          <DrawerTextDataItem
            label={"Cuenta Destino"}
            value={`Caja de Ahorro`}
          />
          <DrawerTextDataItem
            label={"Saldo"}
            value={NumberToMoneyConverter(selectedCuentaUsuario?.SaldoCuentaRemunerada)}
          />
        </DrawerItemsForm>
      )
    }
  };

  return (
    <>
      {createSection()}
    </>
  );
};

export default CuentaSeleccionadaInfoSection;
