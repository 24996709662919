import {
  Inversion,
  DetalleInversion,
} from "../../../services/InversionService/inversion.service";
import { Grid } from "@mui/material";

import "./InversionDrawerContainer.scss";
import InversionDrawerList from "./InversionDrawerList";

const InversionDrawerContainer = (props: {
  inversion: Inversion;
  detalle: DetalleInversion;
}) => {
  const { detalle, inversion } = props;

  return (
    <Grid container>
      <Grid item sx={{ width: "100%", margin: "1rem" }}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12}>
            <InversionDrawerList
              nroCertificado={inversion.NroCertificado}
              fechaCertificado={inversion.FechaCertificado}
              tasaMensual={inversion.Tasa}
              tasaAnual={inversion.TasaAnual}
              fechaVencimiento={inversion.FechaVencimiento}
              capital={detalle?.Capital}
              interes={detalle?.Intereses}
              gastosAdministrativos={detalle?.GastoAdministrativo}
              sellado={detalle?.GastoSellado}
              totalACobrar={inversion.Total}
              plazo={detalle?.Plazo}
              orden={inversion.Orden}
              accionVto={detalle?.AccionVto}
              datosTitular={detalle?.Titular}
              datosCotitular1={detalle?.Cotitular1}
              datosCotitular2={detalle?.Cotitular2}
              nroFilial={inversion.FilialId}
              nroSocio={inversion.SocioId}
              tipoMovimiento={detalle?.TipoMovimiento}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InversionDrawerContainer;
