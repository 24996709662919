import * as jose from "jose";
import AuthenticationService from "./Login/Authentication.service";

const tokenKey = "token";
const firstLoginKey = "isFirstLogin";
const isToChangePasswordKey = "isToChangePassword";
const closedTimestampKey = "closedTimestamp";
const userInformationKey = "userInformation";
const CURRENT_FILIAL_STORAGE_KEY = "CURRENT_FILIAL";
const CURRENT_SOCIO_STORAGE_KEY = "CURRENT_SOCIO";
const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEAuJLcHcoybuWPVkQVNiZ5
xtXcfESlXbFBnbXq8Iejmv72pdCx2OaVur1mraAW467uT6nz1D1TwYwK7urV/I7o
sb/pCvkMydhBfX4vdNNBpYEtV9qBsBOpRa34hlmBbg9KcYsPDxU2p3qoyDOBcHa7
ev8pBAkcTY6Qa3y1zw4V+GlJm5uJuMNrqm5/S8a/rBINwrxc7W9ei7RttImjx4Hp
NTqZB9lkjqQlbK4kegtC+HEC1Upq8ud9X0jStkAsSjhuJrbUIXlqnwjO9lnFDCDZ
SOaA0TfIqMGOx8rATNLd0IBW2aB0O1sJ2PnhvK6ck3yprbiWmAQdXTSQJ0YTpWZV
HPWhHtWATj3CbAIW7A6QtlkGBrtvhvOHHhTk+yAqwbRBk8DkwYs2DOGSfbffUKS9
SDiJZUVW2QyORcz5hEfgdk3suC2LxlphqV+0SUCSLWZgicGw28s0Zt0Zo/aU8dJi
3bRi8rSAuW/Ql7RZskgk1uEsQXVCebpTQHMigl60qlxZAgMBAAE=
-----END PUBLIC KEY-----
`;

interface PayloadInterface extends jose.JWTPayload {
  DatosSocio: string;
}

export interface DatosSocio {
  NroSocio: number;
  FilialId: number;
  Filial: string;
  NombreSocio: string;
  Cuit: string;
}

interface LoginData {
  dni: string;
  usuarioINS: string;
  rememberUser: boolean;
  fechaAceptaTyC?: Date;
}

const decode = (): DatosSocio[] => {
  let token = SessionService.GetToken();
  let result: DatosSocio[] = [];

  if (token) {
    let resultToken = { ...jose.decodeJwt(token) } as PayloadInterface;
    let resultJson = JSON.parse(resultToken.DatosSocio);
    result = resultJson.map((socio) => ({
      ...socio,
      NroSocio: Number(socio.NroSocio),
      FilialId: Number(socio.FilialId),
    }));
  }

  return result;
};

const SessionService = {
  SetLoginInformation: (loginData: LoginData) => {
    let userInformation = window.btoa(JSON.stringify(loginData));
    localStorage.setItem(userInformationKey, userInformation);
  },
  GetLoginInformation: (): LoginData => {
    let userInformation = window.localStorage.getItem(userInformationKey);
    if (userInformation)
      return JSON.parse(window.atob(userInformation)) as LoginData;
    else return {} as LoginData;
  },
  RemoveLoginInformation: () => {
    window.localStorage.removeItem(userInformationKey);
  },
  SetToken: (token: string): void => {
    localStorage.setItem(tokenKey, token);
  },
  GetToken: (): string | undefined => {
    return window.localStorage.getItem(tokenKey);
  },
  SetIsFirstLogin: (isFirstLogin: boolean): void => {
    if (isFirstLogin) sessionStorage.setItem(firstLoginKey, "SI");
  },
  IsFirstLogin: (): boolean => {
    return window.sessionStorage.getItem(firstLoginKey) !== null;
  },
  RemoveIsFirstLogin: () => {
    window.sessionStorage.removeItem(firstLoginKey);
  },
  SetIsToChangePassword: (isToChangePassword: boolean): void => {
    if (isToChangePassword) sessionStorage.setItem(isToChangePasswordKey, "SI");
  },
  IsToChangePassword: (): boolean => {
    return window.sessionStorage.getItem(isToChangePasswordKey) !== null;
  },
  RemoveIsToChangePassword: () => {
    window.sessionStorage.removeItem(isToChangePasswordKey);
  },
  SetClosedTimestamp: () => {
    localStorage.setItem(closedTimestampKey, Date.now().toString());
  },
  GetClosedTimestamp: (): number => {
    return Number(localStorage.getItem(closedTimestampKey));
  },
  GetDatosSocios: (): DatosSocio[] => {
    return decode();
  },
  RemoveToken: (): void => {
    window.localStorage.removeItem(tokenKey);
  },
  CleanSession: () => {
    SessionService.RemoveToken();
    SessionService.RemoveIsFirstLogin();
    SessionService.RemoveIsToChangePassword();
    SessionService.StoreCurrentUserSession();
  },
  VerifySession: (): boolean => {
    const token = SessionService.GetToken();
    if (token) return true;
    if (SessionService.IsFirstLogin()) {
      if (window.location.pathname !== "/login/primer-login") {
        window.location.href = "/login/primer-login";
      }
      return false;
    }
    if (SessionService.IsToChangePassword()) {
      if (window.location.pathname !== "/login/cambiar-contrasenia") {
        window.location.href = "/login/cambiar-contrasenia";
      }
      return false;
    }
    AuthenticationService.Logout();
  },
  StoreCurrentUserSession: (filialKey?: number, socioKey?: number) => {
    if (!filialKey || !socioKey) {
      sessionStorage.removeItem(CURRENT_FILIAL_STORAGE_KEY);
      sessionStorage.removeItem(CURRENT_SOCIO_STORAGE_KEY);
      return;
    }

    sessionStorage.setItem(CURRENT_FILIAL_STORAGE_KEY, filialKey?.toString());
    sessionStorage.setItem(CURRENT_SOCIO_STORAGE_KEY, socioKey?.toString());
  },
  RetrieveCurrentUserSession: () => ({
    currentFilial: sessionStorage.getItem(CURRENT_FILIAL_STORAGE_KEY),
    currentSocio: sessionStorage.getItem(CURRENT_SOCIO_STORAGE_KEY),
  }),
};

export default SessionService;
