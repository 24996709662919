import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ContactoService from "../../../services/ContactoService/contacto.service";
import { UseContactos } from "./ContactosContext";
import { useSnackbar } from "notistack";
import {
  defaultErrorSnackbar,
  defaultSuccessSnackbar,
} from "../../../utils/snackBarConfigs.util";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const BorrarContacto = ({ setModalIsOpen, idContacto }) => {
  const { refreshContactos } = UseContactos();
  const { enqueueSnackbar } = useSnackbar();

  const deleteHandle = () => {
    ContactoService.DeleteById(idContacto)
      .then(() => {
        enqueueSnackbar(
          `Contacto borrado exitosamente`,
          defaultSuccessSnackbar
        );
        refreshContactos();
      })
      .catch(() =>
        enqueueSnackbar(
          `Hubo un problema al eliminar el contacto`,
          defaultErrorSnackbar
        )
      );

    setModalIsOpen(false);
  };

  return (
    <div>
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h3"
          sx={{
            color: "text.primary",
            m: "0.5rem",
            my: "0",
            paddingTop: "2rem",
            paddingBottom: "2rem",
            textAlign: "center",
          }}
        >
          ¿Desea borrar el contacto?
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setModalIsOpen(false)}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => deleteHandle()}
          >
            Borrar
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default BorrarContacto;
