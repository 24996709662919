import { List, ListItem, SxProps, Typography } from "@mui/material";
import "./DrawerForm.scss";

interface DrawerItemsFormProps {
  children: React.ReactElement | React.ReactElement[];
}

export const DrawerItemsForm = (props: DrawerItemsFormProps) => {
  return <List className="drawer-form-items-container">{props.children}</List>;
};

interface DrawerActionItemProps {
  children: React.ReactElement | React.ReactElement[];
}

export const DrawerActionItem = (props: DrawerActionItemProps) => {
  return (
    <ListItem className="drawer-form-action-item">{props.children}</ListItem>
  );
};

interface DrawerDataItemProps {
  label: string;
  children: React.ReactElement;
  sx?: SxProps;
}

export const DrawerDataItem = (props: DrawerDataItemProps) => {
  return (
    <ListItem className="drawer-form-data-item" sx={props.sx}>
      <Typography variant="h4" className="drawer-form-data-item-label">
        {props.label}
      </Typography>
      {props.children}
    </ListItem>
  );
};

interface DrawerTextDataItemProps {
  label: string;
  value: string;
}

export const DrawerTextDataItem = (props: DrawerTextDataItemProps) => {
  return (
    <DrawerDataItem label={props.label}>
      <Typography variant="h4" className="drawer-form-data-item-value">
        {props.value}
      </Typography>
    </DrawerDataItem>
  );
};
