import { InputAdornment } from "@mui/material";
import { NumberToMoneyConverter } from "../../../../components/Formatters/Formatters";
import {
  DrawerDataItem,
  DrawerItemsForm,
} from "../../../../components/DrawerForm/DrawerFormAndItems";
import Icon from "../../../../components/Icon/Icon";
import { IconEnum } from "../../../../components/Icon/Icon.props";
import colors from "../../../../assets/_themes-vars.module.scss";
import { TransferInfo } from "./useTransferInfo.hook";
import TextField from "../../../../components/TextField/TextField";
import { NumericFormat } from "react-number-format";
import { NumberFormatValues } from "react-number-format/types/types";

interface TransferenciaInfoSectionProps {
  hasAmountError: boolean;
  transferInfo: TransferInfo;
  updateTransferInfo: React.Dispatch<React.SetStateAction<TransferInfo>>;
  ctaRemunerada?: boolean;
}

const TransferenciaInfoSection = ({
  hasAmountError,
  transferInfo,
  updateTransferInfo,
  ctaRemunerada,
}: TransferenciaInfoSectionProps) => {
  const minAmountMoney = NumberToMoneyConverter(transferInfo?.minImporte);
  const maxAmountMoney = NumberToMoneyConverter(transferInfo?.maxImporte);

  const amountError =
    transferInfo?.maxImporte === 0
      ? "No tiene saldo disponible"
      : `Rango permitido: ${minAmountMoney} - ${maxAmountMoney}.`;

  const handleChangeAmount = (event: NumberFormatValues) => {
    updateTransferInfo((prevTransferInfo) => ({
      ...prevTransferInfo,
      importe: event.floatValue,
    }));
  };

  const handleChangeConcept = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateTransferInfo((prevTransferInfo) => ({
      ...prevTransferInfo,
      concepto: event.target.value,
    }));
  };

  return (
    <DrawerItemsForm>
      <DrawerDataItem label={"Importe"}>
        <NumericFormat
          thousandSeparator={"."}
          autoComplete="off"
          decimalSeparator={","}
          decimalScale={2}
          allowNegative={false}
          customInput={TextField}
          className="transferencia-individual-text-field"
          placeholder="Ingrese importe..."
          variant="standard"
          value={transferInfo?.importe}
          onValueChange={handleChangeAmount}
          helperText={hasAmountError && amountError}
          error={hasAmountError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  sx={{
                    color: hasAmountError
                      ? colors.errorMain
                      : colors.primaryMain,
                  }}
                  icon={IconEnum.PLAIN_MONEY}
                />
              </InputAdornment>
            ),
          }}
        />
      </DrawerDataItem>
      {
        !ctaRemunerada &&
        <DrawerDataItem label={"Concepto"}>
          <TextField
            className="transferencia-individual-text-field"
            placeholder="Concepto de la transferencia..."
            variant="standard"
            value={transferInfo?.concepto}
            onChange={handleChangeConcept}
          />
        </DrawerDataItem>
      }
    </DrawerItemsForm>
  );
};

export default TransferenciaInfoSection;
