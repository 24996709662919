import { Card, CardContent, Typography, Box, Chip, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { InversionPageCardProps } from './InversionPageCard.props'
import "./InversionPageCardComponent.scss";
import Formatter from '../../../hooks/formatter.hook';
import { DateToStringConverter } from '../../Formatters/Formatters';

const CustomChip = styled(Chip)({
    '& .MuiChip-label': { fontWeight: 'bold', color: 'rgba(0, 230, 118, 0.7)', fontSize: '14px' },
})

const InversionPageCard = (props: InversionPageCardProps) => {

    const { setSelectedInversion, inversion } = props
    const relacion = "COTITULAR";
    
    const FirstRow = (nroCertificado: number, total: number, moneda: string) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                }}
            >

                <Typography variant="h1" className='text-bold'>
                    {`${Formatter.formatCurrency(total)} `}
                </Typography>
                <Typography variant='h5'>
                    Certificado: <span className='text-bold'>{`${nroCertificado}`}</span>&nbsp;&nbsp;
                </Typography>
            </Box>
        )
    }
    const SecondRow = (tasa: number, fechaVencimiento: Date, plazo: number) => {

        const proximaAVencer = (fechaVencimiento: Date, cantidadDiasAlertaVencimiento: number) => {
            const dateActual = new Date()
            dateActual.setHours(0, 0, 0)
            const timeActual = dateActual.getTime()

            const dateFechaVencimiento = new Date(fechaVencimiento)
            dateFechaVencimiento.setHours(0, 0, 0)
            const timeFechaVencimiento = dateFechaVencimiento.getTime()

            const diffTime = timeFechaVencimiento - timeActual
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))


            return cantidadDiasAlertaVencimiento >= diffDays

        }

        const FechaVencimiento = (fechaVencimiento: Date) => {

            const esProximaAvencer = proximaAVencer(fechaVencimiento, 7)

            return (
                <Typography variant='h5'>
                    Vto: {esProximaAvencer
                        ? <CustomChip label={DateToStringConverter(fechaVencimiento)} color='success' size='small' variant="outlined" />
                        : <span className='text-bold'>{DateToStringConverter(fechaVencimiento)}</span>
                    }
                </Typography>
            )
        }
        return (
            <div>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '3rem',
                        marginBottom: '0.3rem',
                    }}
                >
                    <Typography variant='h5'>
                        T.E.M: <span className='text-bold'>{`${tasa} %`}</span>&nbsp;&nbsp;
                    </Typography>
                    <Typography variant='h5'>
                        Plazo: <span className='text-bold'>{`${plazo.toString()} días`}</span>&nbsp;&nbsp;
                    </Typography>
                </Box>
                <Box>
                    {FechaVencimiento(fechaVencimiento)}
                </Box>
            
            </div>
        )
    }

    return (

        <Card className='home-card' onClick={() => setSelectedInversion(inversion)}>
            <CardContent className='inversion-page-card-content' sx={{width: "100%"}} >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                    }}
                >
                    <Grid container>
                        <Grid item xs={7}>
                            {FirstRow(inversion.NroCertificado, inversion.Total, inversion.Moneda)}
                        </Grid>
                        <Grid item xs={8}>
                            {SecondRow(inversion.Tasa, inversion.FechaVencimiento, inversion.Plazo)}
                        </Grid>
                        {
                            inversion.Relacion === relacion
                            &&
                            <Grid item xs={4} display='flex' justifyContent='flex-end' alignItems='end'>
                                <CustomChip label={inversion.Relacion} color='success' size='small' variant="outlined" />
                            </Grid>
                        }
                    </Grid>
                </Box>
            </CardContent>
        </Card>

    )
}




export default InversionPageCard