import React, { useState, useEffect } from 'react'
import { UsuarioCuenta } from '../../../contexts/UsuarioContext'
import { Grid } from '@mui/material'
import { styled } from '@mui/material'
import colors from '../../../assets/_themes-vars.module.scss'
import Formatter from '../../../hooks/formatter.hook'
import AccordionSeccion from '../AccordionSeccion/AccordionSeccion'
import Loader from '../../../components/Loader/Loader'
import { NumericFormat } from 'react-number-format'
import { PasoConstitucion1 } from '../PasoConstitucion'
import useBreakpoints from '../../../hooks/breakpoints.hook'
import TextField from '../../../components/TextField/TextField'


const CuentaSeleccionadaContent = (props: { selectedCuentaUsuario: UsuarioCuenta, saldoCuenta: number, monto: number, setMonto:React.Dispatch<React.SetStateAction<number>>, pasoActual: number,pasoValidado: boolean }) => {

    const { selectedCuentaUsuario, saldoCuenta, monto, setMonto, pasoActual, pasoValidado } = props
    const bp = useBreakpoints()

    const CustomizeTextField = styled(TextField)({
        width: '100%',
        '& label': {
            color: colors.grey,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: colors.grey,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.grey,
            },
            '&:hover fieldset': {
                borderColor: colors.grey,
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.grey,
            },
        },
    });

    const CustomizeTextFieldAmount = styled(TextField)({
        width: '100%',
        '& label': {
            color: colors.grey,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: colors.grey,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.grey,
            },
            '&:hover fieldset': {
                borderColor: colors.grey,
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.grey,
            },
        },
        '& .MuiOutlinedInput-input': {
            textAlign: `${bp.isSmDown ? 'center' : 'right'}`, 
          },
    });

    const CustomizeNumericFormat = styled(TextField)({
        textAlign: 'end',
        width: '100%',
        '& label': {
            color: colors.primaryMain,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: colors.primaryMain,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.primaryMain,
            },
            '&:hover fieldset': {
                borderColor: colors.primaryMain,
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.primaryMain,
            },
        },
        '& .MuiOutlinedInput-input': {
            textAlign: `${bp.isSmDown ? 'center' : 'right'}`,
          },
    });

    const formatAmountToNumber = (value: string): number => {
        const valueAmount = value.slice(1)
        return !valueAmount ? null : parseFloat(valueAmount)
    };

    const CuentaSeleccionadaContent = (): JSX.Element => {
        const [montoInput, setMontoInput] = useState<number>(monto);

        return (
            saldoCuenta == -1
                ? <Loader label='Obteniendo el saldo de la cuenta' />
                : (
                    <Grid container spacing={2} sx={{ paddingTop: '.6rem' }}>
                        <Grid item xs={12} sm={6} md={3} lg={4}>
                            <CustomizeTextField
                                inputProps={{ readOnly: true }}
                                label='Numero de cuenta / Filial '
                                value={!selectedCuentaUsuario ? '' : `${selectedCuentaUsuario.NroSocio} - ${selectedCuentaUsuario?.Filial?.replace(/\s+$/, "")}`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <CustomizeTextField
                                inputProps={{ readOnly: true }}
                                label='Socio'
                                value={!selectedCuentaUsuario ? '' : selectedCuentaUsuario.NombreSocio}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <CustomizeTextFieldAmount
                                inputProps={{ readOnly: true }}
                                label='Saldo'
                                value={isNaN(saldoCuenta) ? 0 : !saldoCuenta ? '' : Formatter.formatCurrency(saldoCuenta)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <NumericFormat
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                prefix={"$"}
                                decimalScale={2}
                                allowNegative={false}
                                customInput={CustomizeNumericFormat}
                                label="Monto Inversión"
                                size="medium"
                                InputLabelProps={{
                                    variant: 'outlined'
                                }}
                                value={montoInput}
                                onValueChange={e => !e.floatValue || e.floatValue == undefined ? setMontoInput(null) : setMontoInput(e.floatValue)}
                                onBlur={e => setMonto(formatAmountToNumber(e.target.value.replace(/\./g, "").replace(",", ".")))}
                                error={!pasoValidado && (!montoInput || saldoCuenta < montoInput)}
                                helperText={!pasoValidado && (!montoInput || saldoCuenta < montoInput) &&
                                    (!montoInput
                                        ? 'Se debe definir el monto de la inversión'
                                        : 'El monto de la inversión no debe superar al saldo de la cuenta'
                                    )
                                }
                                inputProps={{
                                    readOnly: pasoActual != PasoConstitucion1.pagina
                                }}
                            />
                        </Grid>
                    </Grid>
                )
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AccordionSeccion
                    legend='Información'
                    content={<CuentaSeleccionadaContent />}
                />
            </Grid>
        </Grid>
    )
}

export default CuentaSeleccionadaContent