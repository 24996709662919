import { Badge, Box, Divider, Link, ListSubheader } from "@mui/material";
import { NotificationMenuHeaderStyles as styles } from "./NotificationMenu.styles";
import { useMemo } from "react";
import { UseUsuario } from "../../../contexts/UsuarioContext";

interface NotificationsListHeaderProps {
  totalUnreadNotifications: number;
}

export const NotificationsListHeader = ({
  totalUnreadNotifications,
}: NotificationsListHeaderProps) => {
  const gapIfMinor10Notifications = -15;
  const gapForCharacter = -4;

  const rightOffset = useMemo(
    () =>
      gapIfMinor10Notifications +
      getTotalGaps(totalUnreadNotifications) * gapForCharacter,
    [totalUnreadNotifications]
  );

  return (
    <ListSubheader component="div" sx={{ padding: 0 }}>
      <Box sx={styles.Header}>
        <Badge
          badgeContent={totalUnreadNotifications}
          color="error"
          sx={{ "& .MuiBadge-badge": { right: rightOffset, top: 24 } }}
        >
          <h4 style={styles.HeaderTitleTotalUnread}>
            {totalUnreadNotifications === 0 ? "Leídas" : "No leídas: "}
          </h4>
        </Badge>
        <h4 style={styles.HeaderTitleTotalUnread}>
          <Link href={`/cuenta/movimientos`}>Ver historial</Link>
        </h4>
      </Box>
      <Divider sx={styles.HeaderDivider} />
    </ListSubheader>
  );
};

const getTotalGaps = (total: number) => {
  if (total < 10) return 0;
  if (total < 100) return 1;
  return 2;
};
