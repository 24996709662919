import React, { useState, useContext, ReactElement, useMemo } from "react";
import { TipoPrestamoEnum } from "../pages/Prestamos/enums/TipoPrestamo.enum";

export interface DetallePrestamoInfo {
  isOpenDrawer: boolean;
  tipoPrestamo: TipoPrestamoEnum;
  nroComprobantePrestamo: number;
  lineaPrestamoId: number;
}

interface DetallePrestamoContextState {
  detallePrestamo: DetallePrestamoInfo;
  setDetallePrestamo: React.Dispatch<React.SetStateAction<DetallePrestamoInfo>>;
}

export const defaultDetallePrestamoState: DetallePrestamoInfo = {
  isOpenDrawer: false,
  tipoPrestamo: undefined,
  nroComprobantePrestamo: undefined,
  lineaPrestamoId: undefined,
};

const DetallePrestamoContext = React.createContext<DetallePrestamoContextState>(
  {
    detallePrestamo: defaultDetallePrestamoState,
    setDetallePrestamo: () => {},
  }
);

export function useDetallePrestamo() {
  return useContext(DetallePrestamoContext);
}

export function DetallePrestamoProvider(props: { children: ReactElement }) {
  const [detallePrestamo, setDetallePrestamo] = useState<DetallePrestamoInfo>(
    defaultDetallePrestamoState
  );

  const detallePrestamoMemoized = useMemo(
    () => ({
      detallePrestamo,
      setDetallePrestamo,
    }),
    [detallePrestamo, setDetallePrestamo]
  );

  return (
    <DetallePrestamoContext.Provider value={detallePrestamoMemoized}>
      {props.children}
    </DetallePrestamoContext.Provider>
  );
}
