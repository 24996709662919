import { SwipeableDrawerProps } from "./SwipeableDrawer.props";
import { SwipeableDrawer } from "@mui/material";
import SwipeableDrawerHeader from "./SwipeableDrawerHeader";

const SwipeableDrawerComponent = (config: SwipeableDrawerProps) => {
  const { openDrawer, contentDrawer, anchor, setOpenDrawer, title } = config;

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={openDrawer}
      disableSwipeToOpen
      onClose={() => setOpenDrawer(false)}
      onOpen={() => {}}
      sx={{ zIndex: 3000 }}
    >
      <SwipeableDrawerHeader titulo={title} setOpenDrawer={setOpenDrawer} />
      {contentDrawer}
    </SwipeableDrawer>
  );
};

export default SwipeableDrawerComponent;
