import { Button, ListItem } from "@mui/material";
import { Contacto } from "../../../services/ContactoService/contacto.service";
import {
  DrawerActionItem,
  DrawerDataItem,
  DrawerItemsForm,
  DrawerTextDataItem,
} from "../../../components/DrawerForm/DrawerFormAndItems";
import TextField from "../../../components/TextField/TextField";

interface ContactoDataFormActionButtons {
  okButtonLabel: string;
  okButtonOnClick: () => void;
  cancelButtonLabel: string;
  cancelButtonOnClick: () => void;
}

interface ContactoDataFormProps {
  contacto: Contacto;
  referencia: string;
  setReferencia: (newReferencia: string) => void;
  actionButtons: ContactoDataFormActionButtons;
}

const ContactoDataForm = (props: ContactoDataFormProps) => {
  return (
    <DrawerItemsForm>
      <DrawerTextDataItem
        label={"Nro. Socio"}
        value={props.contacto.NSocio}
      />
      <DrawerTextDataItem
        label={"Documento"}
        value={props.contacto.Documento}
      />
      <DrawerTextDataItem
        label={"Apellido y Nombre"}
        value={props.contacto.NombreSocio}
      />
      <DrawerDataItem label={"Referencia"} sx={{ gap: "20px" }}>
        <TextField
          hiddenLabel
          sx={{ width: "65%" }}
          defaultValue={props.referencia}
          inputProps={{ maxLength: 45 }}
          variant="standard"
          onChange={(e) => props.setReferencia(e.target.value)}
        />
      </DrawerDataItem>
      <DrawerActionItem>
        <Button
          variant="contained"
          color="error"
          onClick={() => props.actionButtons.cancelButtonOnClick()}
        >
          {props.actionButtons.cancelButtonLabel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.actionButtons.okButtonOnClick()}
        >
          {props.actionButtons.okButtonLabel}
        </Button>
      </DrawerActionItem>
    </DrawerItemsForm>
  );
};

export default ContactoDataForm;
