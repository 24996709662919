import { useState } from "react";

interface CarouselSwipeContainerProps {
  index: number;
  children: React.ReactNode;
  setCarouselIndex: React.Dispatch<React.SetStateAction<number>>;
  totalItems: number;
}
const CarouselSwipeContainer = (props: CarouselSwipeContainerProps) => {
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchMoveX, setTouchMoveX] = useState(0);
  const touchStartHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartX(e.touches[0].clientX);
  };
  const touchMoveHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchMoveX(e.touches[0].clientX);
  };
  const touchEndHandler = () => {
    const minPxDistance = 50;
    let totalDistance = touchStartX - touchMoveX;
    let totalDistanceAbs = Math.abs(totalDistance);

    if (touchMoveX!== 0 && totalDistanceAbs > minPxDistance) {
      updateCarouselIndex();
      resetState();
    }
    function updateCarouselIndex() {
      if (totalDistance > 0) {
        props.setCarouselIndex((prev) => nextIndex(prev));
      } else {
        props.setCarouselIndex((prev) => prevIndex(prev));
      }
      function nextIndex(prev: number): number {
        return (prev + 1) % props.totalItems;
      }
      function prevIndex(prev: number): number {
        return prev !== 0 ? prev - 1 : props.totalItems - 1;
      }
    }
    function resetState() {
      setTouchStartX(0);
      setTouchMoveX(0);
    }
  };
  return (
    <div
      onTouchStart={touchStartHandler}
      onTouchMove={touchMoveHandler}
      onTouchEnd={touchEndHandler}
      key={props.index}
    >
      {props.children}
    </div>
  );
};

export default CarouselSwipeContainer;
