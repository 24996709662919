import { UseUsuario } from "../../../../contexts/UsuarioContext";
import { Cuenta } from "../../../../services/CuentaService/cuenta.service.interfaces";
import CtaRemuneradaCard from "../CtaRemuneradaCard/CtaRemuneradaCard";
import NoElementsCard from "../NoElementsCard/NoElementsCard";
import CuentaCard from "./CuentaCard";

function CuentaCardList(cuenta: Cuenta, hiddenBalances: boolean) {

  let cardList: JSX.Element[] = [];
  if (cuenta) {
    cardList.push(
      <CuentaCard
        titulo={cuenta.Etiqueta}
        total={cuenta.Saldo}
        numeroSocio={cuenta.NroCuenta}
        hiddenBalance={hiddenBalances}
      />
    );

    if (cuenta.HasCuentaRemunerada) {
      cardList.push(
        <CtaRemuneradaCard
          titulo={"Ahorro Mutual Plus"}
          total={cuenta.SaldoCuentaRemunerada}
          cuenta={cuenta.NroCuenta}
          interesAcumulado={cuenta.InteresesAcumuladosMC}
          hiddenBalance={hiddenBalances}
        />
      );
    }
  } else {
    cardList.push(
      <NoElementsCard
        errorText={"El usuario no se encuentra asociado a ninguna cuenta"}
      />
    );
  }
  return cardList;
}

export default CuentaCardList;
