import { Typography } from "@mui/material";

interface SocioSearchErrorProps {
  errorMessage: string;
}

export const SocioSearchError = (props: SocioSearchErrorProps) => {
  return (
    <Typography variant="h4" className="socio-search-error">
      {props.errorMessage}
    </Typography>
  );
};
