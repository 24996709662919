import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconEnum } from "../../../../../components/Icon/Icon.props";
import Icon from "../../../../../components/Icon/Icon";
import colors from "../../../../../assets/_themes-vars.module.scss";

export const MovementsTableFooter = () => {
    const navigate = useNavigate();
  
    return (
      <div className="footer">
        <Button
          disableRipple={true}
          sx={{
            color: colors.lightTextSecondary,
            width: "25%",
            minWidth: "8rem",
            p: "10px",
          }}
          onClick={() => {
            navigate(`/cuenta/movimientos`);
          }}
        >
          <Icon icon={IconEnum.VIEW_MORE} />
          <Typography
            variant="h3"
            sx={{
              color: colors.lightTextSecondary,
              ml: "10px",
              whiteSpace: "nowrap",
            }}
          >
            Ver más
          </Typography>
        </Button>
      </div>
    );
  };