export const NumberToMoneyConverter = (
  number: number,
  activeCents: boolean = true
) => {
  let formatter = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    maximumFractionDigits: activeCents ? 2 : 0,
  });
  return formatter.format(number);
};

export const StringToDateConverter = (str: string) => {
  const [day, month, year] = str.split("/");

  const date = new Date(+year, +month - 1, +day);

  return date;
};

export const DateToStringConverter = (date: Date) => {
  const renewDate = new Date(date);
  return renewDate
    ?.toLocaleString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    ?.split(",")
    ?.at(0);
};

export const EmptyToString = (stringFrom: string, stringTo: string) => {
  return stringFrom && stringFrom !== "" ? stringFrom : stringTo;
};

export const StringToNumber = (numStr: string): number => {
  return Number.parseFloat(numStr?.replace(",", "."));
};

export const NumberToPercent = (number: number): string => {
  return `${number * 100} %`;
};
