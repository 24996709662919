import Loader from "./Loader";
import { LoaderProps } from "./Loader.props";

const AllPageLoader = (props: LoaderProps) => {
  return (
    <div
      style={{
        display: "flex",
        height: "80vh",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Loader label={props.label} />
    </div>
  );
};

export default AllPageLoader;
