import { Button, Grid, Typography } from "@mui/material";
import Icon from "../../Icon/Icon";
import { useSnackbar } from "notistack";
import { IconEnum } from "../../Icon/Icon.props";
import { GetComprobanteProps } from "../../../services/ComprobantesService/comprobantes.service.interfaces";
import "./SuccessActionDrawerContent.scss";
import { DownloadComprobante } from "../../DownloadButton/Download.function";

const SuccessActionDrawerContent = (props: {
  closeDrawer: () => void;
  title: string;
  text: string;
  comprobanteData: GetComprobanteProps;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Grid container className="success-action-drawer-main-container">
      <Typography variant="h1" className="success-action-drawer-title">
        {props.title}
      </Typography>

      <Typography variant="h4">{props.text}</Typography>
      <div className="success-action-drawer-buttons">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            DownloadComprobante(props.comprobanteData, enqueueSnackbar);
            props.closeDrawer();
          }}
          startIcon={<Icon icon={IconEnum.DOWNLOAD} />}
        >
          Imprimir Comprobante
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.closeDrawer()}
        >
          Aceptar
        </Button>
      </div>
    </Grid>
  );
};

export default SuccessActionDrawerContent;
