import { Divider } from "@mui/material";

export const CardDivider = () => {
  return (
    <Divider
      className="generic-page-section"
      variant="middle"
      sx={{
        mt: "0.3rem",
        mb: "1rem",
        borderColor: "text.primary",
      }}
    />
  );
};
