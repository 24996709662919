import { Button, Typography } from "@mui/material";
import Icon from "../../../../components/Icon/Icon";
import { QuickAccessButtonProps } from "./QuickAccessButton.props";
import "./QuickAccessMenuComponent.scss";

const QuickAccessButton = (props: QuickAccessButtonProps) => {
  return (
    <Button
      className={"quick-access-button"}
      variant="outlined"
      sx={{ color: "primary" }}
      onClick={props.onClick}
    >
      <div>
        <Icon
          sx={{ width: "70%", height: "70%" }}
          icon={props.icon}
        />
        <Typography variant="body1" sx={{ color: "text.primary" }}>
          {props.text}
        </Typography>
      </div>
    </Button>
  );
};

export default QuickAccessButton;
