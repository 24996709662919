import { createContext, useState } from "react";

export const LayoutContext = createContext(null);
const state = {
    menuOpened: true
}
const LayoutContextProvider = ({children}) => (
    <LayoutContext.Provider value={useState(state)}>
        {children}
    </LayoutContext.Provider>
);

export default LayoutContextProvider;