import { Typography } from "@mui/material";
import colors from "../../../assets/_themes-vars.module.scss";
import {
  DrawerDataItem,
  DrawerItemsForm,
  DrawerTextDataItem,
} from "../../../components/DrawerForm/DrawerFormAndItems";
import {
  DateToStringConverter,
  NumberToMoneyConverter,
} from "../../../components/Formatters/Formatters";
import AllPageLoader from "../../../components/Loader/AllPageLoader";
import useDetallePrestamoVencido from "../hooks/useDetallePrestamoVencido.hook";
import { PrestamoIdentifier } from "../interfaces/Prestamo";

const PrestamoTipoVencido = (props: PrestamoIdentifier) => {
  const { isLoading, detallePrestamo } = useDetallePrestamoVencido(props);
  return (
    <>
      {isLoading && <AllPageLoader label={"Cargando detalle"} />}
      {!isLoading && (
        <DrawerItemsForm>
          <DrawerTextDataItem
            label={"Comprobante"}
            value={detallePrestamo?.NComprobante.toString()}
          />
          <DrawerTextDataItem
            label={"Fecha Imposición"}
            value={DateToStringConverter(detallePrestamo?.FecImp)}
          />
          <DrawerTextDataItem
            label={"Importe"}
            value={NumberToMoneyConverter(detallePrestamo?.Importe)}
          />
          <DrawerTextDataItem
            label={"Destino"}
            value={detallePrestamo?.Destino}
          />
          <DrawerTextDataItem
            label={"Días Transcurridos"}
            value={detallePrestamo?.DiasTranscurridos.toString()}
          />
          <DrawerTextDataItem
            label={"Plazo"}
            value={`${detallePrestamo?.Plazo.toString()} días`}
          />
          <DrawerDataItem label={"Fecha Vencimiento"}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: detallePrestamo.EsMoroso
                  ? colors.errorMain
                  : colors.grey900,
                textAlign: "right",
              }}
            >
              {DateToStringConverter(detallePrestamo.FechaVto)}
            </Typography>
          </DrawerDataItem>
          <DrawerTextDataItem
            label={"Total"}
            value={NumberToMoneyConverter(detallePrestamo?.Total)}
          />
        </DrawerItemsForm>
      )}
    </>
  );
};

export default PrestamoTipoVencido;
