import { useState, useEffect } from 'react'
import SwipeableDrawerComponent from '../SwipeableDrawer/SwipeableDrawer'
import SwitchCuentasContainer from './SwitchCuentasContainer'


const SwitchCuentasDrawer = (props:{openDrawer:boolean, setOpenDrawer: Function}) => {

  const {openDrawer, setOpenDrawer} = props;
  

  return (
    <SwipeableDrawerComponent
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      anchor='right'
      contentDrawer={<SwitchCuentasContainer setOpenDrawer={setOpenDrawer} />}
      title='Cuentas'
    />
    )
}

export default SwitchCuentasDrawer