import { Box } from "@mui/system";
import { Contacto } from "../../../../services/ContactoService/contacto.service";
import { UseUsuario } from "../../../../contexts/UsuarioContext";
import useSearchContacto from "../../hooks/useSearchContacto.hook";
import { UseContactos } from "../ContactosContext";
import useDebouncedCallback from "../../../../hooks/useDebouncedCallback";
import TransferenciaInternalLoader from "../BuscandoSocioLoader";
import { SocioSearchError } from "../SocioSearchError";
import Divider100P from "../../../../components/Dividers/Divider100P";
import { useMemo } from "react";
import CuentaSeleccionadaInfoSection from "./CuentaSeleccionadaInfoSection";
import TransferenciaSocioSearcher from "./TransferenciaSocioSearcher";
import CuentaDestinoInfoSection from "./CuentaDestinoInfoSection";
import TransferenciaInfoSection from "./TransferenciaInfoSection";
import TransferenciaConfirmSection from "./TransferenciaConfirmSection";
import useAgendar from "./useAgendar.hook";
import useTransferInfo from "./useTransferInfo.hook";
import useMakeTransfer from "./useMakeTransfer.hook";
import TransferLoader from "./TransferLoader";
import TransferenciaExitosa from "./TransferenciaExitosa";

const TransferenciaIndividual = (props: {
  destinatario?: Contacto;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { selectedCuentaUsuario } = UseUsuario();

  const { contactos } = UseContactos();

  const {
    contacto: selectedContact,
    errorMessage,
    isSearching,
    searchSocio,
    isExistingContact,
  } = useSearchContacto({
    currentNroSocio: selectedCuentaUsuario?.NroSocio,
    contactos: contactos,
    settedContact: props?.destinatario,
    existingContactoInvalid: false,
  });

  const { agendar, agendarEnabled, changeAgendarState } =
    useAgendar(selectedContact);

  const { transferInfo, setTransferInfo, hasAmountError } = useTransferInfo(
    selectedCuentaUsuario
  );

  const { isMakingTransfer, makeTransfer } = useMakeTransfer({
    afterTransfer: (comprobante: number) => {
      if (!comprobante) {
        props.setOpenDrawer(false);
        return;
      }
      setTransferInfo((prev) => ({
        ...prev,
        done: true,
        comprobante: comprobante,
      }));
    },
    NSocioDestino: Number(selectedContact?.NSocio),
    NFilialDestino: Number(selectedContact?.NFilial),
    Monto: transferInfo?.importe,
    Referencia: transferInfo?.concepto,
  });

  const isCuentaPropia = useMemo(
    () =>
      selectedCuentaUsuario?.Cuit?.toString() ===
      selectedContact?.Documento?.toString(),
    [selectedCuentaUsuario, selectedContact]
  );

  const delayedSearch = useDebouncedCallback(searchSocio, 300);

  const handleTransfer = () => {
    if (agendarEnabled) agendar();
    makeTransfer();
  };

  const hasSearchError = !isSearching && errorMessage;
  const hasDestinationContact =
    !isMakingTransfer && !isSearching && !errorMessage && selectedContact;
  const isPossibleToTransfer = hasDestinationContact && !hasAmountError;

  return (
    <>
      {!transferInfo?.done && (
        <Box className="transferencias-menu-main-container">
          {isMakingTransfer && <TransferLoader />}
          {!isMakingTransfer && (
            <>
              <CuentaSeleccionadaInfoSection
                selectedCuentaUsuario={selectedCuentaUsuario}
              />
              <Divider100P />
              <TransferenciaSocioSearcher searchFunction={delayedSearch} />
              <Divider100P />
            </>
          )}
          {isSearching && (
            <TransferenciaInternalLoader label={"Buscando socio"} />
          )}
          {hasSearchError && <SocioSearchError errorMessage={errorMessage} />}
          {hasDestinationContact && (
            <>
              <CuentaDestinoInfoSection
                contacto={selectedContact}
                isExistingContact={isExistingContact}
                agendarEnabled={agendarEnabled}
                changeAgendarState={changeAgendarState}
                isCuentaPropia={isCuentaPropia}
              />
              <Divider100P />
              <TransferenciaInfoSection
                transferInfo={transferInfo}
                updateTransferInfo={setTransferInfo}
                hasAmountError={hasAmountError}
              />
            </>
          )}
          {isPossibleToTransfer && (
            <>
              <Divider100P />
              <TransferenciaConfirmSection
                contactName={selectedContact?.NombreSocio}
                importe={transferInfo?.importe}
                onCancel={() => props.setOpenDrawer(false)}
                onAccept={handleTransfer}
              />
            </>
          )}
        </Box>
      )}
      {transferInfo?.done && (
        <TransferenciaExitosa
          closeDrawer={() => props.setOpenDrawer(false)}
          importe={transferInfo.importe}
          destino={`${selectedContact.NombreSocio} (${selectedContact.NSocio})`}
          comprobante={transferInfo.comprobante}
        />
      )}
    </>
  );
};

export default TransferenciaIndividual;
