import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { IDrawerCtaRemunerada } from "./IDrawerCtaRemunerada.props";
import TablaIntereses from "../TablaIntereses/TablaIntereses";
import { UseUsuario } from "../../../../contexts/UsuarioContext";
import { NumberToMoneyConverter } from "../../../../components/Formatters/Formatters";
import Divider100P from "../../../../components/Dividers/Divider100P";
import TransferenciaInfoSection from "../../../Transferencias/PageComponents/TransferenciaIndividual/TransferenciaInfoSection";
import useTransferInfo from "../../../Transferencias/PageComponents/TransferenciaIndividual/useTransferInfo.hook";
import CuentaSeleccionadaInfoSection from "../../../Transferencias/PageComponents/TransferenciaIndividual/CuentaSeleccionadaInfoSection";
import useMakeTransfer from "../../../Transferencias/PageComponents/TransferenciaIndividual/useMakeTransfer.hook";
import { useNavigate } from "react-router-dom";
import useFilterResponsiveStyles from "../../../CuentaEspecifica/PageComponents/Filters/FilterResponsiveStyle";
import { useState } from "react";
import useBreakpoints from "../../../../hooks/breakpoints.hook";
import { useSystemDate } from "../../../../contexts/SystemDateContext";

const DrawerCtaRemunerada: React.FC<IDrawerCtaRemunerada> = ({
  drawerAction,
  filters,
  setOpenDrawer,
}) => {

  const { selectedCuentaUsuario } = UseUsuario();
  const navigate = useNavigate();
  const styles = useFilterResponsiveStyles();
  const { isSmDown } = useBreakpoints();
  const systemDate = useSystemDate();

  const dateFormatter = new Intl.DateTimeFormat("es-ES", { month: "short" });
  const currentMonth = dateFormatter.format(systemDate);
  const formattedMonth =
    currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);

  const btnStyles = {
    ...styles.button,
    margin: "1rem",
    width: "100%",
  };

  const { transferInfo, setTransferInfo, hasAmountError } = useTransferInfo(
    selectedCuentaUsuario,
    true
  );

  const { makeTransfer } = useMakeTransfer({
    afterTransfer: () => {
      setOpenDrawer(false);
      navigate("/cuenta/ahorro-mutual-plus");
    },
    NSocioDestino: selectedCuentaUsuario?.NroSocio,
    NFilialDestino: selectedCuentaUsuario?.FilialId,
    Monto: transferInfo?.importe,
    ctaPlus: true,
  });

  const renderDrawerContent = () => {
    if (drawerAction === "verIntereses") {
      return (
        <Box display={"flex"} flexDirection={"column"} m={0}>
          <Typography
            variant={isSmDown ? "h2" : "h1"}
            mt="10px"
            p="10px"
            textAlign="center"
          >
            Intereses ({formattedMonth})
          </Typography>
          <Divider />
          <Typography variant="body1" m="15px" textAlign="center">
            ACUMULADO:{" "}
            <span style={{ fontWeight: "bold" }}>
              {NumberToMoneyConverter(
                selectedCuentaUsuario?.InteresesAcumuladosMC
              )}
            </span>
          </Typography>
          <Divider />
          <TablaIntereses filters={filters} />
        </Box>
      );
    } else {
      return (
        <Box>
          <CuentaSeleccionadaInfoSection
            selectedCuentaUsuario={selectedCuentaUsuario}
            ctaRemunerada={true}
          />
          <Divider100P />
          <TransferenciaInfoSection
            transferInfo={transferInfo}
            updateTransferInfo={setTransferInfo}
            hasAmountError={hasAmountError}
            ctaRemunerada={true}
          />
          <Divider100P />
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            p={"10px"}
          >
            <Button
              disabled={hasAmountError}
              variant="contained"
              sx={btnStyles}
              onClick={makeTransfer}
            >
              Transferir
            </Button>
          </Box>
        </Box>
      );
    }
  };

  return (
    <Grid
      container
      sx={{ width: "min(500px, 100vw)", p: "5px" }}
      display={"flex"}
      justifyContent={"center"}
    >
      {renderDrawerContent()}
    </Grid>
  );
};

export default DrawerCtaRemunerada;
