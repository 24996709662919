import { useState } from "react";
import Box from "@mui/material/Box";
import ContactoService, {
  Contacto,
} from "../../../services/ContactoService/contacto.service";
import Loader from "../../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import {
  defaultErrorSnackbar,
  defaultSuccessSnackbar,
} from "../../../utils/snackBarConfigs.util";
import ContactoDataForm from "./ContactoDataForm";
import TransferenciaInternalLoader from "./BuscandoSocioLoader";

const EditarContacto = (props: {
  destinatario: Contacto;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  refreshContactos: () => void;
}) => {
  const [newReferencia, setNewReferencia] = useState<string>(
    props.destinatario?.Referencia
  );

  const [isUpdatingUsuario, setIsUpdatingUsuario] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const updateContacto = () => {
    setIsUpdatingUsuario(true);
    ContactoService.UpdateByID(props.destinatario.ID, newReferencia)
      .then(() => {
        props.refreshContactos();
        enqueueSnackbar(
          `Contacto actualizado satisfactoriamente`,
          defaultSuccessSnackbar
        );
      })
      .catch(() => {
        enqueueSnackbar(
          `Error al actualizar el contacto`,
          defaultErrorSnackbar
        );
      })
      .finally(() => {
        setIsUpdatingUsuario(false);
        props.setOpenDrawer(false);
      });
  };

  return (
    <Box className="transferencias-menu-main-container">
      {isUpdatingUsuario && (
        <TransferenciaInternalLoader label={"Actualizando contacto"} />
      )}
      {!isUpdatingUsuario && (
        <ContactoDataForm
          contacto={props.destinatario}
          referencia={newReferencia}
          setReferencia={setNewReferencia}
          actionButtons={{
            cancelButtonLabel: "Cancelar",
            cancelButtonOnClick: () => props.setOpenDrawer(false),
            okButtonLabel: "Actualizar",
            okButtonOnClick: () => updateContacto(),
          }}
        />
      )}
    </Box>
  );
};

export default EditarContacto;
