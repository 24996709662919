import { Link, Typography } from "@mui/material";
import sadFace from "../../assets/images/sad-face-error-404.png";
import "./Error404.scss";

const Error404Page = () => {
  return (
    <div className="error-404-page">
      <div className="error-404-main">
        <img src={sadFace} className="error-404-image" />
        <Typography variant="h1" className="error-404-404-text">
          404
        </Typography>
        <Typography variant="h1" className="error-404-page-not-found">
          Página no encontrada
        </Typography>
        <Typography variant="h4" className="error-404-text">
          Lo sentimos, la página que está buscando no está disponible. Esto puede deberse a un enlace incorrecto o a que la página
          ha sido eliminada.
        </Typography>
        <Typography variant="h4" className="error-404-text">
          Disculpe las molestias que esto pueda haberle causado.
        </Typography>
        <Link href="/home" className="error-404-back-to-home">
          Volver al Home
        </Link>
      </div>
    </div>
  );
};

export default Error404Page;
