import { Contacto } from "../../../../services/ContactoService/contacto.service";
import { DrawerItemsForm, DrawerTextDataItem } from "../../../../components/DrawerForm/DrawerFormAndItems";
import AgendarContacto from "./AgendarContacto";

interface CuentaDestinoInfoSectionProps {
  contacto: Contacto;
  isExistingContact: boolean;
  isCuentaPropia: boolean;
  agendarEnabled: boolean;
  changeAgendarState: () => void;
}

const CuentaDestinoInfoSection = ({
  contacto,
  isExistingContact,
  isCuentaPropia,
  agendarEnabled,
  changeAgendarState,
}: CuentaDestinoInfoSectionProps) => {
  return (
    <DrawerItemsForm>
      <DrawerTextDataItem
        label={"Cuenta Destino"}
        value={`${contacto.NSocio} - ${contacto.NombreSocio}`}
      />
      <DrawerTextDataItem
        label={"Documento"}
        value={contacto.Documento}
      />
      <DrawerTextDataItem
        label={"Ref. Contacto"}
        value={contacto?.Referencia || "--"}
      />
      <DrawerTextDataItem
        label={"Cuenta Propia"}
        value={isCuentaPropia ? "SI" : "NO"}
      />
      {!isExistingContact && (
        <AgendarContacto
          isEnabled={agendarEnabled}
          changeState={changeAgendarState}
        />
      )}
    </DrawerItemsForm>
  );
};

export default CuentaDestinoInfoSection;
