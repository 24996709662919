import { Cell } from "../../../../../components/Tables/TableWrapper/Table.props";
import { DefaultSmHeaderCell } from "../../../../../components/Tables/TableWrapper/DefaultHeaderCell";
import { DefaultSmBodyCell } from "../../../../../components/Tables/TableWrapper/DefaultBodyCell";
import {
  DateToStringConverter,
  NumberToMoneyConverter,
} from "../../../../../components/Formatters/Formatters";
import {
  DrawerItemsForm,
  DrawerTextDataItem,
} from "../../../../../components/DrawerForm/DrawerFormAndItems";
import DetallePrestamoTable from "../DetallePrestamoTable";
import { DetalleCheque } from "../../../interfaces/Prestamo";
import useCustomPagination from "../../../../../hooks/useCustomPagination.hook";

interface TablaDetalleChequesProps {
  cheques: DetalleCheque[];
}

const TablaDetalleCheques = ({ cheques }: TablaDetalleChequesProps) => {
  const pagination = useCustomPagination<DetalleCheque>({
    defaultPagination: { page: 0, pageSize: 5, totalElements: cheques.length },
    elements: cheques,
  });

  const chequesToShow = pagination.paginatedElements;

  const chequeHiddenDetails = chequesToShow.map((c) => (
    <ChequeHiddenDetail key={`CHQ-${c.IdentificadorCheque}`} cheque={c} />
  ));

  return (
    <DetallePrestamoTable
      header={headerCells}
      body={getBodyCells(chequesToShow)}
      paginationConfig={{
        page: pagination.page,
        pageSize: pagination.pageSize,
        totalElements: pagination.totalElements,
      }}
      onPagination={pagination.onPagination}
      hiddenContent={chequeHiddenDetails}
    />
  );
};

export default TablaDetalleCheques;

const headerCells: Cell[] = [
  {
    ...DefaultSmHeaderCell("Cheque"),
    align: "center",
    textVariant: "h4",
  },
  {
    ...DefaultSmHeaderCell("Monto"),
    align: "center",
    textVariant: "h4",
  },
  {
    ...DefaultSmHeaderCell("Vencimiento"),
    textVariant: "h4",
  },
];

const getBodyCells = (cheques: DetalleCheque[]): Cell[][] => {
  return cheques.map((c) => [
    DefaultSmBodyCell(c.IdentificadorCheque.toString()),
    DefaultSmBodyCell(NumberToMoneyConverter(c.Total, false)),
    DefaultSmBodyCell(DateToStringConverter(c.FechaVto)),
  ]);
};

interface ChequeHiddenDetailProps {
  cheque: DetalleCheque;
}

const ChequeHiddenDetail = ({ cheque }: ChequeHiddenDetailProps) => {
  return (
    <div className="prestamo-table-hidden-section">
      <DrawerItemsForm>
        <DrawerTextDataItem label={"CUIT"} value={cheque.Cuit} />
        <DrawerTextDataItem label={"Titular"} value={cheque.Titular} />
        <DrawerTextDataItem
          label={"Plazo"}
          value={`${cheque.Plazo.toString()} días`}
        />
        <DrawerTextDataItem
          label={"Fecha Emisión"}
          value={DateToStringConverter(cheque.FechaEmision)}
        />
        <DrawerTextDataItem
          label={"Fecha Pago"}
          value={DateToStringConverter(cheque.FechaPago)}
        />
        {cheque?.FechaCancelacion && (
          <DrawerTextDataItem
            label={"Fecha Cancelación"}
            value={DateToStringConverter(cheque.FechaCancelacion)}
          />
        )}
        <DrawerTextDataItem
          label={"Es a la Orden"}
          value={cheque.EsNoOrden ? "NO" : "SÍ"}
        />
        <DrawerTextDataItem
          label={"Entidad Bancaria"}
          value={cheque.EntidadBancaria}
        />
        <DrawerTextDataItem label={"Plaza"} value={cheque.Plaza.toString()} />
        <DrawerTextDataItem
          label={"Es Cheque Electrónico"}
          value={cheque.EsChequeElectronico ? "SÍ" : "NO"}
        />
        <DrawerTextDataItem
          label={"Tasa Servicio"}
          value={NumberToMoneyConverter(cheque.TasaServicio)}
        />
        <DrawerTextDataItem
          label={"Comisión"}
          value={NumberToMoneyConverter(cheque.Comision)}
        />
      </DrawerItemsForm>
    </div>
  );
};
