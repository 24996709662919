import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import colors from "../../../../assets/_themes-vars.module.scss";
import Icon from "../../../../components/Icon/Icon";
import { IconEnum } from "../../../../components/Icon/Icon.props";
import Filters from "../Filters/Filters";
import { DesktopViewProps } from "./DesktopView.props";

import { UseUsuario } from "../../../../contexts/UsuarioContext";
import { NumberToMoneyConverter } from "../../../../components/Formatters/Formatters";
import { DownloadExtracto } from "../../../../components/DownloadButton/Download.function";
import { useSnackbar } from "notistack";
import { CuentaEspecificaFiltersToGetMovements } from "../../../../services/CuentaService/cuenta.service.adapter";

const DesktopView: React.FC<DesktopViewProps> = ({
  openFilters,
  setOpenFilters,
  cuenta,
  filters,
  hasFilters,
  clearAllHandler,
  applyFiltersButtonHandler,
  ctaPlus,
}) => {
  const { selectedCuentaUsuario } = UseUsuario();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Accordion
      color="primary"
      sx={{
        width: "100%",
        ".MuiButtonBase-root": {
          minHeight: "0px !important",
        },
      }}
      expanded={openFilters}
      onChange={() => setOpenFilters((prev) => !prev)}
    >
      <AccordionSummary
        expandIcon={
          <Icon icon={IconEnum.RIGHT} sx={{ color: colors.primaryMain }} />
        }
        sx={{
          flexDirection: "row-reverse",
          border: `1px solid ${colors.primaryMain}`,
          borderRadius: openFilters ? undefined : "1rem",
          borderTopLeftRadius: openFilters ? "1rem" : undefined,
          borderTopRightRadius: openFilters ? "1rem" : undefined,
          height: "49px",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h3" sx={{ color: colors.primaryMain }}>
              Filtros
            </Typography>
            <Chip
              sx={{ mx: "0.5rem" }}
              label={`Moneda: ${cuenta?.Moneda}`}
              variant="outlined"
              color="primary"
            />
            <Chip
              label={`Saldo: ${NumberToMoneyConverter(
                ctaPlus
                  ? selectedCuentaUsuario?.SaldoCuentaRemunerada
                  : selectedCuentaUsuario?.Saldo
              )}`}
              variant="outlined"
              color="primary"
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            onClick={(e) => e.stopPropagation()}
          >
            {!ctaPlus && (
              <>
                <IconButton
                  color="success"
                  disableRipple
                  disableTouchRipple
                  sx={{padding:0}}
                  onClick={() =>
                    DownloadExtracto(
                      CuentaEspecificaFiltersToGetMovements({
                        filters: filters,
                      }),
                      enqueueSnackbar,
                      "csv"
                    )
                  }
                >
                  <Icon icon={IconEnum.CSV} />
                </IconButton>
                <IconButton
                  color="error"
                  disableRipple
                  disableTouchRipple
                  sx={{padding:0}}
                  onClick={() =>
                    DownloadExtracto(
                      CuentaEspecificaFiltersToGetMovements({
                        filters: filters,
                      }),
                      enqueueSnackbar,
                      "pdf"
                    )
                  }
                >
                  <Icon icon={IconEnum.PDF} />
                </IconButton>
              </>
            )}
            <Chip
              label={"Limpiar"}
              disabled={!hasFilters}
              color="primary"
              onClick={() => clearAllHandler()}
              icon={
                <Icon
                  icon={IconEnum.CLEAR}
                  sx={{
                    color: `${colors.lightTextSecondary} !important`,
                  }}
                />
              }
            />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          px: "1rem",
          py: "0.25rem",
          border: openFilters ? `1px solid ${colors.primaryMain}` : undefined,
          borderBottomLeftRadius: "1rem",
          borderBottomRightRadius: "1rem",
          borderTop: "0",
        }}
      >
        <Filters buttonHandler={applyFiltersButtonHandler} ctaPlus={ctaPlus} />
      </AccordionDetails>
    </Accordion>
  );
};

export default DesktopView;
