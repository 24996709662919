import HomeApi from "../../config/axios";

export interface Contacto {
  ID: string;
  NSocio: string;
  NFilial: string;
  NombreSocio: string;
  Documento: string;
  Referencia: string;
}

const ContactoService = {

  GetAll: (): Promise<Contacto[]> => {
    return new Promise((resolve) => {
      HomeApi.get<Contacto[]>("Agenda/Contactos").then(response => {
        resolve(response.data)
      })
        .catch(() => { })
    });
  },
  Set: (NContacto: string, Referencia: string): Promise<Contacto> => {
     return new Promise((resolve, reject) => {
      if (Referencia.length > 45) reject("La referencia no puede superar los 45 caracteres.");
      HomeApi.post<Contacto>(`Agenda`, { NContacto: NContacto.toString(), Referencia: Referencia}).then(response => {
        resolve(response.data)
      })
        .catch((error) => { reject(error.message)})
    });
  },
  UpdateByID: (ID: string, Referencia: string): Promise<Contacto> => {
    return new Promise((resolve, reject) => {
      HomeApi.patch<Contacto>(`Agenda/Contactos/${ID}`, { ID: ID.toString(), Referencia: Referencia}).then(response => {
        resolve(response.data)
      })
        .catch((error) => { reject(error.message) })
    });
  },
  DeleteById: (ID: string): Promise<Contacto[]> => {
    return new Promise((resolve) => {
      HomeApi.delete<Contacto[]>(`Agenda/Contactos/${ID}`).then(response => {
        resolve(response.data)
      })
        .catch(() => { })
    });
  },
  GetUsuario: (searchedNSocio: string): Promise<Contacto[]> => {
    return new Promise<Contacto[]>((resolve, reject) => {
      HomeApi.get<Contacto[]>("Agenda/Usuario", { params: { NSocio: searchedNSocio.toString() } }).then(response => {
        resolve(response.data.map(x => { return { ...x, NroCuenta: searchedNSocio.toString() } }))
      })
        .catch((error) => reject(error))
    })
  },
}

export default ContactoService;