import "./LoginPage.scss";
import Box from '@mui/material/Box';
import {useTheme } from "@mui/material";
import logo from '../../assets/images/logo_mutual.png';



const LoginHeader = () => {
    const theme = useTheme();
  
    return (
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'center'
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center'
          }
        }}
      >
  
        <div className="login__logo">
           <div >
            <img src={logo} width="225px" alt="Logo" />
          </div>          
        </div>
      </Box>
    )
  }

  export default LoginHeader;