import colors from "../../../assets/_themes-vars.module.scss";

export const NotificationMenuStyles = {
  List: {
    width: 350,
    overflow: "auto",
    padding: 0,
  },
  NoNotificationsMenuItem: {
    cursor: "auto",
    "&:hover": { backgroundColor: "transparent" },
    justifyContent: "center",
    color: colors.grey900,
  },
  LoaderMenuItem: {
    cursor: "auto",
    "&:hover": { backgroundColor: "transparent" },
    justifyContent: "center",
    padding: 0,
    paddingTop: "8px",
  },
};

export const NotificationMenuHeaderStyles = {
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingX: "10px",
    width: "100%",
    height:"40px"
  },
  HeaderTitleTotalUnread: { color: colors.primaryMain, margin: 0 },
  HeaderDivider: { borderColor: colors.primaryMain, margin: "0 !important" },
};

export const NotificationMenuItemStyles = {
  MenuItem: {
    cursor: "auto",
    padding: 0,
  },
  MainContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      backgroundColor: colors.primary800,
      color: colors.lightTextPrimary,
    },
    width: "100%",
    height: "60px",
    transition: "all 0.3s ease-out",
  },
  TextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  Subtitle: { margin: 0, fontWeight: "lighter" },
};
