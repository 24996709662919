import { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { CarouselProps } from "./Carousel.props";
import CarouselSwipeContainer from "./CarouselSwipeContainer";
import colors from "../../assets/_themes-vars.module.scss";
import useBreakpoints from "../../hooks/breakpoints.hook";

const CarouselComponent = (props: CarouselProps) => {
  const { isSmDown } = useBreakpoints();

  const [carouselIndex, setCarouselIndex] = useState(0);

  const deactivateIndicator = props.items.length < 2;
  const deactivateNavButtons = deactivateIndicator || isSmDown;

  const indicatorIconButtonStyle = () => {
    return deactivateIndicator
      ? {
          style: {
            opacity: "0",
            cursor: "default",
          },
        }
      : {
          style: {},
        };
  };

  const updateIndex = (newIndex: number) => {
    if (newIndex !== carouselIndex) {
      setCarouselIndex(newIndex);
    }
  };

  useEffect(() => {
    if (props.setNumber) {
      props.setNumber(carouselIndex);
    }
  }, [carouselIndex]);

  let carouselStyle = props.style ? { ...props.style } : {};

  return (
    <>
      <Carousel
        className={props?.className}
        sx={{
          ...carouselStyle,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        autoPlay={false}
        onChange={updateIndex}
        index={carouselIndex}
        navButtonsAlwaysInvisible={deactivateNavButtons}
        swipe={false}
        navButtonsProps={{
          style: {
            backgroundColor: "text.primary",
          },
        }}
        navButtonsWrapperProps={{ style: { height: "calc(100% - 47px)" } }}
        indicatorIconButtonProps={indicatorIconButtonStyle()}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: colors.grey300,
          },
        }}
      >
        {props.items?.map((item, i) => (
          <CarouselSwipeContainer
            key={item.toString()}
            index={i}
            setCarouselIndex={updateIndex}
            totalItems={props.items.length}
          >
            <Item item={item} />
          </CarouselSwipeContainer>
        ))}
      </Carousel>
    </>
  );
};

export { CarouselComponent as Carousel };

interface CarouselItem {
  item: JSX.Element;
}

function Item(props: CarouselItem) {
  return <>{props.item}</>;
}
