import { Divider, Typography } from "@mui/material";
import "../../layout/GenericPageLayout/Page.scss";

export const PageHeader = (props: { title: String }) => {
  return (
    <div className="generic-header">
      <Typography variant="h1" sx={{ color: "text.primary" }}>
        {props.title}
      </Typography>
      <Divider
        sx={{
          width: "100%",
          mb: "1rem",
          mt: "0.35rem",
          borderColor: "text.primary",
        }}
      />
    </div>
  );
};
