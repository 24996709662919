import { useState } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Contacto } from "../../../services/ContactoService/contacto.service";
import "../../../layout/GenericPageLayout/Page.scss";
import Divider from "@mui/material/Divider";
import BorrarContacto from "./BorrarContacto";
import Modal from "@mui/material/Modal";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useTransferenciaActionContext } from "../../../contexts/TransferenciaActionContext";
import { TransferenciaActionType } from "../enums/transferencia-actions.enum";

const ContactoEspecifico = (props: { contacto: Contacto }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { setTransferenciaAction } = useTransferenciaActionContext();

  const showAction = (dest: Contacto) => {
    setTransferenciaAction({
      accion: TransferenciaActionType.TRANSFERENCIA_INDIVIDUAL,
      contacto: dest,
      isOpenDrawer: true,
    });
  };

  const editAction = (dest: Contacto) => {
    setTransferenciaAction({
      accion: TransferenciaActionType.EDITAR_CONTACTO,
      contacto: dest,
      isOpenDrawer: true,
    });
  };

  return (
    <div>
      <Card className="contacto-especifico-container">
        <CardContent className="contacto-especifico-data-container">
          <Typography variant="h5">
            Nro. Socio: {props.contacto.NSocio ? props.contacto.NSocio : ""}
          </Typography>
          <Typography variant="h5">
            {props.contacto.NombreSocio ? props.contacto.NombreSocio : ""}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {props.contacto.Referencia
              ? `Referencia: ${props.contacto.Referencia}`
              : ""}
          </Typography>
        </CardContent>
        <Box className="contacto-especifico-actions-container">
          <IconButton
            color="primary"
            size="small"
            aria-label="edit"
            onClick={() => {
              editAction(props.contacto);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            size="small"
            aria-label="delete"
            onClick={() => setModalIsOpen(true)}
          >
            <DeleteIcon />
          </IconButton>

          <IconButton
            color="primary"
            size="small"
            aria-label="transferir"
            onClick={() => {
              showAction(props.contacto);
            }}
          >
            <CurrencyExchangeIcon />
          </IconButton>
        </Box>
      </Card>
      <Divider
        sx={{
          width: "100%",
        }}
      />
      <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <BorrarContacto
          setModalIsOpen={setModalIsOpen}
          idContacto={props.contacto.ID}
        />
      </Modal>
    </div>
  );
};

export default ContactoEspecifico;
