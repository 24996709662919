import { Divider, Typography } from "@mui/material";
import HiddenBalanceButton from "../../../components/HiddenBalanceButton/HiddenBalanceButton";
import { UseUsuario } from "../../../contexts/UsuarioContext";

interface HomePageHeaderProps {
  hiddenBalances: boolean;
  setHiddenBalances: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = (props: HomePageHeaderProps) => {
  const { hiddenBalances, setHiddenBalances } = props;
  const { selectedCuentaUsuario } = UseUsuario();
  const nameAndNum = selectedCuentaUsuario
    ? `${selectedCuentaUsuario?.NombreSocio} (${selectedCuentaUsuario?.NroSocio})`
    : "";

  return (
    <div className="generic-header">
      <Typography variant="h2" sx={{ color: "text.primary", my: "0" }}>
        HOLA {nameAndNum}
      </Typography>
      <Typography variant="h5" sx={{ color: "text.primary", my: "0" }}>
        Ver Balances:
        <HiddenBalanceButton
          onClick={() => {
            hiddenBalances ? setHiddenBalances(false) : setHiddenBalances(true);
          }}
          hiddenBalances={hiddenBalances}
        />
      </Typography>
      <Divider
        sx={{
          width: "100%",
          mb: "1rem",
          mt: "0.3rem",
          borderColor: "text.primary",
        }}
      />
    </div>
  );
};

export default Header;
