import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import {
  MetodoPagoData,
  PrestamoResumenCardProps,
} from "./PrestamoPageCard.props";
import "./PrestamoPageCardComponent.scss";
import colors from "../../../assets/_themes-vars.module.scss";
import ViewMoreButton from "../../ViewMoreButton/ViewMoreButton";
import {
  DateToStringConverter,
  NumberToMoneyConverter,
} from "../../Formatters/Formatters";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { useDetallePrestamo } from "../../../contexts/DetallePrestamoContext";
import { TipoPrestamoEnum } from "../../../pages/Prestamos/enums/TipoPrestamo.enum";
import useBreakpoints from "../../../hooks/breakpoints.hook";

const PrestamoPageCard = (props: PrestamoResumenCardProps) => {
  const { setDetallePrestamo } = useDetallePrestamo();
  const { isSmDown } = useBreakpoints();

  const handleOpenDrawer = () => {
    setDetallePrestamo({
      isOpenDrawer: true,
      tipoPrestamo: props.tipoPrestamo,
      nroComprobantePrestamo: props.nComprobante,
      lineaPrestamoId: props.lineaId,
    });
  };

  const specificData = GetSpecificDataCardPrestamo({
    tipoPrestamo: props.tipoPrestamo,
    metodoPagoData: props.metodoPagoData,
    isSmDown: isSmDown,
  });

  return (
    <Card className="prestamo-page-card" onClick={handleOpenDrawer}>
      <CardContent className="prestamo-page-card-content">
        <Box className="prestamo-page-line1-container">
          <Typography variant="body2" sx={{ color: colors.lightTextTertiary }}>
            {`Imposición: ${DateToStringConverter(props.fechaImposicion)}`}
          </Typography>
          <Typography variant="h1" className="prestamo-card-importe-total">
            {NumberToMoneyConverter(props?.monto)}
          </Typography>
          <ProgressBar
            currentValue={specificData.currentProgress}
            total={props.metodoPagoData.total}
            sx={{ maxWidth: "max(70%,200px)" }}
          />
          <Stack direction="column">
            <Chip
              className="prestamo-card-vencimiento"
              label={specificData.vencimientoLegend}
              color={props.metodoPagoData?.esMoroso ? "error" : "success"}
              variant={"filled"}
            />
          </Stack>
        </Box>
        <Box className="prestamo-page-line3-container">
          <Typography variant="body2" sx={{ color: colors.lightTextTertiary }}>
            {specificData.footerMetodoLegend}
            {isSmDown && <br />}
            {specificData.footerImporteLegend}
          </Typography>
        </Box>
      </CardContent>
      <CardActions className="prestamo-card-actions">
        <ViewMoreButton onClick={handleOpenDrawer} />
      </CardActions>
      {specificData.destinoLegend !== "" && (
        <Typography variant="body2" className="prestamo-card-destino">
          {specificData.destinoLegend}
        </Typography>
      )}
    </Card>
  );
};

export default PrestamoPageCard;

interface SpecificDataCardPrestamoProps {
  tipoPrestamo: TipoPrestamoEnum;
  metodoPagoData: MetodoPagoData;
  isSmDown: boolean;
}

interface SpecificDataCardPrestamoResult {
  currentProgress: number;
  vencimientoLegend: string;
  footerMetodoLegend: string;
  footerImporteLegend: string;
  destinoLegend: string;
}

function GetSpecificDataCardPrestamo(
  props: SpecificDataCardPrestamoProps
): SpecificDataCardPrestamoResult {
  const vencimiento = DateToStringConverter(props.metodoPagoData.vencimiento);
  const importeProximo = NumberToMoneyConverter(props.metodoPagoData.importe);
  const metodoInterval = `${props.metodoPagoData.actual} de ${props.metodoPagoData.total} `;

  let result: SpecificDataCardPrestamoResult = {
    currentProgress: props.metodoPagoData.actual - 1,
    vencimientoLegend: `Vto: ${vencimiento}`,
    footerMetodoLegend: `Próx Cuota: ${metodoInterval}`,
    footerImporteLegend: props.isSmDown
      ? `Importe: ${importeProximo}`
      : `(${importeProximo})`,
    destinoLegend: "",
  };

  if (props.tipoPrestamo === TipoPrestamoEnum.ANTICIPADO) {
    result = {
      ...result,
      vencimientoLegend: `Próx Vto: ${vencimiento}`,
      footerMetodoLegend: `Cheque: ${metodoInterval}`,
      destinoLegend: "ANTICIPADO",
    };
  }

  if (props.tipoPrestamo === TipoPrestamoEnum.VENCIDO) {
    result = {
      ...result,
      currentProgress: props.metodoPagoData.actual,
      footerMetodoLegend: `Día: ${metodoInterval} (plazo)`,
      destinoLegend: "VENCIDO",
      footerImporteLegend: "",
    };
  }

  return result;
}
