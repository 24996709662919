import { OptionsObject } from "notistack";

const defaultWithAnchorOrigin: OptionsObject = {
  anchorOrigin: { horizontal: "right", vertical: "bottom" },
};

export const homeDisabledSnackbar: OptionsObject = {
  ...defaultWithAnchorOrigin,
  variant: "warning",
  autoHideDuration: 10000,
};

export const defaultErrorSnackbar: OptionsObject = {
  ...defaultWithAnchorOrigin,
  variant: "error",
};

export const longErrorSnackbar: OptionsObject = {
  ...defaultErrorSnackbar,
  autoHideDuration: 10000,
};

export const defaultSuccessSnackbar: OptionsObject = {
  ...defaultWithAnchorOrigin,
  variant: "success",
};

export const longSuccessSnackbar: OptionsObject = {
  ...defaultSuccessSnackbar,
  autoHideDuration: 10000,
};