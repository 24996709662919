import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  MenuItem,
  InputAdornment,
  IconButton,
  Chip,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Loader from "../../../components/Loader/Loader";
import "../InversionConstitucionPage.scss";
import { OrdenCertificado } from "../../../services/InversionService/inversion.service";
import AccordionSeccion from "../AccordionSeccion/AccordionSeccion";
import { styled } from "@mui/material";
import colors from "../../../assets/_themes-vars.module.scss";
import { PasoConstitucion1 } from "../PasoConstitucion";
import ContactoService, {
  Contacto,
} from "../../../services/ContactoService/contacto.service";
import { useSnackbar } from "notistack";
import useBreakpoints from "../../../hooks/breakpoints.hook";
import { NumericFormat } from "react-number-format";
import { UseUsuario } from "../../../contexts/UsuarioContext";
import TextField from "../../../components/TextField/TextField";

const CertificadoConstitucionContent = (props: {
  ordenesCertificado: OrdenCertificado[];
  selectedOrdenCertificado: OrdenCertificado;
  setSelectedOrdenCertificado: React.Dispatch<
    React.SetStateAction<OrdenCertificado>
  >;
  pasoActual: number;
  pasoValidado: boolean;
  titulares: Contacto[];
  selectedTitulares: Contacto[];
  setSelectedTitulares: React.Dispatch<React.SetStateAction<Contacto[]>>;
}) => {
  const {
    ordenesCertificado,
    selectedOrdenCertificado,
    setSelectedOrdenCertificado,
    pasoActual,
    pasoValidado,
    titulares,
    selectedTitulares,
    setSelectedTitulares,
  } = props;

  const bp = useBreakpoints();
  const { selectedCuentaUsuario, cuentasUsuario } = UseUsuario();

  const CustomizeTextField = styled(TextField)({
    width: "100%",
    marginTop: ".5rem",
    "& label": {
      color: colors.primaryMain,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.primaryMain,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colors.primaryMain,
      },
      "&:hover fieldset": {
        borderColor: colors.primaryMain,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.primaryMain,
      },
    },
  });

  const AccordionCertificadoContent = () => {
    const [numeroSocio, setNumeroSocio] = useState<number>(null);
    const [buscandoTitular, setBuscandoTitular] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      if (buscandoTitular) buscarTitular();
    }, [buscandoTitular]);

    const buscarTitular = () => {
      if (![null, undefined].includes(numeroSocio)) {
        if (selectedTitulares && selectedTitulares.length >= 2) {
          setBuscandoTitular(false);
          enqueueSnackbar(`Solo se permiten hasta 2 Cotitulares`, {
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          });
        } else {
          ContactoService.GetUsuario(`${numeroSocio}`)
            .then((responses) => {
              if (responses && responses.length != 0) {
                const response = responses[0];
                const ocurrencias = selectedTitulares.filter(
                  (x) =>
                    x.ID == response.ID &&
                    x.NSocio == response.NSocio &&
                    x.Documento == response.Documento
                ).length;
                if (ocurrencias == 0)
                  setSelectedTitulares([...selectedTitulares, response]);
                else setNumeroSocio(null);
              } else {
                enqueueSnackbar(`No se ha localizado el socio ${numeroSocio}`, {
                  variant: "error",
                  anchorOrigin: { horizontal: "right", vertical: "bottom" },
                });
              }
            })
            .catch((error) =>
              enqueueSnackbar(`Hubo un error al obtener el titular`, {
                variant: "error",
                anchorOrigin: { horizontal: "right", vertical: "bottom" },
              })
            )
            .finally(() => {
              setBuscandoTitular(false);
              setTimeout(() => {
                if (bp.isMdUp)
                  document
                    .getElementById("inversion-input-titular-busqueda")
                    .focus();
              }, 500);
            });
        }
      } else setBuscandoTitular(false);
    };

    const deleteSeleccionTitular = (titularEliminado: Contacto) => {
      const titularesActualizados: Contacto[] = selectedTitulares.filter(
        (x) =>
          !(
            x.Documento == titularEliminado.Documento &&
            x.NSocio == titularEliminado.NSocio
          )
      );
      setSelectedTitulares(titularesActualizados);
    };

    useEffect(() => {
      selectedTitulares.map((selectedTitular) => {
        if (selectedTitular.NSocio === selectedCuentaUsuario?.NroSocio) {
          deleteSeleccionTitular(selectedTitular);
        }
      });
    }, [selectedCuentaUsuario]);

    const filteredAccounts = useMemo(
      () =>
        cuentasUsuario?.length
          ? cuentasUsuario.filter(
              (c) =>
                c?.NroSocio !== selectedCuentaUsuario?.NroSocio &&
                c?.FilialId === selectedCuentaUsuario?.FilialId &&
                !selectedTitulares.some((t) => t.NSocio === c?.NroSocio) &&
                c.Cuit !== selectedCuentaUsuario.Cuit &&
                !selectedTitulares.some(
                  (t) =>
                    t.Documento ===
                    c.Cuit.substring(2, selectedCuentaUsuario.Cuit.length - 1)
                )
            )
          : [],
      [cuentasUsuario, selectedCuentaUsuario, selectedTitulares]
    );

    return ordenesCertificado ? (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <CustomizeTextField
            error={!pasoValidado && !selectedOrdenCertificado}
            helperText={
              !pasoValidado &&
              !selectedOrdenCertificado &&
              "Debe seleccionar una orden"
            }
            variant="outlined"
            select
            label="Orden de Certificado"
            size="small"
            value={ordenesCertificado.indexOf(selectedOrdenCertificado)}
            onChange={(e) =>
              setSelectedOrdenCertificado(ordenesCertificado[e.target.value])
            }
            inputProps={{ readOnly: pasoActual != PasoConstitucion1.pagina }}
          >
            {ordenesCertificado.map((ordenCertificado, index) => {
              if (selectedTitulares.length > 0) {
                if (ordenCertificado?.Nombre !== "UNIPERSONAL")
                  return (
                    <MenuItem key={index} value={index}>
                      {ordenCertificado.Nombre}
                    </MenuItem>
                  );
              } else {
                return (
                  <MenuItem key={index} value={index}>
                    {ordenCertificado.Nombre}
                  </MenuItem>
                );
              }
            })}
          </CustomizeTextField>
        </Grid>
        {selectedOrdenCertificado?.Nombre !== "UNIPERSONAL" && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <CustomizeTextField
                error={!pasoValidado && selectedTitulares.length === 0}
                helperText={
                  !pasoValidado &&
                  selectedTitulares.length === 0 &&
                  "Debe seleccionar al menos un cotitular"
                }
                id="inversion-input-titular-busqueda"
                variant="outlined"
                select
                label="Cotitulares"
                size="small"
                value={numeroSocio}
                disabled={!filteredAccounts?.length}
                onChange={(event) => {
                  if (!event.target.value || event.target.value === undefined) {
                    setNumeroSocio(null);
                  } else {
                    setBuscandoTitular(true);
                    setNumeroSocio(parseInt(event.target.value));
                  }
                }}
                inputProps={{
                  readOnly: pasoActual !== PasoConstitucion1.pagina,
                }}
              >
                {filteredAccounts.map((cuentaUsuario, index) => (
                  <MenuItem key={index} value={cuentaUsuario?.NroSocio}>
                    {cuentaUsuario?.NroSocio}
                  </MenuItem>
                ))}
              </CustomizeTextField>
            </Grid>
            <Grid item xs={12}>
              {selectedTitulares && selectedTitulares.length ? (
                selectedTitulares.map((selectedTitular) => (
                  <>
                    <Chip
                      label={`${selectedTitular.NSocio} - ${selectedTitular.NombreSocio}`}
                      color="primary"
                      onDelete={() => deleteSeleccionTitular(selectedTitular)}
                      disabled={pasoActual != PasoConstitucion1.pagina}
                      sx={{
                        mt: 1,
                        width: bp.is1300Up
                          ? "24%"
                          : bp.isMdUp || bp.isSmUp
                          ? "49%"
                          : "100%",
                      }}
                    />
                    <span>&nbsp;</span>
                  </>
                ))
              ) : (
                <p style={{ color: colors.primaryMain, margin: 0 }}>
                  Sin titulares seleccionados
                </p>
              )}
            </Grid>
          </>
        )}
      </Grid>
    ) : (
      <Loader label="Cargando certificados"></Loader>
    );
  };

  return (
    <Grid container marginTop={2}>
      <Grid item xs={12}>
        <AccordionSeccion
          legend="Certificado"
          content={<AccordionCertificadoContent />}
        />
      </Grid>
    </Grid>
  );
};

export default CertificadoConstitucionContent;
