import { useState } from "react";
import ContactoService, {
  Contacto,
} from "../../../services/ContactoService/contacto.service";

interface UseSearchContactoProps {
  currentNroSocio: string;
  contactos: Contacto[];
  settedContact?: Contacto;
  existingContactoInvalid?: boolean;
}

interface UseSearchContactoResults {
  isSearching: boolean;
  contacto: Contacto;
  errorMessage: string;
  isExistingContact: boolean;
  searchSocio: (toSearchSocio: string) => void;
}

const defaultErrorSearchMessage = "El número de socio ingresado es incorrecto";

const useSearchContacto = (
  props: UseSearchContactoProps
): UseSearchContactoResults => {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [contact, setContact] = useState<Contacto>(props?.settedContact);
  const [isExistingContact, setIsExistingContact] = useState<boolean>(
    Boolean(props?.settedContact)
  );
  const [errorMessage, setErrorMessage] = useState<string>(undefined);

  const searchSocioInDB = (searchedSocio: string) => {
    ContactoService.GetUsuario(searchedSocio)
      .then((result) => {
        if (result.length === 0) {
          setErrorMessage(defaultErrorSearchMessage);
        } else {
          setContact(result[0]);
        }
      })
      .catch(() => setErrorMessage(defaultErrorSearchMessage))
      .finally(() => setIsSearching(false));
  };

  const refreshStatesToStartSearch = () => {
    setIsSearching(true);
    setErrorMessage(undefined);
    setContact(undefined);
    setIsExistingContact(false);
  };

  const searchSocioHandler = (searchedSocio: string) => {
    refreshStatesToStartSearch();

    const hasErrorForEmptySocio = isEmptySocio(searchedSocio);

    const hasErrorForSameSocio =
      searchedSocio.toString() === props.currentNroSocio.toString();

    const existingContact = searchSocioInSavedContacts(
      searchedSocio,
      props.contactos
    );

    const contactAlreadyExist = existingContact !== undefined;

    const hasErrorForExistingContact = isErrorForExistingContact(
      props.existingContactoInvalid,
      existingContact
    );

    handleErrors({
      hasErrorForEmptySocio,
      hasErrorForSameSocio,
      hasErrorForExistingContact,
      existingContact,
      setErrorMessage,
    });

    if (contactAlreadyExist) {
      setContact(existingContact);
      setIsExistingContact(true);
    }

    const contactMustBeSearchedInDB =
      !contactAlreadyExist &&
      !hasErrorForEmptySocio &&
      !hasErrorForSameSocio &&
      !hasErrorForExistingContact;

    if (contactMustBeSearchedInDB) {
      searchSocioInDB(searchedSocio);
    } else {
      setTimeout(() => {
        setIsSearching(false);
      }, 200);
    }
  };

  return {
    contacto: contact,
    errorMessage: errorMessage,
    isSearching: isSearching,
    isExistingContact: isExistingContact,
    searchSocio: searchSocioHandler,
  };
};

export default useSearchContacto;

function isEmptySocio(searchedSocio: string): boolean {
  return !searchedSocio || searchedSocio === "";
}

function searchSocioInSavedContacts(
  searchedSocio: string,
  contacts: Contacto[]
): Contacto {
  return contacts?.find(
    (c) => c.NSocio.toLowerCase() === searchedSocio.toLowerCase()
  );
}

function isErrorForExistingContact(
  existingContactoInvalid: boolean,
  existingContact: Contacto
): boolean {
  return existingContactoInvalid && existingContact !== undefined;
}

interface HandleErrorsProps {
  hasErrorForEmptySocio: boolean;
  hasErrorForSameSocio: boolean;
  hasErrorForExistingContact: boolean;
  setErrorMessage: (errMsg: string) => void;
  existingContact: Contacto;
}

function handleErrors(props: HandleErrorsProps) {
  if (props.hasErrorForEmptySocio || props.hasErrorForSameSocio)
    props.setErrorMessage(defaultErrorSearchMessage);
  if (props.hasErrorForExistingContact)
    props.setErrorMessage(
      `El socio ${props.existingContact.NombreSocio} ya se encuentra en su agenda de contactos`
    );
}
