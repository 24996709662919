import { useState, useEffect } from "react";
import "../LoginPage.scss";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Checkbox } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { LoginPayload } from "../../../services/Login/IAuthentication.interface";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoginOption from "../LoginOptions";
import { useForm } from "react-hook-form";
import AuthenticationService from "../../../services/Login/Authentication.service";
import SessionService from "../../../services/session.service";
import { useSnackbar } from "notistack";
import TextField from "../../../components/TextField/TextField";
import PasswordField from "../../../components/PasswordField/PasswordField";

const LoginForm = () => {
  const [dni, setDni] = useState("");
  const [usuarioINS, setUsuarioINS] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const userSavedData = SessionService.GetLoginInformation();
    if (userSavedData.rememberUser && userSavedData.dni !== "") {
      setDni(userSavedData.dni);
      setUsuarioINS(userSavedData.usuarioINS);
      setChecked(true);
    } else setChecked(false);
  }, []);

  const ChangeRememberMe = (event) => {
    setChecked(event.target.checked);
  };

  const Clear = () => {
    setDni("");
    setUsuarioINS("");
    setChecked(false);
    clearErrors("DNI");
    clearErrors("usuarioINS");
  };

  const LoadDNI = (e) => {
    if (e.target.value.length === 0) {
      Clear();
    } else {
      setDni(e.target.value);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let payload: LoginPayload = {
      NroDocumento: dni,
      Usuario: usuarioINS,
      Password: password,
      RememberUser: checked,
    };
    AuthenticationService.Login(payload)
      .then((response) => {
        navigate("/Home");
      })
      .catch(() => {
        enqueueSnackbar(`Usuario o Clave Incorrectos`, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      })
      .finally(() => setLoading(false));
  };
  
  return (
    <Box
      component="form"
      sx={{
        display: "flex",
      }}
    >
      <div className="login__form">
        <div>
          <h2> Ingrese aquí para operar con su Entidad</h2>
          <br></br>
        </div>
        <TextField
          className="login__textBox"
          id="textField_NroDoc"
          label="Nro. de Documento"
          autoComplete="off"
          type="number"
          placeholder="Nro. de Documento"
          {...register("DNI", {
            required: {
              value: [null, undefined, ""].includes(dni),
              message: "Debe ingresar su DNI",
            },
            minLength: {
              value: 5,
              message: "Al menos 7 números para el DNI",
            },
            maxLength: {
              value: 11,
              message: "Solo 8 números para el DNI",
            },
            pattern: {
              value: /^[0-9]/,
              message: "Formato incorrecto, Debe cargar entre 7 y 8 números.",
            },
          })}
          value={dni}
          onChange={(e) => LoadDNI(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {dni ? (
                  <IconButton onClick={Clear} edge="end">
                    <HighlightOffIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
        />
        <p className="error">{errors.DNI && errors.DNI.message}</p>
        <TextField
          className="login__textBox"
          id="textField_UsuarioINS"
          label="Usuario"
          placeholder="Usuario"
          {...register("usuario", {
            required: {
              value: [null, undefined, ""].includes(usuarioINS),
              message: "Debe ingresar su usuario",
            },
            minLength: {
              value: 5,
              message:
                "Se deben ingresar al menos 5 caracteres para el usuario",
            },
            maxLength: {
              value: 25,
              message: "Se deben ingresar hasta 25 caracteres para el usuario",
            },
            pattern: {
              value: /^[A-Za-z0-9._%$]{5,25}/i,
              message:
                "Formato incorrecto. Solo se permiten letras, números y los caracteres . _ % $",
            },
          })}
          autoComplete="off"
          value={usuarioINS}
          onChange={(e) => setUsuarioINS(e.target.value)}
        />
        <p className="error">
          {errors.usuarioINS && errors.usuarioINS.message}
        </p>
        <div className="login__textBox">
          <PasswordField
            label={"Clave"}
            onChange={(newPass: string) => setPassword(newPass)}
          />
        </div>
        <Checkbox
          checked={checked}
          onChange={ChangeRememberMe}
          inputProps={{ "aria-label": "controlled" }}
        />
        Recordarme
        <LoadingButton
          className="login__btn"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          loadingPosition="center"
          loading={loading}
          sx={{
            ".MuiLoadingButton-loadingIndicator": {
              color: "white",
            },
          }}
        >
          Iniciar Sesión
        </LoadingButton>
        <LoginOption></LoginOption>
      </div>
    </Box>
  );
};

export default LoginForm;
