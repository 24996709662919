import { Typography } from "@mui/material";
import { Contacto } from "../../../services/ContactoService/contacto.service";
import ContactoEspecifico from "./ContactoEspecifico";

interface ContactoListProps {
  cuentas: Contacto[];
}

const ContactoList = ({ cuentas }: ContactoListProps) => {
  return (
    <>
      {!cuentas?.length && (
        <Typography
          variant="h4"
          className="socio-search-error"
          sx={{ mx: "0px !important" }}
        >
          No hay contactos
        </Typography>
      )}
      {cuentas.map((c) => (
        <ContactoEspecifico
          key={`ContactoAgenta:${c.Documento}`}
          contacto={c}
        />
      ))}
    </>
  );
};

export default ContactoList;
