import { Divider } from "@mui/material";

const Divider100P = () => {
  return (
    <Divider
      sx={{
        width: "100%",
      }}
    />
  );
};

export default Divider100P;
