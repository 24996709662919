import HomeApi from "../../config/axios";
import SessionService from "../session.service";

export interface State {
  result: number;
}

const HomeService = {
  GetSystemDate: () => {
    return new Promise<string>((resolve, reject) => {
      HomeApi.get(`HomeMutual/FechaActual`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  GetTerminosYCondiciones: () => {
    return new Promise<string>((resolve, reject) => {
      HomeApi.get(`HomeMutual/TyC`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  AcceptTerminosYCondiciones: () => {
    const loginData = SessionService.GetLoginInformation();
    return new Promise<Date>((resolve, reject) => {
      HomeApi.patch(`HomeMutual/TyC`, {
        NroDocumento: loginData.dni,
        Usuario: loginData.usuarioINS,
      })
        .then((response) => {
          resolve(new Date(response.data.FechaAceptaTyC));
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
};

export default HomeService;
