import { useState, useEffect } from 'react'
import { PageHeader } from '../../components/Headers/PageHeader'
import InversionService, { Inversion } from '../../services/InversionService/inversion.service'
import InversionList from './PageComponents/InversionList';
import InversionDrawer from '../../components/Drawer/InversionDrawer/InversionDrawer';
import { Grid } from '@mui/material'
import { useSnackbar } from 'notistack';
import Loader from '../../components/Loader/Loader';
import { UseUsuario } from '../../contexts/UsuarioContext'

const InversionesPage = () => {

    const [inversiones, setInversiones] = useState<Inversion[]>([]);
    const [loadingInversiones, setLoadingInversiones] = useState(false)
    const [selectedInversion, setSelectedInversion] = useState<Inversion>(null)

    const { enqueueSnackbar } = useSnackbar()
    const { selectedCuentaUsuario } = UseUsuario()

    const getAll = () => {


        setLoadingInversiones(true)

        const { FilialId, NroSocio } = selectedCuentaUsuario

        InversionService.GetAll()
            .then(response => setInversiones(response.sort(sortByVencimiento)))
            .catch(() => enqueueSnackbar(`Hubo un error al obtener las inversiones`, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'bottom' } }))
            .finally(() => setLoadingInversiones(false))
    }

    const sortByVencimiento = (a: Inversion, b: Inversion) => {
        let compareResult = 0
        if (a.FechaVencimiento < b.FechaVencimiento)
            compareResult = -1
        else if (a.FechaVencimiento > b.FechaVencimiento)
            compareResult = 1

        return compareResult
    }

    useEffect(() => {
        if (selectedCuentaUsuario) getAll()
    }, [selectedCuentaUsuario])


    return (
        <>
            <PageHeader title={"Inversiones"} />
            {

                loadingInversiones
                    ? (<Loader label='Buscando inversiones' />)
                    : (

                        inversiones && inversiones.length
                            ? (
                                <Grid container spacing={2}>
                                    {InversionList({ inversiones: inversiones, selectedInversion: selectedInversion, setSelectedInversion: setSelectedInversion })}
                                    <InversionDrawer
                                        inversion={selectedInversion}
                                        setSelectedInversion={setSelectedInversion}
                                    />
                                </Grid>
                            )
                            : (<div style={{ textAlign: 'center' }}>No tiene ninguna inversión realizada</div>)

                    )
            }
        </>
    )
}

export default InversionesPage;