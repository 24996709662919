import { Chip, Typography } from "@mui/material";
import { CardDivider } from "../../../../components/Headers/CardDivider";
import useBreakpoints from "../../../../hooks/breakpoints.hook";

const AccountChip = (props: { numeroCuenta: number }) => {
  return (
    props.numeroCuenta !== undefined && (
      <Chip
        label={`Cuenta: ${props.numeroCuenta}`}
        variant="outlined"
        color="primary"
      />
    )
  );
};

const HeaderLastMovements = (props: { numeroCuenta: number }) => {
  const bp = useBreakpoints();
  return (
    <>
      <div
        className="generic-page-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "text.primary",
            mt: "0.25rem",
          }}
        >
          Últimos Movimientos
        </Typography>
        {bp.isSmDown ? undefined : (
          <AccountChip numeroCuenta={props.numeroCuenta} />
        )}
      </div>
      <CardDivider />
      {bp.isSmDown ? (
        <div
          className="generic-page-section"
          style={{
            display: "flex",
            marginTop: "-0.5rem",
            marginBottom: "0.5rem",
            justifyContent: "left",
          }}
        >
          <AccountChip numeroCuenta={props.numeroCuenta} />
        </div>
      ) : undefined}
    </>
  );
};

export default HeaderLastMovements;
