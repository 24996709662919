import useBreakpoints from "../../../hooks/breakpoints.hook";

export const useCardDivResponsiveStyle = () => {
  const { isSmDown, isMdDown, isXlDown } = useBreakpoints();
  if (isSmDown) {
    return {
      width: "100%",
      minWidth: "21.5rem",
      height: "auto",
    };
  }
  if (isMdDown) {
    return { width: "100%", height: "auto" };
  }
  if (isXlDown) {
    return { width: "100%", maxWidth: "70rem", height: "auto" };
  }
};
