import React from "react";
import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import "../HomeCard.scss";
import { ICtaRemuneradaProps } from "./ICtaRemuneradaCard.props";
import { HiddenBalanceText } from "../../../HiddenBalanceButton/HiddenBalanceText";
import Formatter from "../../../../hooks/formatter.hook";
import colors from "../../../../assets/_themes-vars.module.scss";
import ViewMoreButton from "../../../ViewMoreButton/ViewMoreButton";
import { useNavigate } from "react-router-dom";
import useBreakpoints from "../../../../hooks/breakpoints.hook";

const CtaRemuneradaCard: React.FC<ICtaRemuneradaProps> = ({ titulo, hiddenBalance, total, interesAcumulado, cuenta }) => {
  const navigate = useNavigate();
  const { isSmDown } = useBreakpoints();

  return (
    <Card className="home-card">
      <CardContent className="home-card-principal-data">
        <Box className="home-card-principal-data-box">
          <Typography variant={"h2"} className="home-card-title">
            {titulo}
          </Typography>
          <Typography variant="h1" className="home-card-balance">
            {hiddenBalance
              ? `$ ${HiddenBalanceText}`
              : `${Formatter.formatCurrency(total)}`}
          </Typography>
          <Typography
            mt={isSmDown ? 1 : 3}
            variant={isSmDown ? "body2" : "h3"}
            sx={{ color: colors.primaryLight, width: "100%" }}
          >
            Interes Acumulado: {hiddenBalance
              ? `$ ${HiddenBalanceText}`
              : `${Formatter.formatCurrency(interesAcumulado)}`}
          </Typography>
        </Box>
        <Box className="home-card-other-data">

          <Typography
            variant="body2"
            sx={{ color: colors.lightTextTertiary, width: "100%" }}
          >
            {`Nro Cuenta: ${cuenta}`}
          </Typography>
        </Box>
      </CardContent>
      <CardActions className="home-card-actions">
        <ViewMoreButton onClick={() => navigate(`/cuenta/ahorro-mutual-plus`)} />
      </CardActions>
    </Card>
  )
}

export default CtaRemuneradaCard