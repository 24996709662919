import { EmptyToString } from "../../components/Formatters/Formatters";
import { UsuarioCuenta } from "../../contexts/UsuarioContext";
import {
  MAX_SALDO,
  getDateLimits,
} from "../../pages/CuentaEspecifica/PageComponents/Filters/FilterUtils";
import {
  Cuenta,
  GetMovementAdapterProps,
  GetMovementProps,
  MovementAllData,
  GetInteresesProps,
} from "./cuenta.service.interfaces";

export const CuentaEspecificaFiltersToGetMovements = (
  props: GetMovementAdapterProps
): GetMovementProps => {
  const { minDateFrom, maxDateTo } = getDateLimits(undefined, undefined);

  return {
    FechaDesde: props.filters.dateFrom ? props.filters.dateFrom : minDateFrom,
    FechaHasta: props.filters.dateTo ? props.filters.dateTo : maxDateTo,
    TipoImputacion: props.filters.tipoImputacion.Id,
    TipoImputacionNombre: props.filters.tipoImputacion.Nombre,
    TipoOrigen: props.filters.tipoOrigen.Id,
    TipoOrigenNombre: props.filters.tipoOrigen.Nombre,
    MinImporte: props.filters.min,
    MaxImporte: props.filters.max === MAX_SALDO ? undefined : props.filters.max,
    Pagina: props.filters.page,
    TamanioPagina: props.filters.pageSize,
  };
};

export const BdMovementToMovementAllData = (
  movement: MovementAllData
): MovementAllData => {
  return {
    ...movement,
    Fecha: new Date(movement.Fecha),
    Referencia: EmptyToString(movement?.Referencia, "--"),
    Concepto: EmptyToString(movement?.Concepto, "--"),
  };
};

export const HomeFiltersToGetMovements = (
  rows: number
): GetMovementProps => {
  const { minDateFrom, maxDateTo } = getDateLimits(undefined, undefined);

  return {
    FechaDesde: minDateFrom,
    FechaHasta: maxDateTo,
    TipoImputacion: undefined,
    TipoOrigen: undefined,
    MinImporte: 0,
    MaxImporte: undefined,
    Pagina: 0,
    TamanioPagina: rows,
  };
};

export const CuentaToUsuarioCuenta = (cuenta: Cuenta): UsuarioCuenta => {
  return {
    NroSocio: cuenta.NroCuenta,
    Filial: cuenta.Filial,
    FilialId: cuenta.FilialId,
    NombreSocio: cuenta.NombreSocio,
    Cuit: cuenta.Cuit,
    TipoCuentaId: cuenta.TipoCuentaId,
    LeySellosAhorros: cuenta.LeySellosAhorros,
    Orden: cuenta.Orden.toString(),
    DescripcionTipoCuenta: cuenta.DescripcionTipoCuenta,
    Moneda: cuenta.Moneda,
    Saldo: cuenta.Saldo,
    Etiqueta: cuenta.Etiqueta,
    HasCuentaRemunerada: cuenta.HasCuentaRemunerada,
    InteresesAcumuladosMC: cuenta.InteresesAcumuladosMC,
    SaldoCuentaRemunerada: cuenta.SaldoCuentaRemunerada,
    FechaAceptaTyC: cuenta.FechaAceptaTyC,
  };
};

export const FiltersToGetInterest = (
  props: GetMovementAdapterProps
): GetInteresesProps => {
  const { minDateFrom, maxDateTo } = getDateLimits(undefined, undefined);

  return {
    FechaDesde: props.filters.dateFrom ? props.filters.dateFrom : minDateFrom,
    FechaHasta: props.filters.dateTo ? props.filters.dateTo : maxDateTo,
    TipoImputacion: props.filters.tipoImputacion.Id,
    MinImporte: props.filters.min,
    MaxImporte: props.filters.max === MAX_SALDO ? undefined : props.filters.max,
    Pagina: 0,
    TamanioPagina: props.filters.pageSize,
  };
};
