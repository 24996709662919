import { useEffect, useState } from "react";

interface UseScrollVisibilityResult {
  isScrollVisible: boolean;
}

const useScrollVisibility = (
  elementId: string,
  dependency?: any[]
): UseScrollVisibilityResult => {
  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false);

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      const hasVerticalScrollbar =
        element.scrollHeight > element.clientHeight;
      setIsScrollVisible(hasVerticalScrollbar);
    }
  }, [elementId, ...dependency]);

  return { isScrollVisible };
};

export default useScrollVisibility;
