import { SectionHeader } from "../../../components/Headers/SectionHeader";
import { useCardDivResponsiveStyle } from "./CardResponsiveStyle.hook";
import Loader from "../../../components/Loader/Loader";
import { Carousel } from "../../../components/Carousel/Carousel";
import sizes from "../../../assets/_sizes-vars.module.scss";

interface HomeCardSectionProps {
  title: string;
  loaderText: string;
  isLoading: boolean;
  items: any[];
  setNumber?: (number: number) => void;
}

const HomeCardSection = ({
  title,
  loaderText,
  isLoading,
  items,
  setNumber,
}: HomeCardSectionProps) => {
  const cardDivResponsiveStyle = useCardDivResponsiveStyle();

  return (
    <div className="home-card-div" style={cardDivResponsiveStyle}>
      <SectionHeader title={title} />
      {isLoading ? (
        <div style={{ height: "220px" }}>
          <Loader label={loaderText} />
        </div>
      ) : (
        <Carousel
          className="home-carousel"
          items={items}
          setNumber={setNumber ? setNumber : () => {}}
        />
      )}
    </div>
  );
};

export default HomeCardSection;
