import "./MovementsComponent.scss";
import { MovementsProps } from "./Movements.props";
import { Cell } from "../../../../../components/Tables/TableWrapper/Table.props";
import { Table } from "../../../../../components/Tables/TableWrapper/Table";
import { DefaultHeaderCell } from "../../../../../components/Tables/TableWrapper/DefaultHeaderCell";
import {
  DefaultBodyCell,
  DefaultSmBodyCell,
} from "../../../../../components/Tables/TableWrapper/DefaultBodyCell";
import Formatter from "../../../../../hooks/formatter.hook";
import { MovementsTableFooter } from "./MovementsTableFooter";
import { EmptyMovementsTable } from "./EmptyMovementsTable";
import useBreakpoints from "../../../../../hooks/breakpoints.hook";
import { MovementAllData } from "../../../../../services/CuentaService/cuenta.service.interfaces";
import colors from "../../../../../assets/_themes-vars.module.scss";
import { DateToStringConverter } from "../../../../../components/Formatters/Formatters";

const getHeaderCells = (isSmDown: boolean): Cell[] => {
  const headerCells: Cell[] = [
    DefaultHeaderCell("Fecha"),
    { ...DefaultHeaderCell(isSmDown ? "Desc." : "Descripción"), align: "left" },
    DefaultHeaderCell("Monto"),
  ];
  return headerCells;
};

const getBodyCells = (
  movements: MovementAllData[],
  isSmDown: boolean
): Cell[][] => {
  const bodyCells: Cell[][] = [];

  movements.forEach((movement) => {
    const defaultMontoCell = DefaultBodyCell(
      Formatter.formatCurrency(movement.Monto)
    );
    let fechaCell: Cell = DefaultBodyCell(DateToStringConverter(movement.Fecha));
    let descripcionCell: Cell = isSmDown
      ? {
          ...DefaultSmBodyCell(movement.Concepto),
          align: "left",
        }
      : {
          ...DefaultBodyCell(movement.Concepto),
          align: "left",
          sx: {
            ...DefaultBodyCell(movement.Concepto).sx,
            whiteSpace: "nowrap",
          },
        };
    let montoCell: Cell = {
      ...defaultMontoCell,
      sx: {
        ...defaultMontoCell.sx,
        color: movement.Monto < 0 ? colors.negativeMoney : colors.positiveMoney,
      },
    };
    bodyCells.push([fechaCell, descripcionCell, montoCell]);
  });
  return bodyCells;
};

const LastMovementsHomeTable = (props: MovementsProps) => {
  const { isSmDown } = useBreakpoints();

  return (
    <Table
      containerClassName="last-movements-container"
      headerClassName="last-movements-header"
      rowClassName="last-movements-row"
      rowFooterClassName="last-movements-footer"
      footer={<MovementsTableFooter />}
      fixedRowsQuantity={7}
      headerCells={getHeaderCells(isSmDown)}
      bodyCells={getBodyCells(props.movements, isSmDown)}
      emtpyTableContent={<EmptyMovementsTable />}
      emptyRowClassName="last-movements-empty-row"
      size="small"
    />
  );
};

export default LastMovementsHomeTable;
