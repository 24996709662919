import ElementConstitucion from "./ElementConstitucion"
import colors from '../../../assets/_themes-vars.module.scss'
import { Grid, Divider, Typography } from '@mui/material'
import Formatter from "../../../hooks/formatter.hook"
import { AccionVencimientoConstitucion, DetalleInversionConstitucion, OrdenCertificado } from '../../../services/InversionService/inversion.service'
import moment from "moment"
import { useSystemDate } from "../../../contexts/SystemDateContext"

const SeccionDetalleSimulacion = (props: {
    detalleSimulacion: DetalleInversionConstitucion, 
    selectedOrdenCertificado: OrdenCertificado, 
    selectedAccionVencimiento: AccionVencimientoConstitucion, 
}) => {
    const { detalleSimulacion, selectedOrdenCertificado, selectedAccionVencimiento  } = props
    const systemDate = useSystemDate();
    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: colors.primaryMain, marginBottom: '.5rem' }}>Detalle de la constitución</Typography>
                <Divider></Divider>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ElementConstitucion label='Orden de certificado' value={`${selectedOrdenCertificado.Nombre}`} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ElementConstitucion label='Fecha Imposición' value={moment(systemDate).format("DD/MM/YYYY")} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ElementConstitucion label='Fecha de Vencimiento' value={moment(new Date(detalleSimulacion.FechaVencimiento)).format("DD/MM/YYYY")} />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
                <ElementConstitucion label='Plazo (Días)' value={`${detalleSimulacion.PlazoReal}`} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ElementConstitucion label='Acción al vencimiento' value={`${selectedAccionVencimiento.Nombre}`} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ElementConstitucion label='Tasa Nominal Anual (T.N.A)' value={`${detalleSimulacion.TasaNominalAnual} %`} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ElementConstitucion label='Tasa Efectiva Mensual (T.E.M)' value={`${detalleSimulacion.TasaEfectivaMensual} %`} />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
                <ElementConstitucion label='Capital' value={`${Formatter.formatCurrency(detalleSimulacion.Monto)}`} color="green" />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
                <ElementConstitucion label='Intereses' value={`${Formatter.formatCurrency(detalleSimulacion.Intereses)}`} color="green" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ElementConstitucion label='Importe Sellado Documento' value={`${Formatter.formatCurrency(detalleSimulacion.ImporteSelladoDocumento)} (${detalleSimulacion.PorcentageSelladoDocumento}‰)`} color="red" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ElementConstitucion label='Gastos Administrativos' value={`${Formatter.formatCurrency(detalleSimulacion.GastosAdministrativos)}`} color="red" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <ElementConstitucion label='Total a cobrar' value={`${Formatter.formatCurrency(detalleSimulacion.TotalaCobrar)}`} color="green" />
            </Grid>
        </Grid>
    )
}

export default SeccionDetalleSimulacion