import {
  PrestamoVencidoDetalle,
  PrestamoResumen,
  PrestamoAnticipadoDetalle,
  PrestamoConCuotasDetalle,
  LineaPrestamoResumen,
} from "../../pages/Prestamos/interfaces/Prestamo";

export const BdPrestamoToPrestamoResumen = (
  prestamo: PrestamoResumen
): PrestamoResumen => {
  return {
    ...prestamo,
    FechaImposicion: new Date(prestamo.FechaImposicion),
    DetalleCuota: prestamo?.DetalleCuota && {
      ...prestamo.DetalleCuota,
      FechaVencimiento: new Date(prestamo.DetalleCuota.FechaVencimiento),
    },
    DetalleCheque: prestamo?.DetalleCheque && {
      ...prestamo.DetalleCheque,
      FechaVencimiento: new Date(prestamo.DetalleCheque.FechaVencimiento),
    },
    DetalleVencido: prestamo?.DetalleVencido && {
      ...prestamo.DetalleVencido,
      FechaVencimiento: new Date(prestamo.DetalleVencido.FechaVencimiento),
    },
  };
};

export const LineaPrestamoResumenAdapter = (
  lineaPrestamo: LineaPrestamoResumen
): LineaPrestamoResumen => {
  return {
    ...lineaPrestamo,
    ProximoVencimiento: new Date(lineaPrestamo.ProximoVencimiento),
  };
};

export const DetalleConCuotasAdapter = (
  prestamo: PrestamoConCuotasDetalle
): PrestamoConCuotasDetalle => {
  return {
    ...prestamo,
    FechaImposicion: new Date(prestamo.FechaImposicion),
    Cuotas: prestamo.Cuotas.map((cuota) => ({
      ...cuota,
      FechaVencimiento: new Date(cuota.FechaVencimiento),
      FechaPago: cuota?.FechaPago && new Date(cuota.FechaPago),
    })),
  };
};

export const DetalleAnticipadoAdapter = (
  prestamo: PrestamoAnticipadoDetalle
): PrestamoAnticipadoDetalle => {
  return {
    ...prestamo,
    FecImp: new Date(prestamo.FecImp),
    Cheques: prestamo.Cheques.map((chq) => ({
      ...chq,
      FechaEmision: new Date(chq.FechaEmision),
      FechaPago: new Date(chq.FechaPago),
      FechaVto: new Date(chq.FechaVto),
      FechaCancelacion: chq.FechaCancelacion
        ? new Date(chq.FechaVto)
        : undefined,
    })),
  };
};

export const DetalleVencidoAdapter = (
  prestamo: PrestamoVencidoDetalle
): PrestamoVencidoDetalle => {
  return {
    ...prestamo,
    FechaVto: new Date(prestamo.FechaVto),
    FecImp: new Date(prestamo.FecImp),
  };
};
