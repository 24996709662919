import { Button, Typography } from "@mui/material";
import {
  DrawerDataItem,
  DrawerItemsForm,
  DrawerActionItem,
} from "../../../../components/DrawerForm/DrawerFormAndItems";
import { NumberToMoneyConverter } from "../../../../components/Formatters/Formatters";

interface TransferenciaConfirmSectionProps {
  contactName: string;
  importe: number;
  onCancel: () => void;
  onAccept: () => void;
  ctaRemunerada?: boolean;
}

const TransferenciaConfirmSection = (
  props: TransferenciaConfirmSectionProps
) => {
  return (
    <DrawerItemsForm>
      <DrawerDataItem sx={{ gap: "0px !important" }} label={undefined}>
        <Typography
          variant="h4"
          sx={{ textAlign: "justify", fontWeight: "normal", width: "100%" }}
        >
          Está a punto de transferir{" "}
          <Typography
            component="span"
            variant="h4"
            style={{ fontWeight: "bold" }}
          >
            {NumberToMoneyConverter(props.importe)}
          </Typography>{" "}
          a{" "}
          <Typography
            component="span"
            variant="h4"
            style={{ fontWeight: "bold" }}
          >
            {props.contactName}
          </Typography>
          <br />
          <Typography
            component="span"
            variant="h4"
            style={{
              textAlign: "right",
              fontWeight: "bold",
              width: "100%",
              display: "block",
            }}
          >
            ¿Desea continuar?
          </Typography>
        </Typography>
      </DrawerDataItem>
      <DrawerActionItem>
        <Button variant="contained" color="error" onClick={props.onCancel}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={props.onAccept}>
          Aceptar
        </Button>
      </DrawerActionItem>
    </DrawerItemsForm>
  );
};

export default TransferenciaConfirmSection;
