import { Inversion } from '../../../services/InversionService/inversion.service'
import InversionEspecifica from './InversionEspecifica'

const InversionList = (props: { inversiones: Inversion[], selectedInversion: Inversion, setSelectedInversion: Function }) => {
    const { inversiones, selectedInversion, setSelectedInversion } = props
    return (
        inversiones.map((inversion, index) => <InversionEspecifica inversion={inversion} key={index} selectedInversion={selectedInversion} setSelectedInversion={setSelectedInversion} />)
    )
}

export default InversionList