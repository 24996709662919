export interface GetComprobanteProps {
  NComprobante: number;
  TipoMovimiento: TipoMovimientoEnum;
}

export enum TipoMovimientoEnum {
  TRANSFERENCIA = "TRANSFERENCIA",
  PAGO_CUOTA = "PAGO_CUOTA",
  INVERSION = "INVERSION",
}
