import { useContext } from "react";
import "./LoginPage.scss";
import { Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PersonIcon from "@mui/icons-material/Person";
import PasswordIcon from "@mui/icons-material/Password";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { LoginAction } from "./enums/login-actions.enum";
import { LoginContext } from "./LoginPage";
import { useSnackbar } from "notistack";
import { defaultErrorSnackbar } from "../../utils/snackBarConfigs.util";

const LoginOption = () => {
  const [action, setAction] = useContext(LoginContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <div className="login__form">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => {
              setAction(LoginAction.RecuperarUsuario);
            }}
          >
            <PersonIcon sx={{ mr: 0.5 }} />
            ¿Olvidó su usuario?
          </Link>

          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={(_) => {
              setAction(LoginAction.RecuperarContraseña);
            }}
          >
            <PasswordIcon sx={{ mr: 0.5 }} />
            ¿Olvidó su clave?
          </Link>
        </div>
        <div>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="$primaryMain"
          >
            <PersonAddAltIcon sx={{ mr: 0.4 }} />
            Solicite USUARIO y<br></br>
            CLAVE en su Entidad
          </Typography>
        </div>
      </Breadcrumbs>
    </div>
  );
};

export default LoginOption;
