import {
  defaultDetallePrestamoState,
  useDetallePrestamo,
} from "../../../contexts/DetallePrestamoContext";
import SwipeableDrawerComponent from "../SwipeableDrawer/SwipeableDrawer";
import DetallePrestamoDrawerContainer from "./DetallePrestamoDrawerContainer";

const DetallePrestamoDrawer = () => {
  const { detallePrestamo, setDetallePrestamo } = useDetallePrestamo();

  const closeDrawer = () => {
    setDetallePrestamo(defaultDetallePrestamoState);
  };

  const setOpenDrawer = (_: boolean) => {
    closeDrawer();
  };

  return (
    <SwipeableDrawerComponent
      openDrawer={detallePrestamo?.isOpenDrawer}
      setOpenDrawer={setOpenDrawer}
      anchor="right"
      contentDrawer={
        <DetallePrestamoDrawerContainer closeDrawer={closeDrawer} />
      }
      title={"Detalle Préstamo"}
    />
  );
};

export default DetallePrestamoDrawer;
