const getFilenameFromResponse = (response) => {
  const contentDisposition = response?.headers["content-disposition"] || "";
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);

  if (matches?.length > 1) {
    return matches[1].replace(/['"]/g, "");
  }

  return "comprobante.pdf"; // Nombre predeterminado
};

export default getFilenameFromResponse;
