import ContactoService from "../../../services/ContactoService/contacto.service";
import {
  defaultErrorSnackbar,
  defaultSuccessSnackbar,
} from "../../../utils/snackBarConfigs.util";

interface SaveContactoFunctionProps {
  contactNroSocio: string;
  rererencia: string;
  refreshContactos: () => void;
  enqueueSnackbar: Function;
}

export const SaveContacto = (props: SaveContactoFunctionProps) => {
  ContactoService.Set(props.contactNroSocio, props.rererencia)
    .then(() => {
      props.refreshContactos();
      props.enqueueSnackbar(
        "Contacto agendado satisfactoriamente",
        defaultSuccessSnackbar
      );
    })
    .catch(() =>
      props.enqueueSnackbar(
        "Hubo un error al agendar el contacto",
        defaultErrorSnackbar
      )
    );
};

export default SaveContacto;
