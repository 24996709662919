import ComprobantesService from "../../services/ComprobantesService/comprobantes.service";
import { GetComprobanteProps } from "../../services/ComprobantesService/comprobantes.service.interfaces";
import { GetMovementProps } from "../../services/CuentaService/cuenta.service.interfaces";
import { defaultErrorSnackbar } from "../../utils/snackBarConfigs.util";

export const DownloadComprobante = (
  downloadProps: GetComprobanteProps,
  enqueueSnackbar: Function
) => {
  ComprobantesService.DownloadComprobante(downloadProps)
    .then(() => {})
    .catch(() =>
      enqueueSnackbar(
        "Hubo un problema al obtener el comprobante",
        defaultErrorSnackbar
      )
    );
};

export const DownloadExtracto = (
  filters: GetMovementProps,
  enqueueSnackbar: Function,
  formato: "pdf" | "csv"
) => {
  ComprobantesService.DownloadExtracto({ ...filters, Formato: formato })
    .then(() => {})
    .catch(() =>
      enqueueSnackbar(
        "Hubo un problema al obtener el extracto de cuenta",
        defaultErrorSnackbar
      )
    );
};
