import Box from "@mui/material/Box";
import {
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Typography,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import TransferenciaDrawer from "../../components/Drawer/TransferenciaDrawer/TransferenciaDrawer";
import { TransferenciaActionType } from "./enums/transferencia-actions.enum";
import {
  TransferenciaActionProvider,
  useTransferenciaActionContext,
} from "../../contexts/TransferenciaActionContext";
import AgendaContacto from "./PageComponents/AgendaContacto";
import "./TransferenciasPage.scss";
import { ContactosContextProvider } from "./PageComponents/ContactosContext";

const Header = () => {
  return (
    <div className="generic-header">
      <Typography
        variant="h1"
        sx={{ color: "text.primary", m: "0.5rem", my: "0" }}
      >
        Transferencias
      </Typography>
      <Divider
        sx={{
          width: "100%",
          mb: "1rem",
          mt: "0.3rem",
          borderColor: "text.primary",
        }}
      />
    </div>
  );
};

const Body = () => {
  const { transferenciaAction, setTransferenciaAction } =
    useTransferenciaActionContext();

  const showAction = (accion: TransferenciaActionType) => {
    setTransferenciaAction({
      isOpenDrawer: true,
      accion: accion,
      contacto: undefined,
    });
  };

  const setIsOpenDrawer = (isOpen: boolean) => {
    setTransferenciaAction((prevState) => ({
      ...prevState,
      isOpenDrawer: isOpen,
    }));
  };

  return (
    <Box>
      <BottomNavigation showLabels sx={{ mt: "15px" }}>
        <BottomNavigationAction
          disableRipple
          label="Nueva transferencia individual"
          icon={<CurrencyExchangeIcon fontSize="large" color={"primary"} />}
          onClick={() => {
            showAction(TransferenciaActionType.TRANSFERENCIA_INDIVIDUAL);
          }}
        />
        <BottomNavigationAction
          disableRipple
          label="Nuevo contacto"
          icon={<PersonAddAlt1Icon fontSize="large" color={"primary"} />}
          onClick={() => {
            showAction(TransferenciaActionType.NUEVO_CONTACTO);
          }}
        />
      </BottomNavigation>
      <TransferenciaDrawer
        openDrawer={transferenciaAction?.isOpenDrawer}
        setOpenDrawer={setIsOpenDrawer}
      />
      <AgendaContacto />
    </Box>
  );
};

const TransferenciasPage = () => {
  return (
    <TransferenciaActionProvider>
      <div className="generic-main-div">
        <Header />
        <ContactosContextProvider>
          <Body />
        </ContactosContextProvider>
      </div>
    </TransferenciaActionProvider>
  );
};

export default TransferenciasPage;
