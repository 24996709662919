import { Typography } from "@mui/material";
import { CardDivider } from "./CardDivider";

interface PropsSectionHeader {
    title: string;
  }
 export const SectionHeader = (props: PropsSectionHeader) => {
    return (
      <>
        <Typography
          variant="h1"
          className="generic-page-section"
          sx={{
            color: "text.primary",
            mt: "0.25rem",
          }}
        >
          {props.title}
        </Typography>
        <CardDivider />
      </>
    );
  };