import { useEffect, useState, createContext, useContext } from "react";
import ContactoService, {
  Contacto,
} from "../../../services/ContactoService/contacto.service";
import { useSnackbar } from "notistack";
import { UseUsuario } from "../../../contexts/UsuarioContext";

interface IUseContactos {
  contactos: Contacto[];
  isLoadingContactos: boolean;
  refreshContactos: () => void;
}

const useContactos = (nroSocio: string): IUseContactos => {
  const [contactos, setContactos] = useState<Contacto[]>([]);
  const [isLoadingContactos, setIsLoadingContactos] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const getContactos = () => {
    setIsLoadingContactos(true);
    ContactoService.GetAll()
      .then((result) => {
        setContactos(result);
      })
      .catch((error) =>
        enqueueSnackbar(`Error al obtener los contactos: ${error}`, {
          variant: "error",
        })
      )
      .finally(() => {
        setIsLoadingContactos(false);
      });
  };

  useEffect(() => {
    if (nroSocio) getContactos();
  }, [nroSocio]);

  return { contactos, isLoadingContactos, refreshContactos: getContactos };
};

export const ContactosContext = createContext<IUseContactos>({
  contactos: [],
  isLoadingContactos: true,
  refreshContactos: () => {},
});

export const ContactosContextProvider = ({ children }) => {
  const { selectedCuentaUsuario } = UseUsuario();
  const contactosState = useContactos(selectedCuentaUsuario?.NroSocio);

  return (
    <ContactosContext.Provider value={contactosState}>
      {children}
    </ContactosContext.Provider>
  );
};

export const UseContactos = () => {
  const context = useContext(ContactosContext);

  if (!context)
    console.log("UseContactos debe estar dentro del ContactosContext");

  return context;
};
