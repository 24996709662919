import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Contacto } from "../../../services/ContactoService/contacto.service";
import { useSnackbar } from "notistack";
import ContactoDataForm from "./ContactoDataForm";
import SearchBar from "../../../components/SearchBar/SearchBarComponent";
import useSearchContacto from "../hooks/useSearchContacto.hook";
import TransferenciaInternalLoader from "./BuscandoSocioLoader";
import { SocioSearchError } from "./SocioSearchError";
import Divider100P from "../../../components/Dividers/Divider100P";
import SaveContacto from "./SaveContacto.function";
import { UseUsuario } from "../../../contexts/UsuarioContext";

const NuevoContacto = (props: {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  contactos: Contacto[];
  refreshContactos: () => void;
}) => {
  const [searchedSocio, setSearchedSocio] = useState<string>("");
  const [newReferencia, setNewReferencia] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCuentaUsuario } = UseUsuario();

  const {
    contacto,
    errorMessage,
    isSearching: isSearchingSocio,
    searchSocio,
  } = useSearchContacto({
    currentNroSocio: selectedCuentaUsuario?.NroSocio,
    contactos: props.contactos,
    existingContactoInvalid: true,
  });

  const saveContactoHandler = () => {
    SaveContacto({
      contactNroSocio: contacto.NSocio,
      rererencia: newReferencia,
      refreshContactos: props.refreshContactos,
      enqueueSnackbar,
    });
    props.setOpenDrawer(false);
  };

  const searchSocioHandler = () => searchSocio(searchedSocio);

  return (
    <Box className="transferencias-menu-main-container">
      <Box className="nuevo-contacto-header ">
        <SearchBar
          sx={{ width: "70%" }}
          searchType="number"
          placeholder={"Nro Socio..."}
          onChangedValue={setSearchedSocio}
          onPressedEnter={searchSocioHandler}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={searchSocioHandler}
        >
          Buscar
        </Button>
      </Box>
      <Divider100P />
      {isSearchingSocio && (
        <TransferenciaInternalLoader label={"Buscando socio"} />
      )}
      {!isSearchingSocio && errorMessage && (
        <SocioSearchError errorMessage={errorMessage} />
      )}
      {!isSearchingSocio && !errorMessage && contacto && (
        <ContactoDataForm
          contacto={contacto}
          referencia={newReferencia}
          setReferencia={setNewReferencia}
          actionButtons={{
            cancelButtonLabel: "Cerrar",
            cancelButtonOnClick: () => props.setOpenDrawer(false),
            okButtonLabel: "Agregar",
            okButtonOnClick: saveContactoHandler,
          }}
        />
      )}
    </Box>
  );
};

export default NuevoContacto;
