import { IconButton } from "@mui/material";
import Icon from "../Icon/Icon";
import { IconEnum } from "../Icon/Icon.props";
import { useSnackbar } from "notistack";
import { GetComprobanteProps } from "../../services/ComprobantesService/comprobantes.service.interfaces";
import { DownloadComprobante } from "./Download.function";

interface DownloadIconButtonProps {
  class?: string;
  style?: React.CSSProperties;
  downloadProps: GetComprobanteProps;
}

const DownloadIconButton = ({
  class: buttonClass,
  style: buttonStyle,
  downloadProps,
}: DownloadIconButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <IconButton
      disableRipple={true}
      className={`${buttonClass}`}
      style={buttonStyle}
      color="primary"
      onClick={() => DownloadComprobante(downloadProps, enqueueSnackbar)}
    >
      <Icon icon={IconEnum.DOWNLOAD} />
    </IconButton>
  );
};

export default DownloadIconButton;
