import HomeApi from "../../config/axios";
import { TipoMovimientoEnum } from "../ComprobantesService/comprobantes.service.interfaces";

export interface Inversion {
    Tipo: string;
    Orden: number;
    Numero: number;
    FilialId: number;
    Filial: string;
    Socio: string,
    SocioId: number;
    Plazo: number;
    NroCertificado: number;
    FechaCertificado: Date;
    Tasa: number;
    TasaAnual: number;
    FechaVencimiento: Date;
    Moneda: string;
    Total: number;
    Relacion: string;
}

export interface ResumenInversiones{
    TieneInversionesActivas: boolean,
    TotalInversiones: number,
    CantidadInversiones: number,
    TotalInversionesPropias: number,
    CantidadInversionesPropias: number,
    ProximoVencimiento?: Date,
    MontoProximoVencimiento: number,
}

export interface DetalleInversion {
    NroComprobante: number;
    FechaConstitucion: Date;
    Plazo: number;
    TNA: number;
    TNE: number;
    FechaVencimiento: Date;
    NroSocioTitular: number;
    Titular: string;
    NroSocioCotitular1?: number;
    Cotitular1?: string;
    NroSocioCotitular2?: number;
    Cotitular2?: string;
    Orden: string;
    AccionVto: string;
    Capital: number;
    GastoAdministrativo: number;
    GastoSellado: number;
    Intereses: number;
    Importe: number;
    TipoMovimiento: TipoMovimientoEnum;
}

export interface DetalleCuota {
    Importe: number;
    FechaPago: Date;
    FechaVencimiento: Date;
    NroCuota: number;
}

interface User {
    id: Number;
}

export interface AccionVencimientoConstitucion {
    Id: number;
    Nombre: string;
}

export interface TitularAdicional {
    Id: number;
    NumeroSocio: number;
    Nombre: string;
}

export interface OrdenCertificado {
    Id: number;
    Nombre: string;
}

export interface DetalleInversionConstitucion {
    Monto: number;
    PlazoReal: number;
    FechaVencimiento: Date;
    TasaEfectivaMensual: number;
    TasaNominalAnual: number;
    Intereses: number;
    GastosAdministrativos: number;
    PorcentageSelladoDocumento: number;
    ImporteSelladoDocumento: number;
    TotalaCobrar: number;
}

const InversionService = {

    GetAll: (): Promise<Inversion[]> => {
        return new Promise<Inversion[]>((resolve, reject) => {
            HomeApi.get(`Inversion`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    GetResumenInversiones: (): Promise<ResumenInversiones> => {
        return new Promise<ResumenInversiones>((resolve, reject) => {
            HomeApi.get(`Inversion/resumen`)
                .then(response => {
                    resolve({...response.data, ProximoVencimiento: new Date(response.data.ProximoVencimiento)})
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    GetDetalle: (nroComprobante: number): Promise<DetalleInversion> => {
        return new Promise<DetalleInversion>((resolve, reject) => {
            HomeApi.get(`Inversion/Detalle`, { params: { NComprobante: nroComprobante } })
                .then(response => { resolve(response.data) })
                .catch(error => { reject(error.message) })
        })
    },
    GetAllAccionesVencimientoConstitucion: (): Promise<AccionVencimientoConstitucion[]> => {
        return new Promise((resolve, reject) => {
            HomeApi.get<any>(`Inversion/Constitucion/AccionesVencimiento`)
                .then(response => resolve(response.data))
                .catch(error => reject(error.message))
        })
    },
    GetAllOrdenesCertificado: (): Promise<OrdenCertificado[]> => {
        return new Promise((resolve, reject) => {
            HomeApi.get<any>(`Inversion/Constitucion/OrdenesCertificado`)
                .then(response => resolve(response.data))
                .catch(error => reject(error.message))
        })
    },
    SimularInversion: (leySellosAhorros: string, monto: number, plazo: number, cotitulares: string, esSimulacion: number, orden?: number, accionVencimiento?: number): Promise<DetalleInversionConstitucion> => {
        return new Promise((resolve, reject) => {
            if (esSimulacion) {
                HomeApi.get<any>(`Inversion/Constitucion/Simulacion`, { params: { LeySellosAhorros: leySellosAhorros, Monto: monto, Plazo: plazo, Cotitulares: cotitulares, esSimulacion: esSimulacion, Orden: orden, AccionVto: accionVencimiento } })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.message))
            }
            else {
                HomeApi.post<any>(`Inversion`, { LeySellosAhorros: leySellosAhorros, Monto: monto, Plazo: plazo, Cotitulares: cotitulares, esSimulacion: esSimulacion, Orden: orden, AccionVto: accionVencimiento })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.message))
            }
        })
    },
    obtenerPlazoReal: (Plazo: number, NFilial: number) => {
        return new Promise<number>((resolve, reject) => {
            HomeApi.get(`Inversion/Plazo`, { params: { NFilial, Plazo } })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

}

export default InversionService;