import { Table } from "../../../../components/Tables/TableWrapper/Table";
import colors from "../../../../assets/_themes-vars.module.scss";
import { Cell } from '../../../../components/Tables/TableWrapper/Table.props';
import CuentaService from '../../../../services/CuentaService/cuenta.service';
import { UseUsuario } from "../../../../contexts/UsuarioContext";
import { useEffect, useState } from "react";
import { FiltersToGetInterest } from "../../../../services/CuentaService/cuenta.service.adapter";
import { DateToStringConverter, NumberToMoneyConverter } from "../../../../components/Formatters/Formatters";
import Loader from "../../../../components/Loader/Loader";
import { Box } from "@mui/material";
import useCustomPagination from "../../../../hooks/useCustomPagination.hook";

const TablaIntereses = ({ filters }) => {
  const [interestsData, setInterestsData] = useState<Cell[][]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { selectedCuentaUsuario } = UseUsuario();

  const fetchInterests = () => {
    setIsLoading(true);
    CuentaService.GetInteresesPlus(FiltersToGetInterest({
      filters,
    }))
      .then((result) => {
        const bodyCell: Cell[][] = result.map((interest) => [
          {
            text: DateToStringConverter(new Date(interest.Fecha)),
            width: "220px",
            align: "center",
            pl: "0.4rem",
            pr: "0.4rem",
            sx: { fontWeight: 'bold', color: '#000000' },
            textVariant: "body2",
          },
          {
            text: `${NumberToMoneyConverter(interest.Interes)}`,
            width: "220px",
            align: "center",
            pl: "0.4rem",
            pr: "0.4rem",
            sx: { fontWeight: 'bold', color: '#00c853' },
            textVariant: "body2",
          },
        ]);
        setInterestsData(bodyCell)
        setIsLoading(false);
      })
  };

  useEffect(() => {
    if (selectedCuentaUsuario) {
      fetchInterests();
    }
  }, [selectedCuentaUsuario]);

  const movimientos: Cell[] = [
    {
      text: "Fecha",
      width: "245px",
      align: "center",
      pl: "0.4rem",
      pr: "0.4rem",
      sx: {
        color: "#d8e2ec",
        fontSize: "22px",
      }
    },
    {
      text: "Intereses",
      width: "245px",
      align: "center",
      pl: "0.4rem",
      pr: "0.4rem",
      sx: {
        color: "#d8e2ec",
        fontSize: "22px",
      }
    },
  ];

  const pagination = useCustomPagination<Cell[]>({
    defaultPagination: { page: 0, pageSize: 5, totalElements: interestsData.length },
    elements: interestsData,
  });

  const interesesToShow = pagination.paginatedElements;

  return (
    <>
      {
        isLoading
          ?
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"50vh"}>
            <Loader label="Cargando Intereses" />
          </Box>
          :
          <Table
            containerClassName=""
            headerClassName="prestamo-table-header"
            rowClassName="prestamo-table-row"
            rowFooterClassName="prestamo-table-footer"
            emptyRowClassName="prestamo-table-empty-row"
            size="small"
            tablePagination={{
              rowsPerPageList: [],
              totalCount: interestsData.length,
              currentRowsPerPage: 5,
              currentPage: pagination.page,
              onChange: pagination.onPagination,
              color: colors.lightTextSecondary,
            }}
            headerCells={movimientos}
            bodyCells={interesesToShow}
          />
      }
    </>
  )
}

export default TablaIntereses