import { Button, Typography } from "@mui/material";
import Divider100P from "../../../components/Dividers/Divider100P";
import colors from "../../../assets/_themes-vars.module.scss";
import {
  DrawerActionItem,
  DrawerDataItem,
  DrawerItemsForm,
  DrawerTextDataItem,
} from "../../../components/DrawerForm/DrawerFormAndItems";
import TablaDetalleCuotas from "./Tables/TablaDetalleCuotas/TablaDetalleCuotas";
import useDetallePrestamoConCuotas from "../hooks/useDetallePrestamoConCuotas.hook";
import AllPageLoader from "../../../components/Loader/AllPageLoader";
import {
  DateToStringConverter,
  NumberToMoneyConverter,
} from "../../../components/Formatters/Formatters";
import {
  DetalleCuota,
  PrestamoConCuotasDetalle,
  PrestamoIdentifier,
} from "../interfaces/Prestamo";
import usePagarCuotaPrestamo from "../hooks/usePagarCuotaPrestamo.hook";
import { useNavigate } from "react-router-dom";

interface PrestamoConCuotasProps extends PrestamoIdentifier {
  saldoUsuario: number;
  closeDrawer: () => void;
}

const PrestamoConCuotas = (props: PrestamoConCuotasProps) => {
  const { isLoading, detallePrestamo, systemDate } =
    useDetallePrestamoConCuotas(props);
  const navigate = useNavigate();

  const { isConfirmationOpen, setIsConfirmationOpen, isPaying, pay } =
    usePagarCuotaPrestamo({
      onSuccessPayment: () => {
        navigate("/cuenta/movimientos");
        props.closeDrawer();
      },
      onFailedPayment: () => props.closeDrawer(),
    });

  const currentCuota =
    detallePrestamo?.Cuotas[detallePrestamo?.IndexCuotaActual];
  const hasSufficientBalance = props?.saldoUsuario >= currentCuota?.Total;
  const isPayableThisMonth =
    currentCuota?.FechaVencimiento <= getMaxPaymentDay(systemDate);

  return (
    <>
      {isLoading && <AllPageLoader label={"Cargando detalle"} />}
      {isPaying && <AllPageLoader label={"Realizando pago"} />}
      {!isLoading && !isPaying && (
        <>
          <HeaderDetalle detalle={detallePrestamo} />
          <Divider100P />
          <CurrentCuotaDetalle cuota={currentCuota} />
          {!isConfirmationOpen && (
            <CurrentCuotaPaymentButton
              hasSufficientBalance={hasSufficientBalance}
              isPayableThisMonth={isPayableThisMonth}
              systemDate={systemDate}
              openConfirmationSection={() => {
                if (hasSufficientBalance && isPayableThisMonth)
                  setIsConfirmationOpen(true);
              }}
            />
          )}
          {isConfirmationOpen && (
            <CurrentCuotaPaymentConfirmation
              totalCuota={currentCuota?.Total}
              closeConfirmationSection={() => setIsConfirmationOpen(false)}
              onConfirmPay={() =>
                pay({
                  NroComprobante: props.NroComprobante,
                  LineaId: props.LineaId,
                  NroCuota: currentCuota?.NroCuota,
                })
              }
            />
          )}
          <Divider100P />
          <TablaDetalleCuotas
            cuotas={detallePrestamo.Cuotas}
            currentCuota={detallePrestamo.IndexCuotaActual + 1}
          />
        </>
      )}
    </>
  );
};

export default PrestamoConCuotas;

interface HeaderDetalleProps {
  detalle: PrestamoConCuotasDetalle;
}

const HeaderDetalle = ({ detalle }: HeaderDetalleProps) => {
  return (
    <DrawerItemsForm>
      <DrawerTextDataItem label={"Descripción"} value={detalle.Descripcion} />
      <DrawerTextDataItem
        label={"Comprobante"}
        value={detalle.NComprobante.toString()}
      />
      <DrawerTextDataItem
        label={"Fecha Imposición"}
        value={DateToStringConverter(detalle.FechaImposicion)}
      />
      <DrawerTextDataItem label={"Destino"} value={detalle.Destino} />
      <DrawerTextDataItem
        label={"Cantidad Cuotas"}
        value={detalle.Plazo.toString()}
      />
      <DrawerTextDataItem
        label={"Importe"}
        value={NumberToMoneyConverter(detalle.Importe)}
      />
      <DrawerTextDataItem
        label={"Importe Adeudado"}
        value={NumberToMoneyConverter(detalle.ImporteAdeudado)}
      />
    </DrawerItemsForm>
  );
};

interface CurrentCuotaDetalleProps {
  cuota: DetalleCuota;
}

const CurrentCuotaDetalle = ({ cuota }: CurrentCuotaDetalleProps) => {
  return (
    <DrawerItemsForm>
      <DrawerTextDataItem
        label={"Cuota a Pagar"}
        value={cuota.NroCuota.toString()}
      />
      <DrawerDataItem label={"Fecha Vencimiento"}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: cuota.EsMoroso ? colors.errorMain : colors.grey900,
            textAlign: "right",
          }}
        >
          {DateToStringConverter(cuota.FechaVencimiento)}
        </Typography>
      </DrawerDataItem>
      <DrawerTextDataItem
        label={"Capital"}
        value={NumberToMoneyConverter(cuota.Capital)}
      />
      <DrawerTextDataItem
        label={"Intereses"}
        value={NumberToMoneyConverter(cuota.Intereses)}
      />
      <DrawerTextDataItem
        label={"Compensatorios"}
        value={NumberToMoneyConverter(cuota.Compensatorios)}
      />
      <DrawerTextDataItem
        label={"Punitorios"}
        value={NumberToMoneyConverter(cuota.Punitorios)}
      />
      <DrawerTextDataItem
        label={"Total"}
        value={NumberToMoneyConverter(cuota.Total)}
      />
    </DrawerItemsForm>
  );
};

interface CurrentCuotaPaymentButtonProps {
  hasSufficientBalance: boolean;
  isPayableThisMonth: boolean;
  systemDate: Date;
  openConfirmationSection: () => void;
}

const CurrentCuotaPaymentButton = ({
  hasSufficientBalance,
  isPayableThisMonth,
  systemDate,
  openConfirmationSection,
}: CurrentCuotaPaymentButtonProps) => {
  const proxPaymentDate = new Date(
    systemDate?.getFullYear(),
    systemDate?.getMonth() + 1,
    1
  );

  return (
    <div className="pay-prestamo-container">
      <Button
        sx={{ mb: hasSufficientBalance && "5px" }}
        disabled={!hasSufficientBalance || !isPayableThisMonth}
        onClick={openConfirmationSection}
        variant="contained"
      >
        Pagar Cuota
      </Button>
      {!isPayableThisMonth && (
        <Typography
          variant="h5"
          sx={{
            fontWeight: "light",
            color: colors.errorMain,
            textAlign: "right",
          }}
        >
          El pago se habilitará el {DateToStringConverter(proxPaymentDate)}
        </Typography>
      )}
      {isPayableThisMonth && !hasSufficientBalance && (
        <Typography
          variant="h5"
          sx={{
            fontWeight: "light",
            color: colors.errorMain,
            textAlign: "right",
          }}
        >
          No posee saldo suficiente
        </Typography>
      )}
    </div>
  );
};

interface CurrentCuotaPaymentConfirmationProps {
  totalCuota: number;
  closeConfirmationSection: () => void;
  onConfirmPay: () => void;
}

const CurrentCuotaPaymentConfirmation = (
  props: CurrentCuotaPaymentConfirmationProps
) => {
  return (
    <>
      <Divider100P />
      <DrawerItemsForm>
        <DrawerDataItem sx={{ gap: "0px !important" }} label={undefined}>
          <Typography
            variant="h4"
            sx={{ textAlign: "justify", fontWeight: "normal", width: "100%" }}
          >
            Está a punto de abonar{" "}
            <Typography
              component="span"
              variant="h4"
              style={{ fontWeight: "bold" }}
            >
              {NumberToMoneyConverter(props.totalCuota)}
            </Typography>{" "}
            para pagar la cuota.
            <br />
            <Typography
              component="span"
              variant="h4"
              style={{
                textAlign: "right",
                fontWeight: "bold",
                width: "100%",
                display: "block",
              }}
            >
              ¿Desea continuar?
            </Typography>
          </Typography>
        </DrawerDataItem>
        <DrawerActionItem>
          <Button
            variant="contained"
            color="error"
            onClick={props.closeConfirmationSection}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={props.onConfirmPay}
          >
            Aceptar
          </Button>
        </DrawerActionItem>
      </DrawerItemsForm>
    </>
  );
};

const getMaxPaymentDay = (systemDate: Date) => {
  const currentMonth = systemDate?.getMonth();
  const currentYear = systemDate?.getFullYear();
  const firstDayOfNextMonth = new Date(currentYear, currentMonth + 1, 1);
  return new Date(firstDayOfNextMonth.getTime() - 1);
};
