import { useEffect, useMemo, useState } from "react";
import { UsuarioCuenta } from "../../../../contexts/UsuarioContext";

export interface TransferInfo {
  minImporte: number;
  maxImporte: number;
  importe: number;
  concepto: string;
  done: boolean;
  comprobante?: number;
}

const defaultTransferInfo: TransferInfo = {
  minImporte: 0.01,
  maxImporte: undefined,
  importe: undefined,
  concepto: "",
  done: false,
};

export const isValidAmount = (
  amount: number,
  minAmount: number,
  maxAmount: number
): boolean => {
  return minAmount <= amount && maxAmount >= amount;
};

interface UseTransferInfoResult {
  transferInfo: TransferInfo;
  setTransferInfo: React.Dispatch<React.SetStateAction<TransferInfo>>;
  hasAmountError: boolean;
}

const useTransferInfo = (
  selectedCuentaUsuario: UsuarioCuenta,
  isCuentaPlus: boolean = false
): UseTransferInfoResult => {
  const [transferInfo, setTransferInfo] =
    useState<TransferInfo>(defaultTransferInfo);

  useEffect(() => {
    if (selectedCuentaUsuario) {
      setTransferInfo((prevTI) => ({
        ...prevTI,
        maxImporte: isCuentaPlus ? selectedCuentaUsuario?.SaldoCuentaRemunerada : selectedCuentaUsuario?.Saldo,
      }));
    }
  }, [selectedCuentaUsuario]);

  const hasAmountError = useMemo(
    () =>
      !isValidAmount(
        transferInfo?.importe,
        transferInfo.minImporte,
        isCuentaPlus ? selectedCuentaUsuario?.SaldoCuentaRemunerada : selectedCuentaUsuario?.Saldo
      ),
    [transferInfo]
  );

  return { transferInfo, setTransferInfo, hasAmountError };
};

export default useTransferInfo;
