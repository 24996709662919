import Loader from "../../../components/Loader/Loader";

const TransferenciaInternalLoader = ({ label }: { label: string }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        marginTop: "30px",
        justifyContent: "center",
      }}
    >
      <Loader label={label} />
    </div>
  );
};

export default TransferenciaInternalLoader;
