import { useEffect, useState } from "react";
import PrestamoService from "../../../services/PrestamoService/prestamo.service";
import {
  PrestamoConCuotasDetalle,
  PrestamoIdentifier,
} from "../interfaces/Prestamo";
import useGenericDetallePrestamo from "./useGenericDetallePrestamo.hook";
import HomeService from "../../../services/HomeService/Home.Service";
import { useSystemDate } from "../../../contexts/SystemDateContext";

interface UseDetallePrestamoConCuotasResult {
  isLoading: boolean;
  detallePrestamo: PrestamoConCuotasDetalle;
  systemDate: Date;
}

const useDetallePrestamoConCuotas = (
  props: PrestamoIdentifier
): UseDetallePrestamoConCuotasResult => {
  const { isLoading, detallePrestamo } = useGenericDetallePrestamo({
    ...props,
    serviceToCall: PrestamoService.GetDetallePrestamoConCuotas,
  });
  const systemDate = useSystemDate();

  return {
    isLoading: isLoading,
    detallePrestamo: detallePrestamo as PrestamoConCuotasDetalle,
    systemDate,
  };
};

export default useDetallePrestamoConCuotas;
