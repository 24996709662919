import { Chip, Divider, Stack, Typography } from "@mui/material";

import useBreakpoints from "../../../hooks/breakpoints.hook";
import Selector from "../../../components/Selector/Selector";
import { LineaPrestamo } from "../interfaces/Prestamo";
export const DefaultSelectedLineaPrestamos: LineaPrestamo = {
  Id: 0,
  Nombre: "Todas las líneas",
};

interface HeaderPrestamosPageProps {
  lineasPrestamos: LineaPrestamo[];
  selectedLineaPrestamo: LineaPrestamo;
  setSelectedLineaPrestamo: React.Dispatch<React.SetStateAction<LineaPrestamo>>;
}

const FilterLineaPrestamo = ({
  lineasPrestamos,
  selectedLineaPrestamo,
  setSelectedLineaPrestamo,
}: HeaderPrestamosPageProps) => {
  const lineas = [DefaultSelectedLineaPrestamos, ...lineasPrestamos];

  const handleChangeLinea = (selectedLinea: string) => {
    setSelectedLineaPrestamo(lineas.find((lp) => lp.Nombre === selectedLinea));
  };

  return (
    <>
      {lineasPrestamos.length > 0 && (
        <Selector
          className="lineas-prestamos-filter"
          list={lineas.map((lp) => lp.Nombre)}
          defaultValue={DefaultSelectedLineaPrestamos.Nombre}
          value={selectedLineaPrestamo.Nombre}
          setValue={handleChangeLinea}
          title="Linea de préstamo"
        />
      )}
    </>
  );
};

const Header = (props: HeaderPrestamosPageProps) => {
  const { isSmDown } = useBreakpoints();

  const prestamosHeaderClass =
    props.lineasPrestamos.length > 0 ? "prestamos-header" : "";

  return (
    <div className={`generic-header ${prestamosHeaderClass}`}>
      <Typography variant="h1" sx={{ color: "text.primary", mb: "5px" }}>
        <Stack
          direction="row"
          spacing={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div>Préstamos</div>
          <Chip label="Vigentes" color="success" size="small" />
        </Stack>
      </Typography>
      {!isSmDown && <FilterLineaPrestamo {...props} />}
      <Divider
        sx={{
          width: "100%",
          mb: "1rem",
          borderColor: "text.primary",
        }}
      />
      {isSmDown && <FilterLineaPrestamo {...props} />}
    </div>
  );
};

export default Header;
