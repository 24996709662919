import useBreakpoints from "./breakpoints.hook";

export const useCardResponsiveStyle = () => {
  const { is1150Down, isXlDown } = useBreakpoints();

  if (is1150Down) {
    return {
      width: "100%",
      minWidth: "21.5rem",
    };
  }
  if (isXlDown) {
    return {
      width: "50%",
      height: "auto",
    };
  }
};
