import { Table } from "../../../../components/Tables/TableWrapper/Table";
import colors from "../../../../assets/_themes-vars.module.scss";
import "./DetallePrestamoTable.scss";
import DefaultArrowCellStyle from "../../../../components/Tables/TableWrapper/DefaultArrowCellStyle";
import { Cell } from "../../../../components/Tables/TableWrapper/Table.props";
import { PaginationConfig } from "../../../../components/Tables/TableWrapper/PaginationConfig";

interface DetallePrestamoTableProps {
  hiddenContent: JSX.Element[];
  header: Cell[];
  body: Cell[][];
  paginationConfig: PaginationConfig;
  onPagination: (paginationConfig: PaginationConfig) => void;
}

const DetallePrestamoTable = (props: DetallePrestamoTableProps) => {
  return (
    <Table
      containerClassName="prestamo-table-container"
      headerClassName="prestamo-table-header"
      rowClassName="prestamo-table-row"
      rowFooterClassName="prestamo-table-footer"
      emptyRowClassName="prestamo-table-empty-row"
      size="small"
      tablePagination={{
        currentPage: props.paginationConfig.page,
        currentRowsPerPage: props.paginationConfig.pageSize,
        totalCount: props.paginationConfig.totalElements,
        onChange: props.onPagination,
        color: colors.lightTextSecondary,
        rowsPerPageList: [],
      }}
      hiddenContent={props.hiddenContent}
      hiddenArrowStyle={DefaultArrowCellStyle()}
      headerCells={props.header}
      bodyCells={props.body}
    />
  );
};

export default DetallePrestamoTable;
