import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import "../HomeCard.scss";
import { LineaPrestamoCardProps } from "./LineaPrestamoCard.props";
import ViewMoreButton from "../../../ViewMoreButton/ViewMoreButton";
import { useNavigate } from "react-router-dom";
import {
  DateToStringConverter,
  NumberToMoneyConverter,
} from "../../../Formatters/Formatters";
import { HiddenBalanceText } from "../../../HiddenBalanceButton/HiddenBalanceText";
import "./LineaPrestamoCard.scss";

const LineaPrestamoCard = (props: LineaPrestamoCardProps) => {
  const navigate = useNavigate();

  return (
    <Card className="home-card">
      <CardContent className="home-card-principal-data">
        <Box className="home-card-principal-data-box">
          <Typography variant={"h2"} className="home-card-title">
            {props.NombreLinea}
          </Typography>
          <Typography variant="h1" className="home-card-balance">
            {props.HiddenBalances
              ? `$ ${HiddenBalanceText}`
              : NumberToMoneyConverter(props.Importe)}
          </Typography>
        </Box>
        <Box
          className="home-card-other-data"
          sx={{
            flexWrap: "nowrap !important",
          }}
        >
          <Chip
            className="linea-prestamo-resumen-vencimiento"
            label={`Próx. Vto. ${DateToStringConverter(
              props.ProximoVencimiento
            )}`}
            color={props.EsMoroso ? "error" : "success"}
            variant={"filled"}
          />
          <Typography
            variant="body2"
            className="linea-prestamo-resumen-cantidad-vigentes"
          >
            {`Vigentes: ${props.CantidadPrestamosVigentes}`}
          </Typography>
        </Box>
      </CardContent>
      <CardActions className="home-card-actions">
        <ViewMoreButton onClick={() => navigate(`/prestamos`)} />
      </CardActions>
    </Card>
  );
};

export default LineaPrestamoCard;
