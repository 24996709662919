import { List, MenuItem } from "@mui/material";
import { Notification } from "./Notifications.interfaces";
import NotificationMenuItem from "./NotificationMenuItem";
import { NotificationMenuStyles as styles } from "./NotificationMenu.styles";
import Loader from "../../Loader/Loader";
import { NotificationsListHeader } from "./NotificationsListHeader";

export interface NotificationsListProps {
  handleScroll: (event: React.UIEvent) => void;
  notificationListId: string;
  isLoading: boolean;
  notifications: Notification[];
  markAsRead: (notification: Notification) => void;
  noMoreNotifications: boolean;
  totalUnread: number;
}

const NotificationsList = (props: NotificationsListProps) => {
  const totalUnread = props.totalUnread > 0 ? props.totalUnread : 0;

  return (
    <List
      sx={styles.List}
      onScroll={props.handleScroll}
      id={props.notificationListId}
      subheader={
        <NotificationsListHeader totalUnreadNotifications={totalUnread} />
      }
    >
      {props.notifications.map((notification) => (
        <NotificationMenuItem
          key={`NotificationItem: ${notification.Id}`}
          notification={notification}
          markAsRead={props.markAsRead}
        />
      ))}
      {props.isLoading && (
        <MenuItem sx={styles.LoaderMenuItem} disableRipple>
          <Loader label={""} />
        </MenuItem>
      )}
      {props.noMoreNotifications &&
        !props.isLoading &&
        props.notifications.length !== 0 && (
          <MenuItem sx={styles.NoNotificationsMenuItem} disableRipple>
            No posee más notificaciones
          </MenuItem>
        )}
      {!props.isLoading && props.notifications.length === 0 && (
        <MenuItem sx={styles.NoNotificationsMenuItem} disableRipple>
          No hay notificaciones
        </MenuItem>
      )}
    </List>
  );
};

export default NotificationsList;
