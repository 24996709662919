interface GetNumberProps {
  sliderRange: Range;
  finalRange: Range;
  sliderStep: number;
  value: number;
}

interface Range {
  min: number;
  max: number;
}

export const GetValueInRange = (props: GetNumberProps): number => {
  let totalPoints =
    (props.sliderRange.max - props.sliderRange.min) / props.sliderStep;
  let stepFinalRange = Math.round(
    (props.finalRange.max - props.finalRange.min) / totalPoints
  );
  let totalPointsToValue = Math.round(
    (props.value - props.sliderRange.min) / props.sliderStep
  );
  return stepFinalRange * totalPointsToValue + props.finalRange.min;
};
