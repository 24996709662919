import { Box, Slider } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { marks } from "./Marks";
import { SliderProps } from "./Slider.props";
import { MAX_VALUE, MIN_VALUE, TransformValue } from "./TransformValue";

const minDistance = 0;
const sliderStep = 0.5;

const MySlider = (props: SliderProps) => {
  const [sliderValues, setSliderValues] = React.useState([0, 100]);

  useEffect(() => {
    if (props.min === MIN_VALUE && props.max === MAX_VALUE) {
      setSliderValues([0, 100]);
    }
  }, [props.min, props.max]);

  useEffect(() => {
    props.setMin(TransformValue(sliderValues[0], sliderStep));
  }, [sliderValues[0]]);

  useEffect(() => {
    props.setMax(TransformValue(sliderValues[1], sliderStep));
  }, [sliderValues[1]]);

  const handleChange1 = (_event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setSliderValues([
        Math.min(newValue[0], sliderValues[1] - minDistance),
        sliderValues[1],
      ]);
    } else {
      setSliderValues([
        sliderValues[0],
        Math.max(newValue[1], sliderValues[0] + minDistance),
      ]);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Slider
        value={sliderValues}
        step={sliderStep}
        marks={marks}
        onChange={handleChange1}
        valueLabelDisplay="off"
        disableSwap
      />
    </Box>
  );
};

export { MySlider as Slider };
