import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { drawerWidth } from "../../layout/LayoutConstants";
import { Link } from "react-router-dom";
import { LayoutContext } from "../../contexts/LayoutContext";
import Icon from "../Icon/Icon";
import { IconEnum } from "../Icon/Icon.props";
import AuthenticationService from "../../services/Login/Authentication.service";
import { UseUsuario } from "../../contexts/UsuarioContext";
import { tycQueryKey, tycQueryValue } from "../TyCDialog/TyCDialog";

interface MenuItem {
  label: string;
  icon: React.ReactNode;
  route?: string;
  disabled: boolean;
  isCollapsed?: boolean;
  children?: MenuItem[];
}

let menu: MenuItem[] = [
  {
    label: "Home",
    icon: <Icon icon={IconEnum.HOME} />,
    route: "/home",
    disabled: false,
    children: [],
  },
  {
    label: "Cuenta",
    icon: <Icon icon={IconEnum.CUENTAS} />,
    disabled: false,
    children: [
      {
        label: "Caja de Ahorro",
        icon: null,
        route: "/cuenta/movimientos",
        isCollapsed: false,
        disabled: false,
      },
      {
        label: "Ahorro Mutual Plus",
        icon: null,
        route: "/cuenta/ahorro-mutual-plus",
        isCollapsed: false,
        disabled: false,
      },
    ],
  },
  {
    label: "Transferencias",
    icon: <Icon icon={IconEnum.TRANSFERENCIAS} />,
    route: "/transferencias",
    disabled: false,
    children: [],
  },
  {
    label: "Préstamos",
    icon: <Icon icon={IconEnum.PRESTAMOS} />,
    route: "/prestamos",
    disabled: false,
    children: [],
  },
  {
    label: "Inversiones",
    icon: <Icon icon={IconEnum.INVERSIONES} />,
    disabled: false,
    children: [
      {
        label: "Listado",
        icon: null,
        route: "/inversiones",
        isCollapsed: false,
        disabled: false,
      },
      {
        label: "Constitución",
        icon: null,
        route: "inversion-constitucion",
        isCollapsed: false,
        disabled: false,
      },
    ],
  },
  {
    label: "Cambiar contraseña",
    icon: <Icon icon={IconEnum.CHANGE_PASSWORD} />,
    route: "/cambiar-contrasenia",
    disabled: false,
    children: [],
  },
  {
    label: "Términos y Condiciones",
    icon: <Icon icon={IconEnum.TERMS_AND_CONDITIONS} />,
    route: `?${tycQueryKey}=${tycQueryValue}`,
    disabled: false,
    children: [],
  },
  {
    label: "Cerrar sesión",
    icon: <Icon icon={IconEnum.LOGOUT} />,
    route: "/Home",
    disabled: false,
    children: [],
  },
];

const renderMenuItem = ({
  item,
  index,
  menuOpciones,
  setMenuOpciones,
  callback,
}: {
  item: MenuItem;
  index: number;
  menuOpciones: MenuItem[];
  setMenuOpciones: Function;
  callback?: Function;
}) => {
  const { selectedCuentaUsuario } = UseUsuario();
  const cerrarSesion = () => {
    AuthenticationService.Logout();
  };
  const handleCollapse = (item) => {
    let menuCopia = menuOpciones.slice();
    const { label } = item;

    let element = menuCopia.find((opcion) => opcion.label == label);

    if (element != undefined) {
      element.isCollapsed = !element.isCollapsed;
      setMenuOpciones(menuCopia);
    }
  };

  return item.children.length == 0 ? (
    <ListItem
      key={index}
      disablePadding
      onClick={() => (callback ? callback() : undefined)}
    >
      {item.label != "Cerrar sesión" ? (
        <ListItemButton component={Link} to={item.route}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItemButton>
      ) : (
        <ListItemButton onClick={() => cerrarSesion()}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItemButton>
      )}
    </ListItem>
  ) : (
    <>
      <ListItemButton onClick={() => handleCollapse(item)}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
        {item.isCollapsed ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={item.isCollapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children.map((child) => (
            <ListItemButton
              component={Link}
              to={child.route}
              disabled={
                child.route === "/cuenta/ahorro-mutual-plus" &&
                !selectedCuentaUsuario?.HasCuentaRemunerada
              }
            >
              <ListItemText primary={child.label} sx={{ pl: 5 }} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const DrawerContent = ({ theme, menuOpciones, setMenuOpciones }) => {
  // let collapsableMenuOpened: boolean = false;
  const toggleCollapsableMenu = useCallback(() => {
    // collapsableMenuOpened = !collapsableMenuOpened;
    // return collapsableMenuOpened;
  }, []);

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          {/* <LogoSection /> */}
        </Box>
      </Box>
      <BrowserView>
        <Box
          sx={{
            [theme.breakpoints.up("md")]: {
              paddingTop: "70px",
            },
            [theme.breakpoints.down("md")]: {
              paddingTop: "30px",
            },
          }}
        >
          {/* <UserCardComponent ></UserCardComponent> */}
          <List>
            {menuOpciones.map((item, index) =>
              item.children?.length ? (
                <>
                  {renderMenuItem({
                    item: item,
                    index: 333 + index,
                    menuOpciones,
                    setMenuOpciones,
                  })}
                  {/* <Collapse in={true} timeout="auto" unmountOnExit key={600 + index}>
                                        {item.children.map((children, childrenIndex) => {
                                            console.log("Item: " + item.label + " - " + index);
                                            console.log("Children Index: " + childrenIndex)
                                            return renderMenuItem({item: children, index: menu.length + 200 + childrenIndex, callback: toggleCollapsableMenu})
                                        }

                                        )}
                                    </Collapse> */}
                </>
              ) : (
                renderMenuItem({
                  item: item,
                  index: index,
                  menuOpciones,
                  setMenuOpciones,
                })
              )
            )}
          </List>
        </Box>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <List
            sx={{
              [theme.breakpoints.up("md")]: {
                paddingTop: "70px",
              },
              [theme.breakpoints.down("md")]: {
                paddingTop: "30px",
              },
            }}
          >
            {menu.map((item, index) =>
              renderMenuItem({
                item: item,
                index: index,
                menuOpciones,
                setMenuOpciones,
              })
            )}
          </List>
        </Box>
      </MobileView>
    </>
  );
};

export default function LeftMenuComponent() {
  const theme = useTheme();
  const [layoutContext, setLayoutContext] = useContext(LayoutContext);
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      )
        return;
      setLayoutContext({ menuOpened: open });
    },
    []
  );

  const [menuOpciones, setMenuOpciones] = useState<MenuItem[]>(menu);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={layoutContext.menuOpened}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            // borderRight: 'none',
            // [theme.breakpoints.up('md')]: {
            //     top: '88px'
            // }
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <DrawerContent
          theme={theme}
          menuOpciones={menuOpciones}
          setMenuOpciones={setMenuOpciones}
        />
      </Drawer>
    </Box>
  );
}
