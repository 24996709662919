import PrestamoService from "../../../services/PrestamoService/prestamo.service";
import useGenericDetallePrestamo from "./useGenericDetallePrestamo.hook";
import { PrestamoIdentifier, PrestamoVencidoDetalle } from "../interfaces/Prestamo";

interface UseDetallePrestamoVencidoResult {
  isLoading: boolean;
  detallePrestamo: PrestamoVencidoDetalle;
}

const useDetallePrestamoVencido = (
  props: PrestamoIdentifier
): UseDetallePrestamoVencidoResult => {
  const { isLoading, detallePrestamo } = useGenericDetallePrestamo({
    ...props,
    serviceToCall: PrestamoService.GetDetallePrestamoVencido,
  });

  return {
    isLoading,
    detallePrestamo: detallePrestamo as PrestamoVencidoDetalle,
  };
};

export default useDetallePrestamoVencido;
