import { Notification } from "../../components/Drawer/NotificationsDrawer/Notifications.interfaces";

export const NotificationAdapter = (
  notification: Notification
): Notification => {
  return {
    ...notification,
    Timestamp: new Date(notification.Timestamp),
    ReadTimestamp: notification.ReadTimestamp
      ? new Date(notification.Timestamp)
      : undefined,
    IsRead: Boolean(notification.ReadTimestamp),
  };
};
