import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./assets/style.scss";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./themes";
import NotificationProvider from "./hooks/notification.hook";
import disableConsoleOnProduction from "./utils/disableConsole.util";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

disableConsoleOnProduction();

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme()}>
    <CssBaseline></CssBaseline>
    <BrowserRouter>
      <NotificationProvider>
        <App></App>
      </NotificationProvider>
    </BrowserRouter>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();