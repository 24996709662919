import { Grid } from "@mui/material";
import { useDetallePrestamo } from "../../../contexts/DetallePrestamoContext";
import { UseUsuario } from "../../../contexts/UsuarioContext";
import { TipoPrestamoEnum } from "../../../pages/Prestamos/enums/TipoPrestamo.enum";
import PrestamoConCuotas from "../../../pages/Prestamos/PageComponents/PrestamoConCuotas";
import PrestamoTipoAnticipado from "../../../pages/Prestamos/PageComponents/PrestamoTipoAnticipado";
import PrestamoTipoVencido from "../../../pages/Prestamos/PageComponents/PrestamoTipoVencido";
import { StringToNumber } from "../../Formatters/Formatters";
import { PrestamoIdentifier } from "../../../pages/Prestamos/interfaces/Prestamo";

interface DetallePrestamoDrawerContainerProps {
  closeDrawer: () => void;
}

const DetallePrestamoDrawerContainer = ({
  closeDrawer,
}: DetallePrestamoDrawerContainerProps) => {
  const { selectedCuentaUsuario } = UseUsuario();
  const { detallePrestamo } = useDetallePrestamo();

  const sharedProps: PrestamoIdentifier = {
    NroComprobante: detallePrestamo?.nroComprobantePrestamo,
    LineaId: detallePrestamo?.lineaPrestamoId,
  };

  return (
    <Grid container sx={{ width: "min(500px, 100vw)" }}>
      {detallePrestamo?.tipoPrestamo === TipoPrestamoEnum.CON_CUOTAS && (
        <PrestamoConCuotas
          {...sharedProps}
          saldoUsuario={selectedCuentaUsuario?.Saldo}
          closeDrawer={closeDrawer}
        />
      )}
      {detallePrestamo?.tipoPrestamo === TipoPrestamoEnum.ANTICIPADO && (
        <PrestamoTipoAnticipado {...sharedProps} />
      )}
      {detallePrestamo?.tipoPrestamo === TipoPrestamoEnum.VENCIDO && (
        <PrestamoTipoVencido {...sharedProps} />
      )}
    </Grid>
  );
};

export default DetallePrestamoDrawerContainer;
