import { createContext, useContext, useEffect, useState } from "react";
import HomeService from "../services/HomeService/Home.Service";
import { useLocation } from "react-router-dom";
import SessionService from "../services/session.service";

const SystemDateContext = createContext<Date>(null);

const SystemDateProvider = ({ children }) => {
  const location = useLocation();
  const [systemDate, setSystemDate] = useState<Date>(new Date(Date.now()));

  useEffect(() => {
    if (SessionService.VerifySession()) {
      GetSystemDate();
    }
  }, [location]);

  const GetSystemDate = () => {
    HomeService.GetSystemDate()
      .then((strDate) => setSystemDate(new Date(strDate)))
      .catch(() => setSystemDate(new Date(Date.now())));
  };

  return (
    <SystemDateContext.Provider value={systemDate}>
      {children}
    </SystemDateContext.Provider>
  );
};

export const useSystemDate = () => {
  return useContext(SystemDateContext);
};

export default SystemDateProvider;
