export interface TipoVencimiento {
    id: number;
    label: string;
}

const TipoVencimiento1: TipoVencimiento = {
    id: 1,
    label: "Plazo"
}

const TipoVencimiento2: TipoVencimiento = {
    id: 2,
    label: "Fecha de vencimiento"
}

export enum TipoVencimientoEnum {
    plazo = 1,
    fechaVencimiento = 2
}

const TipoVencimientos = {
    GetAll: (): TipoVencimiento[] => ([TipoVencimiento1, TipoVencimiento2])
}

export default TipoVencimientos