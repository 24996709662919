import { useState, useEffect } from "react";
import ".././LoginPage.scss";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import AuthenticationService from "../../../services/Login/Authentication.service";
import LoginHeader from "../LoginHeader";
import Loader from "../../../components/Loader/Loader";
import SessionService from "../../../services/session.service";
import { defaultMsgError } from "../../../config/axios";

const successfulSubtitle =
  "Se ha enviado un correo a su email asociado con los pasos para restablecer su contraseña";
const defaultErrorSubtitle =
  "Hubo un problema al validar la información de su cuenta. Comuníquese con el equipo de HomeMutual para resolver este problema";

const FirstLoginPage = () => {
  const [loading, setLoading] = useState(true);
  const [subtitle, setSubtitle] = useState(successfulSubtitle);
  const { dni, usuarioINS } = SessionService.GetLoginInformation();

  useEffect(() => {
    if (dni && usuarioINS) {
      setTimeout(() => {
        sendMailToChangePass();
      }, 1500);
    } else {
      onExit();
    }
  }, [dni, usuarioINS]);

  const sendMailToChangePass = () => {
    AuthenticationService.ForgotMyPassword(dni, usuarioINS)
      .then(() => setTimeout(onExit, 3000))
      .catch((message: string) => {
        const errorMsg =
          message !== defaultMsgError ? message : defaultErrorSubtitle;
        setSubtitle(errorMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onExit = () => {
    AuthenticationService.Logout();
    AuthenticationService.NavigateToDefaultLogin();
  };

  return (
    <div className="login">
      <div className="login__container" style={{ width: "auto" }}>
        <LoginHeader></LoginHeader>
        <Divider></Divider>
        <Box
          component="form"
          sx={{
            display: "flex",
          }}
        >
          <div className="login__form">
            <h2>Primer ingreso</h2>
            <Typography variant="h4" sx={{ color: "text.primary", mb: "15px" }}>
              Se enviará un correo a su casilla de email asociada para verificar
              su identidad y generar una contraseña temporal para su primer
              acceso.
            </Typography>
            {loading && <Loader label={"Enviando correo"} />}
            {!loading && (
              <Typography variant="h3" sx={{ mt: "30px", mb: "30px" }}>
                {subtitle}.
              </Typography>
            )}
            <a href="#" onClick={onExit}>Volver a página de ingreso</a>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default FirstLoginPage;
