import { Chip, Collapse, IconButton } from "@mui/material";
import Icon from "../../../../components/Icon/Icon";
import { IconEnum } from "../../../../components/Icon/Icon.props";
import Filters from "../Filters/Filters";
import { MobileViewProps } from "./MobileView.props";
import { UseUsuario } from "../../../../contexts/UsuarioContext";
import { NumberToMoneyConverter } from "../../../../components/Formatters/Formatters";
import { DownloadExtracto } from "../../../../components/DownloadButton/Download.function";
import { CuentaEspecificaFiltersToGetMovements } from "../../../../services/CuentaService/cuenta.service.adapter";
import { useSnackbar } from "notistack";

const MobileView: React.FC<MobileViewProps> = ({
  setOpenFilters,
  applyFiltersButtonHandler,
  filters,
  clearAllHandler,
  hasFilters,
  openFilters,
  ctaPlus,
}) => {
  const { selectedCuentaUsuario } = UseUsuario();
  const { enqueueSnackbar } = useSnackbar();

  const ApplyFiltersButtonHandler = () => {
    setOpenFilters(false);
    applyFiltersButtonHandler();
  };

  return (
    <div className="cuenta-especifica-default-div">
      <div
        className="cuenta-especifica-default-div"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
        }}
      >
        <Chip
          label="Filtros"
          variant="outlined"
          color="primary"
          sx={{ mr: "0.5rem" }}
          onClick={() => {
            setOpenFilters((prev) => !prev);
          }}
          onDelete={
            hasFilters
              ? () => {
                  clearAllHandler();
                }
              : undefined
          }
          icon={
            openFilters ? (
              <Icon icon={IconEnum.DOWN} />
            ) : (
              <Icon icon={IconEnum.RIGHT} />
            )
          }
        />
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Chip
            label={`Saldo: ${NumberToMoneyConverter(
              ctaPlus
                ? selectedCuentaUsuario?.SaldoCuentaRemunerada
                : selectedCuentaUsuario?.Saldo
            )}`}
            variant="outlined"
            color="primary"
          />
          {!ctaPlus && (
            <>
              <IconButton
                color="success"
                disableRipple
                disableTouchRipple
                sx={{ padding: 0 }}
                onClick={() =>
                  DownloadExtracto(
                    CuentaEspecificaFiltersToGetMovements({
                      filters: filters,
                    }),
                    enqueueSnackbar,
                    "csv"
                  )
                }
              >
                <Icon icon={IconEnum.CSV} />
              </IconButton>
              <IconButton
                color="error"
                disableRipple
                disableTouchRipple
                sx={{ padding: 0 }}
                onClick={() =>
                  DownloadExtracto(
                    CuentaEspecificaFiltersToGetMovements({
                      filters: filters,
                    }),
                    enqueueSnackbar,
                    "pdf"
                  )
                }
              >
                <Icon icon={IconEnum.PDF} />
              </IconButton>
            </>
          )}
        </div>
      </div>
      <Collapse
        in={openFilters}
        timeout="auto"
        unmountOnExit
        sx={{ width: "100%", mt: "0.5rem", transition: "ease-in" }}
      >
        <Filters buttonHandler={ApplyFiltersButtonHandler} ctaPlus={ctaPlus} />
      </Collapse>
    </div>
  );
};

export default MobileView;
