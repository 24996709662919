export interface PasoConstitucion {
    pagina: number;
    label: string
}


export const PasoConstitucion1: PasoConstitucion = {
    pagina: 1,
    label: 'Formulario ATM'
}

export const PasoConstitucion2: PasoConstitucion = {
    pagina: 2,
    label: 'Verifiación de datos'
}

const PasosConstitucion = {
    GetAll: () => ([PasoConstitucion1, PasoConstitucion2])
}

export default PasosConstitucion