import { Box, Divider, MenuItem } from "@mui/material";
import { Notification } from "./Notifications.interfaces";
import { NotificationMenuItemStyles as styles } from "./NotificationMenu.styles";
import colors from "../../../assets/_themes-vars.module.scss";
import { useRef, useState } from "react";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

interface NotificationMenuItemProps {
  notification: Notification;
  markAsRead: (notification: Notification) => void;
}

const NotificationMenuItem = ({
  notification,
  markAsRead,
}: NotificationMenuItemProps) => {
  const menuItemRef = useRef(null);
  const isIntersecting = useIntersectionObserver(menuItemRef);
  const [isRead, setIsRead] = useState<boolean>(notification.IsRead);

  const callMarkAsRead = () => {
    if (!isRead) {
      setTimeout(() => {
        markAsRead(notification);
      }, 300);
      setIsRead(true);
    }
  };

  if (isIntersecting) {
    callMarkAsRead();
  }

  return (
    <>
      <MenuItem
        ref={menuItemRef}
        sx={styles.MenuItem}
        disableRipple
        onMouseEnter={callMarkAsRead}
      >
        <Box
          sx={{
            ...styles.MainContainer,
            color: notification.IsRead ? colors.grey900 : colors.primary800,
          }}
        >
          <div style={styles.TextContainer}>
            <h4 style={{ margin: 0 }}>{notification.Title}</h4>
            <h5 style={styles.Subtitle}>
              {notification.Timestamp.toLocaleDateString()}
            </h5>
          </div>
          <h5 style={styles.Subtitle}>{notification.Mensaje}</h5>
        </Box>
      </MenuItem>
      <Divider sx={{ margin: "0 !important" }} />
    </>
  );
};

export default NotificationMenuItem;
