import { useState } from "react";
import ".././LoginPage.scss";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useContext } from "react";
import { LoginAction } from "../enums/login-actions.enum";
import { LoginContext } from "../LoginPage";
import TextField from "../../../components/TextField/TextField";
import { LoadingButton } from "@mui/lab";
import AuthenticationService from "../../../services/Login/Authentication.service";
import {
  longErrorSnackbar,
  longSuccessSnackbar,
} from "../../../utils/snackBarConfigs.util";
import { defaultMsgError } from "../../../config/axios";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

const PasswordRecoveryOption = () => {
  const [_, setAction] = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  const [dni, setDni] = useState("");
  const [usuario, setUsuario] = useState("");

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { enqueueSnackbar } = useSnackbar();

  const LoadDNI = (e) => {
    clearErrors("DNI");
    setDni(e.target.value);
  };

  const LoadUsuario = (e) => {
    clearErrors("Usuario");
    setUsuario(e.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);
    AuthenticationService.ForgotMyPassword(dni, usuario)
      .then(() => {
        enqueueSnackbar(
          `Se ha enviado un correo a su email asociado con los pasos para restablecer su contraseña`,
          longSuccessSnackbar
        );
        setAction(LoginAction.Login);
      })
      .catch((message: string) => {
        const errorMsg =
          message !== defaultMsgError
            ? message
            : `Hubo un problema al iniciar el proceso de reestablecimiento de contraseña. Contáctese con el equipo de mutual para más información`;
        enqueueSnackbar(errorMsg, longErrorSnackbar);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
      }}
    >
      <div className="login__form">
        <div>
          <h2>Reestablecer contraseña</h2>
          <br></br>
        </div>
        <TextField
          className="login__textBox"
          id="textField_NroDoc"
          label="Nro. de Documento"
          autoComplete="off"
          type="number"
          placeholder="Nro. de Documento"
          {...register("DNI", {
            required: {
              value: [null, undefined, ""].includes(dni),
              message: "Debe ingresar su DNI",
            },
            minLength: {
              value: 5,
              message: "Al menos 7 números para el DNI",
            },
            maxLength: {
              value: 11,
              message: "Solo 8 números para el DNI",
            },
            pattern: {
              value: /^[0-9]/,
              message: "Formato incorrecto, Debe cargar entre 7 y 8 números.",
            },
          })}
          value={dni}
          onChange={LoadDNI}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {dni ? (
                  <IconButton onClick={() => setDni("")} edge="end">
                    <HighlightOffIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
        />
        <p className="error">{errors.DNI?.message}</p>
        <TextField
          className="login__textBox"
          id="textField_Usuario"
          label="Usuario"
          autoComplete="off"
          placeholder="Usuario"
          {...register("Usuario", {
            required: {
              value: [null, undefined, ""].includes(dni),
              message: "Debe ingresar su Usuario",
            },
          })}
          value={usuario}
          onChange={LoadUsuario}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {usuario ? (
                  <IconButton onClick={() => setUsuario("")} edge="end">
                    <HighlightOffIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
        />
        <p className="error">{errors.Usuario?.message}</p>
        <div className="login__buttons__container">
          <Button
            className="login__btn"
            disabled={loading}
            onClick={() => setAction(LoginAction.Login)}
            sx={{
              "&.Mui-disabled": {
                opacity: 0.6,
                color: "white",
              },
            }}
          >
            Cancelar
          </Button>
          <LoadingButton
            className="login__btn"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            loadingPosition="center"
            loading={loading}
            sx={{
              ".MuiLoadingButton-loadingIndicator": {
                color: "white",
              },
            }}
          >
            Aceptar
          </LoadingButton>
        </div>
      </div>
    </Box>
  );
};

export default PasswordRecoveryOption;
