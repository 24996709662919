import { useEffect, useState } from "react";

import { PaginationConfig } from "../components/Tables/TableWrapper/PaginationConfig";

interface UseCustomPaginationProps<T> {
  defaultPagination: PaginationConfig;
  elements: T[];
  criteriaToShowSpecificElement?: (element: T) => boolean;
}

interface UseCustomPaginationResult<T> extends PaginationConfig {
  paginatedElements: T[];
  onPagination: (paginationConfig: PaginationConfig) => void;
}

const useCustomPagination = <T,>(
  props: UseCustomPaginationProps<T>
): UseCustomPaginationResult<T> => {
  const [pagination, setPagination] = useState<PaginationConfig>(
    props.defaultPagination
  );

  const updatePageByCriteria = () => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: getPageByCriteria(
        props.elements,
        prevPagination.pageSize,
        props.criteriaToShowSpecificElement
      ),
    }));
  };

  useEffect(() => {
    if (props.criteriaToShowSpecificElement) updatePageByCriteria();
  }, [props.elements]);

  const paginatedElements = [...props.elements].splice(
    pagination.page * pagination.pageSize,
    pagination.pageSize
  );

  const onPagination = (paginationConfig: PaginationConfig) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      ...paginationConfig,
    }));
  };

  return {
    ...pagination,
    paginatedElements,
    onPagination,
  };
};

export default useCustomPagination;

function getPageByCriteria<T>(
  elements: T[],
  pageSize: number,
  criteria: (element: T) => boolean
): number {
  for (let i = 0; i < elements.length; i += pageSize) {
    const pageElements = elements.slice(i, i + pageSize);
    if (pageElements.some(criteria)) {
      return Math.floor(i / pageSize);
    }
  }
  return 0;
}
