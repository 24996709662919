import {  ResumenInversiones } from "../../../../services/InversionService/inversion.service";
import { IconEnum } from "../../../Icon/Icon.props";
import NoElementsCard from "../NoElementsCard/NoElementsCard";
import ResumenInversionesCard from "./ResumenInversionesCard";

function GetResumenInversionesCard(resumen: ResumenInversiones, hiddenBalances: boolean) {
  let cardList: JSX.Element[] = [];
  if (resumen?.TieneInversionesActivas) {
    cardList.push(
      <ResumenInversionesCard
        total={resumen.TotalInversiones}
        cantidad={resumen.CantidadInversiones}
        totalPropias={resumen.TotalInversionesPropias}
        cantidadPropias={resumen.CantidadInversionesPropias}
        fechaProximoVencimiento={resumen.ProximoVencimiento}
        montoProximoVencimiento={resumen.MontoProximoVencimiento}
        hiddenBalance={hiddenBalances}
      />
    );
  } else {
    cardList.push(
      <NoElementsCard
        errorText={"No tiene ninguna inversion realizada"}
        buttonText={"Realizar inversión"}
        icon={IconEnum.INVERSIONES}
        redirectPath={"/inversiones"}
      />
    );
  }
  return cardList;
}

export default GetResumenInversionesCard;
