import SuccessActionDrawerContent from "../../../../components/Drawer/SuccessActionDrawerContent/SuccessActionDrawerContent";
import { NumberToMoneyConverter } from "../../../../components/Formatters/Formatters";
import { TipoMovimientoEnum } from "../../../../services/ComprobantesService/comprobantes.service.interfaces";

const TransferenciaExitosa = (props: {
  closeDrawer: () => void;
  importe: number;
  destino: string;
  comprobante: number;
}) => {
  return (
    <SuccessActionDrawerContent
      closeDrawer={props.closeDrawer}
      title={"¡Transferencia Exitosa!"}
      text={`La transferencia de ${NumberToMoneyConverter(
        props.importe,
        true
      )} a ${props.destino} ha sido realizada correctamente.`}
      comprobanteData={{
        NComprobante: props.comprobante,
        TipoMovimiento: TipoMovimientoEnum[TipoMovimientoEnum.TRANSFERENCIA],
      }}
    />
  );
};

export default TransferenciaExitosa;
